class FirebaseManager {
  constructor() {
    this.db = window.firebase.firestore().collection("frescos");

    this.data = null;
    this.currentFresco = null;
  }
}

export default new FirebaseManager();
