export default {
  color:
    'Verleihen Sie dem Winter Farbe und malen Sie das Fresko von OMY aus! Mit etwas Glück finden Sie dabei einen Promo Code!',
  btnStart: 'Jetzt malen',
  avatar: 'Ihr OMY Avatar ist:',
  instructionTitle: 'Anleitung',
  instruction:
    'Sehen Sie sich im Fresko um, wählen Sie eine weiße Fläche und malen Sie sie mit gedrückter Maustaste oder Ihrem Finger aus.',
  usersColorize: 'Personen malen gerade an diesem Fresko.',
  instructionColorize: `Wählen Sie eine Farbe und malen Sie damit die gewählte Fläche aus, um zu erfahren, ob Sie etwas gewonnen haben.`,
  sorry: `Schade!
  Hier war kein Promo Code versteckt.`,
  eshop: 'Lösen Sie Ihren Promo Code auf de.loccitane.com ein!',
  discoverMore: 'Mehr entdecken',
  win: `Glückwunsch! 
Sie haben einen Promo Code gefunden!`,
  tryAgain: 'Versuchen Sie es einfach nochmal!',
  close: 'Schließen',
  discover: 'Entdecken',
  discoverUrl: '',
  howTo: 'Wie lösen Sie Ihren Promo Code ein?',
  push: "Entdecken Sie die limitierte Shea Edition von OMY for L'OCCITANE!",
  rangeURL: 'https://de.loccitane.com/loccitane-x-omy,77,2,97655,1458715.htm',
  colorAll:
    'Wenn Sie eine Farbe gewählt haben, malen Sie damit das gesamte Element aus.',
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText:
    "Verleiht dem Winter Farbe und malt das L'OCCITANE Online-Fresko von OMY aus! Mit etwas Glück findet ihr dabei einen Promo Code für unsere Shea Kollektion! Los geht's! #ProvenceEverywhere",
  globalTWText:
    "LET’S COLOR TOGETHER! Verleiht dem Winter Farbe und malt das L'OCCITANE Online-Fresko von OMY aus! Mit etwas Glück findet ihr dabei einen Promo Code für unsere Shea Kollektion! Los geht's! #ProvenceEverywhere",
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText:
    "Nun seid ihr an der Reihe: Verleiht auch ihr dem Winter Farbe und malt das L'OCCITANE Online-Fresko von OMY aus! Mit etwas Glück findet ihr dabei einen Promo Code für unsere Shea Kollektion! Los geht's! #ProvenceEverywhere",
  localTWText:
    "LET’S COLOR TOGETHER! Nun seid ihr an der Reihe: Verleiht auch ihr dem Winter Farbe und malt das L'OCCITANE Online-Fresko von OMY aus! Mit etwas Glück findet ihr dabei einen Promo Code für unsere Shea Kollektion! Los geht's! #ProvenceEverywhere",
  frescoPourcent: `"So weit ist das Fresko bereits fertiggestellt.
Neugierig, wie das fertige Fresko aussieht? Besuchen Sie unseren Instagram-Account: @loccitanede"`,
  tc:
    'https://de.loccitane.com/teilnahmebedingungen-color,77,2,97710,1461551.htm',
  tcTitle: 'Teilnahmebedingungen',
  frescoCompleted: 'Fresko wurde vor $ Tagen begonnen',
  ie:
    'Dieser Browser ist mit unserer Animation nicht kompatibel. Bitte verwenden Sie einen neueren Browser.',
  continue: 'Malen Sie einfach weiter, um einen Code zu finden!',
  eye: '',
  howToURL:
    'https://de.loccitane.com/angebot-handcreme-mit-stift,77,2,97645,1458417.htm'
}
