export default {
  color:
    'Colorea el mural ilustrado por OMY e intenta encontrar los códigos promocionales en el dibujo.',
  btnStart: 'Empezar a colorear',
  avatar: 'Tu avatar OMY es...',
  instructionTitle: 'Instrucciones',
  instruction:
    'Navega en el mural, elige la zona que deseas customizar y pulsa sobre ella para colorearla',
  usersColorize: 'usuarios están coloreando este mural.',
  instructionColorize: `Elige el color de tu lapicero y colorea la zona seleccionada. 
Rellena el área completa para validar tu obra y descubrir si has ganado un premio.`,
  sorry: `¡Lo sentimos!
Ningún código promocional estaba escondido en esta zona.`,
  eshop: 'Úsalo en la tienda online ',
  discoverMore: 'Saber más',
  win: `¡Enhorabuena, 
has ganado un código promocional! Úsalo en es.loccitane.com`,
  tryAgain: '¡Prueba otra vez!',
  close: 'Cerrar',
  discover: 'DESCUBRIR',
  discoverUrl: '',
  howTo: '¿CÓMO UTILIZAR TU CÓDIGO PROMOCIONAL?',
  push: 'Descubre la gama Karité en edición limitada',
  rangeURL: 'https://es.loccitane.com/l-occitane-x-omy,76,2,84301,1463021.htm',
  colorAll:
    'Una vez eliges un color, utiliza el mismo para colorear todo el segmento.',
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText:
    "Colorea el mural online de OMY para L'Occitane, y podrás ganar códigos promocionales para el cuidado de la piel de la colección Karité. ¡A sus lapices! #ProvenceEverywhere",
  globalTWText: `LET’S COLOR TOGETHER
Colorea el mural online de OMY para L'Occitane y podrás ganar códigos promocionales para el cuidado de la piel de la colección Karité. ¡A sus lapices! #ProvenceEverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText:
    "Colorea también el mural online de OMY para L'Occitane y podrás ganar códigos promocionales para el cuidado de la piel de la colección Karité. ¡A sus lapices! #ProvenceEverywhere",
  localTWText: `LET’S COLOR TOGETHER
Colorea también el mural online de OMY para L'Occitane y podrás ganar códigos promocionales para el cuidado de la piel de la colección Karité. ¡A sus lapices! #ProvenceEverywhere`,
  frescoPourcent: `Este es el porcentaje de finalización del mural
¿Tienes curiosidad por ver este mural terminado? Consulta nuestra cuenta de Instagram: @loccitane_es`,
  tc:
    'https://es.loccitane.com/condiciones-legales-color,76,2,96869,1462976.htm',
  tcTitle: 'Condiciones Generales de Venta',
  frescoCompleted: 'Comenzaste tu mural hace $ horas',
  ie:
    'Este navegador no es compatible con nuestra animación. Por favor inténtalo con otro.',
  continue:
    'Continuer à colorier pour trouver le code!Réessayez encore une fois!',
  eye: '',
  howToURL: 'https://es.loccitane.com/oferta-colorear,76,2,97787,1463015.htm'
}
