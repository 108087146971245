export function prependZero(str, totalNbr, val = 0) {
  return str.toString().length < totalNbr
    ? prependZero(val.toString() + str, totalNbr)
    : str;
}

export function range(val, oldMin, oldMax, newMin, newMax) {
  return ((val - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin;
}

export function toRadian(degrees) {
  return (degrees * Math.PI) / 180;
}

export function getPointsDist(p1, p2) {
  return Math.hypot(p2.x - p1.x, p2.y - p1.y);
}

export function angleBetween(p1, p2) {
  return Math.atan2(p2.x - p1.x, p2.y - p1.y);
}

export function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export function randomFloat(min, max, decimals = 1) {
  return parseFloat((Math.random() * (min - max) + max).toFixed(decimals), 10);
}

export function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const isMobile = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

export const map = (num, min1, max1, min2, max2) => {
  let num1 = (num - min1) / (max1 - min1);
  let num2 = num1 * (max2 - min2) + min2;
  return num2;
};

export const setFullScreen = (bg, w, h, width = window.innerWidth) => {
  let height = window.innerHeight;
  // console.log(height)
  let imageRatio = w / h;
  let containerRatio = width / height;

  if (containerRatio > imageRatio) {
    // console.log('ici')
    bg.height = bg.height / (bg.width / width);
    bg.width = width;
    bg.position.x = 0;
    bg.position.y = (height - bg.height) / 2;
  } else {
    // console.log('la')
    bg.width = bg.width / (bg.height / height);
    bg.height = height;
    bg.position.y = 0;
    bg.position.x = (width - bg.width) / 2;
  }
};

export function shuffleArray(o) {
  for (
    let j, x, i = o.length;
    i;
    j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x
  );

  return o;
}

export const randomProperty = obj => {
  var keys = Object.keys(obj);
  let value = obj[keys[(keys.length * Math.random()) << 0]];
  const key = Object.keys(obj).find(key => obj[key] === value);

  return { name: key, color: value };
};

export const getUrlVars = () => {
  const vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
    m,
    key,
    value
  ) {
    vars[key] = value;
  });
  return vars;
};

export const getUrlParam = (parameter, defaultvalue) => {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
};

export const throttle = (func, wait, leading, trailing, context) => {
  var ctx, args, result;
  var timeout = null;
  var previous = 0;
  var later = function() {
    previous = new Date();
    timeout = null;
    result = func.apply(ctx, args);
  };
  return function() {
    var now = new Date();
    if (!previous && !leading) previous = now;
    var remaining = wait - (now - previous);
    ctx = context || this;
    args = arguments;
    // Si la période d'attente est écoulée
    if (remaining <= 0) {
      // Réinitialiser les compteurs
      clearTimeout(timeout);
      timeout = null;
      // Enregistrer le moment du dernier appel
      previous = now;
      // Appeler la fonction
      result = func.apply(ctx, args);
    } else if (!timeout && trailing) {
      // Sinon on s’endort pendant le temps restant
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};
