import * as PIXI from "pixi.js";
import Victor from "victor";
import bodymovin from "lottie-web";
import { map } from "../utils";
import animationData from "../lottie/fly.json";
import Target from "../helpers/Target";

export default class ParticleFly {
  constructor(parent) {
    this.parent = parent;
    this.maxspeed = map(Math.random(), 0, 1, 2.5, 3.7);
    this.maxforce = 0.1;
    this.arrived = false;
  }

  initLottie = container => {
    this.flyAnimation = bodymovin.loadAnimation({
      container: container,
      animationData: animationData,
      // renderer: "canvas",
      loop: true,
      autoplay: true
    });
  };

  init(x, y) {
    let acceleration = new Victor(0, 0);
    let velocity = new Victor(map(Math.random(), 0, 1, -40, 40), map(Math.random(), 0, 1, -40, 40));
    let position = new Victor(x, y);

    this.particle = document.createElement("div");
    this.particle.classList.add("fly");
    this.initLottie(this.particle);
    this.particle.style.transform = `translateX(${x}px) translateY(${y}px)`;

    this.position = position;
    this.velocity = velocity;
    this.acceleration = acceleration;

    this.parent.appendChild(this.particle);

    this.target = new Target();
    this.target.init();
    this.target.setTarget();
    // this.parent.appendChild(this.target.element);
  }

  applyForce(force) {
    // We could add mass here if we want A = F / M
    this.acceleration.add(force);
  }

  update() {
    var desired = this.target.clone().subtract(this.position);
    let d = desired.magnitude();
    // Scale with arbitrary damping within 100 pixels
    if (d < 50) {
      var m = map(d, 0, 50, 0, this.maxspeed);
      desired.normalize();
      desired.multiply({
        x: m,
        y: m
      });
    } else {
      desired.normalize();
      desired.multiply({
        x: this.maxspeed,
        y: this.maxspeed
      });
    }
    if (d < 10 && this.arrived === false) {
      // this.target.setTarget()
      // console.log('arrived')
      this.arrived = true;
    } else {
      this.arrived = false;
    }
    // Steering = Desired minus Velocity
    let steer = desired.clone().subtract(this.velocity);
    steer.limit({
      x: this.maxforce,
      y: this.maxforce
    }); // Limit to maximum steering force
    this.applyForce(steer);

    // Update velocity
    this.velocity.add(this.acceleration);
    // Limit speed
    this.velocity.limit({
      x: this.maxspeed,
      y: this.maxspeed
    });
    this.position.add(this.velocity);
    // Reset accelerationelertion to 0 each cycle
    this.acceleration.multiply({
      x: 0,
      y: 0
    });

    let angle = Math.atan2(this.velocity.y, this.velocity.x) + Math.PI / 2;

    // let angle = this.velocity.angle();
    let deg = this.toDegrees(angle);
    // console.log("ROTATE",angle)

    this.particle.style.transform = `translate3d(${this.position.x}px, ${this.position.y}px, 0px) rotate(${(angle *
      180) /
      Math.PI}deg)`;

    this.target.update();
  }

  toDegrees = angle => {
    return angle * (180 / Math.PI);
  };
}
