export default {
  color:
    'Llena de color esta ilustración inspirada en Karité y participa por muchos premios y sorpresas',
  btnStart: 'Empieza a colorear',
  avatar: 'Tu Avatar es...',
  instructionTitle: 'Instrucciones',
  instruction:
    'Busca el área que más te guste y pulsa sobre ella para empezar a colorear',
  usersColorize: 'usuarios están coloreando nuestra ilustración',
  instructionColorize: `Elige tu color favorito y colorea el área seleccionada, llena toda el área para descubrir si has ganado.`,
  sorry: `¡Lo sentimos!
Esta área de la ilustración no tenía un código oculto. `,
  eshop: 'Úsalo en nuestro sitio web co.loccitane.com',
  discoverMore: 'Descubrir más',
  win: `¡Felicitaciones! Ganaste un código promocional.`,
  tryAgain: '¡Intenta nuevamente!',
  close: 'Cerrar',
  discover: 'Descubre todas las ilustraciones realizadas.',
  discoverUrl:
    'https://co.loccitane.com/t%C3%A9rminos-y-condiciones-actividad-karit%C3%A9-color,8,2,92678,1472098.htm',
  howTo: '¿Cómo usar este código promocional?',
  push: 'Descubre nuestra edición limitada Karité Color',
  rangeURL:
    'https://co.loccitane.com/karit%C3%A9-color-landing,8,2,97761,1472603.htm',
  colorAll:
    'Una vez hayas elegido un color, usálo para colorear toda el área seleccionada.',
  globalFBTitle: '¡Dale color a tus días!',
  globalFBText: `Llena de color esta ilustración inspirada en Karité Color y participa por muchos premios y sorpresas.
#DaleColorATusDías`,
  globalTWText: `¡Dale color a tus días!
  Llena de color esta ilustración inspirada en Karité Color y participa por muchos premios y sorpresas.
#DaleColorATusDías`,
  localFBTitle: '¡Dale color a tus días!',
  localFBText: `Llena de color esta ilustración inspirada en Karité Color y participa por muchos premios y sorpresas.
#DaleColorATusDías`,
  localTWText: `¡Dale color a tus días!
  Llena de color esta ilustración inspirada en Karité Color y participa por muchos premios y sorpresas.
#DaleColorATusDías`,
  frescoPourcent: `Este es el porcentaje de color de nuestra ilustración.
¿Te gustaría ver la ilustración terminada? Consulta nuestra cuenta de Instagram: @loccitane_co`,
  tc:
    'https://co.loccitane.com/t%C3%A9rminos-y-condiciones-actividad-karit%C3%A9-color,8,2,97761,1472098.htm',
  tcTitle: 'Términos y condiciones',
  frescoCompleted: 'Esta ilustración inició hace $ horas',
  ie:
    'Este navegador no es compatible con esta animación, por favor usa una versión más reciente.',
  continue: '¡Sigue coloreando para encontrar tu premio!',
  eye:
    'https://co.loccitane.com/karit%C3%A9-color-landing,8,2,97761,1472603.htm',
  howToURL:
    'https://co.loccitane.com/t%C3%A9rminos-y-condiciones-actividad-karit%C3%A9-color,8,2,92678,1472098.htm'
}
