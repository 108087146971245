export default {
  color:
    "Раскрасьте зиму яркими красками с разноцветной фреской от OMY! Отправляйтесь в путешествие по анимированной карте и получите шанс найти на рисунке эксклюзивное предложение.",
  btnStart: "Начните раскрашивать",
  avatar: "Ваш OMY аватар...",
  instructionTitle: "Инструкция",
  instruction:
    "Рассмотрите фреску, выберите незакрашенный участок и нажмите на экран, чтобы раскрасить его.",
  usersColorize: "Сейчас эту фреску раскрашивают XX пользователей.",
  instructionColorize: `Выберите цвет и заполните им выбранный участок.
Заполните весь участок, чтобы узнать, появится ли промокод.`,
  sorry: `Извините, на этом участке не спрятаны промокоды.`,
  eshop: "Оформите заказ на www.loccitane.ru, чтобы получить подарок при покупке",
  discoverMore: "Узнать больше",
  win: `Поздравляем — эксклюзивное предложение ваше!`,
  tryAgain: "Попробуйте еще раз!",
  close: "Закрыть ",
  discover: "узнать больше ",
  discoverUrl: "",
  howTo: "КАК ВОСПОЛЬЗОВАТЬСЯ ПРОМОКОДОМ?",
  push: "Откройте для себя лимитированную коллекцию L'OCCITANE Карите x OMY!",
  rangeURL:
    "https://www.loccitane.ru/lp-omy-collection,9,2,73872,823531.htm",
  colorAll: "Как только вы выберете цвет, сохраните его, чтобы раскрасить весь элемент.",
  globalFBTitle : "ДАВАЙТЕ РАСКРАСИМ ВМЕСТЕ",
  globalFBText : "Раскрасьте онлайн фреску L'Occitane от OMY, и получите шанс найти промокод. Приготовьте карандаши! #provenceeverywhere",
  globalTWText : "Раскрасьте онлайн фреску L'Occitane от OMY, и получите шанс найти промокод. Приготовьте карандаши! #provenceeverywhere",
  localFBTitle : "ДАВАЙТЕ РАСКРАСИМ ВМЕСТЕ",
  localFBText : "Раскрасьте онлайн фреску L'Occitane от OMY, и получите шанс найти промокод. Приготовьте карандаши! #provenceeverywhere",
  localTWText : "Раскрасьте онлайн фреску L'Occitane от OMY, и получите шанс найти промокод. Приготовьте карандаши! #provenceeverywhere",
  frescoPourcent : "Эта цифра показывает степень завершенности фрески. Хотите узнать, как выглядит законченная фреска? Проверьте наш аккаунт в Instagram: @loccitane_ru",
  tc :  "https://www.loccitane.ru/terms-and-conditions,9,2,73805,823398.htm",
  tcTitle : "Условия и положения",
  frescoCompleted : "Фреску начали раскрашивать $ дней назад.",
  ie : "Этот браузер не совместим с нашей анимацией, пожалуйста, используйте другой. ",
  continue : "Продолжайте раскрашивать фреску, чтобы найти промокод.",
  eye : "",
    howToURL : "https://www.loccitane.ru/how-to-use-promocode,9,2,73805,823442.htm"
};
