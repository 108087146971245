import { map } from "../utils";
import Victor from "victor";

export default class Target extends Victor {
  init = () => {
    this.element = document.createElement("div");
    this.element.classList.add("target");
    let velocity = new Victor(map(Math.random(), 0, 1, -5, 5), map(Math.random(), 0, 1, -6, 6));
    this.velocity = velocity;
  };

  setTarget = () => {
    const minPosX = window.innerWidth / 2 - 250;
    const minPosY = window.innerHeight / 2 - 250;
    const maxPosX = window.innerWidth / 2 + 250;
    const maxPoY = window.innerHeight / 2 + 250;
    let randomX = map(Math.random(), 0, 1, minPosX, maxPosX);
    let randomY = map(Math.random(), 0, 1, minPosY, maxPoY);
    this.x = randomX;
    this.y = randomY;
    this.element.style.transform = `translateX(${this.x}px) translateY(${this.y}px)`;
  };

  update = () => {
    this.add(this.velocity);
    if (this.x > window.innerWidth - 50 || this.x < 50) {
      this.velocity.x = this.velocity.x * -1;
    }
    if (this.y > window.innerHeight - 50 || this.y < 50) {
      this.velocity.y = this.velocity.y * -1;
    }
    this.element.style.transform = `translateX(${this.x}px) translateY(${this.y}px)`;
  };
}
