export const fragmentClamp = `
    precision lowp float;
    varying vec2 vTextureCoord;
    uniform sampler2D uSampler;

    uniform vec2 uOffset;
    uniform vec4 filterClamp;

    void main(void)
    {
        vec4 color = vec4(0.0);

        // Sample top left pixel
        color += texture2D(uSampler, clamp(vec2(vTextureCoord.x - uOffset.x, vTextureCoord.y + uOffset.y), filterClamp.xy, filterClamp.zw));

        // Sample top right pixel
        color += texture2D(uSampler, clamp(vec2(vTextureCoord.x + uOffset.x, vTextureCoord.y + uOffset.y), filterClamp.xy, filterClamp.zw));

        // Sample bottom right pixel
        color += texture2D(uSampler, clamp(vec2(vTextureCoord.x + uOffset.x, vTextureCoord.y - uOffset.y), filterClamp.xy, filterClamp.zw));

        // Sample bottom left pixel
        color += texture2D(uSampler, clamp(vec2(vTextureCoord.x - uOffset.x, vTextureCoord.y - uOffset.y), filterClamp.xy, filterClamp.zw));

        // Average
        color *= 0.25;

        gl_FragColor = color;
    }
`;
