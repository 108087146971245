export default {
  color:
    'Color the illustrated fresco by OMY and get a chance  to find promo codes in the drawing.',
  btnStart: 'Start coloring',
  avatar: 'Your OMY avatar is...',
  instructionTitle: 'Instructions',
  instruction: 'Browse the fresco, choose an area and tap to color it',
  usersColorize: 'users are now coloring this fresco.',
  instructionColorize: `Choose the color of your pencil and color the whole selected area.
Fill the entire area to validate the coloring and discover if you have won something.`,
  sorry: `Sorry,
no promo code was hidden in this area.`,
  eshop: "Shop on the L'OCCITANE site to redeem your FREE gift.*",
  discoverMore: 'Discover more',
  win: `Congratulations
you've won an exclusive offer!`,
  tryAgain: 'Please try again!',
  close: 'Close',
  discover: 'Discover all the completed frescos.',
  discoverUrl: '',
  howTo: 'HOW TO USE THIS PROMO CODE ?',
  push: "Discover L'OCCITANE's limited edition Shea x OMY Collection!",
  rangeURL: '',
  colorAll:
    'Once you choose a color, keep the same to color the entire element.',
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText: `Come color the online L'Occitane fresco by OMY, 
  and get a chance to win promo code on the 
  shea collection. Get your pencils ready! 
  #provenceeverywhere`,
  globalTWText: `LET'S COLOR TOGETHER
  Come color the online L'Occitane fresco by OMY, 
and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#provenceeverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText: `It's your turn to color the L'Occitane online fresco
by OMY and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#ProvenceEverywhere`,
  localTWText: `LET'S COLOR TOGETHER
It's your turn to color the L'Occitane online fresco
by OMY and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#ProvenceEverywhere`,
  frescoPourcent: '',
  tc: ''
}
