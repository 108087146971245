export default {
  color:
    'Colour your winter with the illustrated fresco by OMY! Travel around the animated map for a chance to discover an exclusive offer for you in the drawing.',
  btnStart: 'Start colouring',
  avatar: 'Your OMY avatar is...',
  instructionTitle: 'Instructions',
  instruction: 'Browse the fresco, choose a blank area and tap to colour it.',
  usersColorize: 'users are now colouring this fresco.',
  instructionColorize: `Choose a colour and fill in your selected area.
Complete the entire area to discover what you've won.`,
  sorry: `Sorry,
no promo code was hidden in this area.`,
  eshop: 'Use it on the eshop.',
  discoverMore: 'Discover more',
  win: `Congratulations -
you've won an exclusive offer! Shop on the L'OCCITANE site to redeem your FREE gift.*`,
  tryAgain: 'Please try again!',
  close: 'Close',
  discover: 'Discover all the completed frescos.',
  discoverUrl:
    'https://uk.loccitane.com/omy-x-loccitane,83,1,64556,1470055.htm',
  howTo: 'HOW TO USE THIS PROMO CODE ?',
  push: "Discover L'OCCITANE's limited edition Shea x OMY Collection!",
  rangeURL: 'https://uk.loccitane.com/omy-x-loccitane,83,1,64556,1470055.htm',
  colorAll:
    'Once you choose a color, keep the same to color the entire element.',
  globalFBTitle: 'LET’S COLOUR TOGETHER',
  globalFBText: `Come color the online L'Occitane fresco by OMY, and get a chance to win promo code on the shea collection. Get your pencils ready! #provenceeverywhere`,
  globalTWText: `LET'S COLOR TOGETHER
  Come color the online L'Occitane fresco by OMY, and get a chance to win promo code on the shea collection. Get your pencils ready! #provenceeverywhere`,
  localFBTitle: 'LET’S COLOUR TOGETHER',
  localFBText: `It's your turn to color the L'Occitane online fresco by OMY and get a chance to win promo code on the shea collection. Get your pencils ready! #ProvenceEverywhere`,
  localTWText: `LET'S COLOUR TOGETHER
It's your turn to color the L'Occitane online fresco by OMY and get a chance to win promo code on the shea collection. Get your pencils ready! #ProvenceEverywhere`,
  frescoPourcent:
    'This percentage is how much of the fresco is completed. Curious to know what this finished Fresco looks like? Check out our Instagram account: @loccitane_uk_ire',
  tc:
    'https://uk.loccitane.com/omy-game-terms-conditions,83,1,64556,1472632.htm',
  tcTitle: 'Terms and Conditions ',
  frescoCompleted: 'Fresco started X hours ago',
  ie:
    'This browser is not compatible with our animation, please use a newer one.',
  continue: 'Please continue to color to find the code!',
  eye: 'https://uk.loccitane.com/omy-x-loccitane,83,1,64556,1470055.htm',
  howToURL: 'https://uk.loccitane.com/omy-x-loccitane,83,1,64556,1470055.htm'
}
