import {
  DARKPURPLE,
  PURPLE,
  GREEN,
  GREENBLUE,
  YELLOW,
  LIGHTYELLOW,
  LIGHTORANGE,
  ORANGE,
  BLUE
} from "../css/style";

export default {
  darkpurple: DARKPURPLE,
  purple: PURPLE,
  greenblue: GREENBLUE,
  green: GREEN,
  blue: BLUE,
  yellow: YELLOW,
  lightyellow: LIGHTYELLOW,
  orange: ORANGE,
  lightorange: LIGHTORANGE
};
