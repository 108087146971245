/** @jsx jsx */
import { jsx, css } from "@emotion/core";

function ProgressRing({ radius, stroke, progress, color }) {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2} css={animeClass}>
      <circle
        stroke={color || "white"}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + " " + circumference}
        strokeLinecap="round"
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
}

const animeClass = css`
  circle {
    /* transition: stroke-dashoffset 0.01s linear; */
  }
`;

export default ProgressRing;
