import { utils, Container, Texture, Sprite } from "pixi.js";
import store from "../datas/store";
import colors from "../datas/colors";
import EmitterManager from "./EmitterManager";
// import circleSvg from "../../src/images/icons/circle.svg";
import { TweenMax, Power3 } from "gsap";
import ColorContainer from "./ColorContainer";
import detectIt from "detect-it";

export default class ColorCircle extends Container {
  constructor(
    x,
    y,
    radius,
    border,
    color,
    colorName,
    isActive,
    index,
    isMobile
  ) {
    super();
    this.interactive = true;

    this.name = colorName;
    this.buttonMode = true;

    let circleTexture = new Texture.from(
      `${process.env.PUBLIC_URL}/images/circle.png`
    );

    this.circleSprite = new Sprite(circleTexture);
    this.circleBorderSprite = new Sprite(circleTexture);
    // this.circleSprite = new Graphics();
    // this.circleSprite.beginFill(0xffffff);
    // this.circleSprite.drawCircle(100, 100, 100);
    // this.circleBorderSprite = new Graphics();
    // this.circleBorderSprite.beginFill(0xffffff);
    // this.circleBorderSprite.drawCircle(100, 100, 100);

    this.circleSprite.width = this.circleSprite.height =
      radius * (detectIt.primaryInput === "touch" ? 1.5 : 2);
    this.circleBorderSprite.width = this.circleBorderSprite.height =
      radius * (detectIt.primaryInput === "touch" ? 1.5 : 2) + border;

    this.circleSprite.x = border / 2;
    this.circleSprite.y = border / 2;

    this.circleSprite.tint = utils.string2hex(color);

    this.addChild(this.circleBorderSprite);
    this.addChild(this.circleSprite);

    this.isActive = isActive;

    if (isMobile) {
      this.x = 0;
      this.y = y;
      this.pivot.x = this.width - this.circleBorderSprite.width / 2;
      this.pivot.y = this.circleBorderSprite.width / 2;
    } else {
      this.y = 0;
      this.x = x;
      this.pivot.x = this.width - this.circleBorderSprite.width / 2;
      this.pivot.y = this.circleBorderSprite.width / 2;
    }

    if (this.isActive) {
      this.scale.x = this.scale.y = 1.22;
    }

    this.on("mouseover", () => {
      TweenMax.to(this.scale, 0.2, { x: 1.22, y: 1.22, ease: Power3.easeIn });
    });

    this.on("mouseout", () => {
      if (this.isActive) {
        this.scale.x = this.scale.y = 1.22;
      } else {
        TweenMax.to(this.scale, 0.2, { x: 1, y: 1, ease: Power3.easeInOut });
      }
    });

    this.on("pointertap", () => {
      ColorContainer.children.forEach(child => {
        if (child.isActive) {
          TweenMax.to(child.scale, 0.2, { x: 1, y: 1, ease: Power3.easeInOut });
        }
        child.isActive = false;
      });

      this.isActive = true;
      this.scale.x = this.scale.y = 1.22;

      store.color.name = this.name;
      store.color.colorSelected = colors[this.name];
      EmitterManager.emit("COLOR:CHANGED", colors[this.name]);
    });
  }

  resize = (radius, x, border) => {
    this.cacheAsBitmap = false;
    this.circleSprite.width = this.circleSprite.height = radius * 2;
    this.circleBorderSprite.width = this.circleBorderSprite.height =
      radius * 2 + border;
    this.x = x;
    this.pivot.x = this.width - this.circleBorderSprite.width / 2;
    this.pivot.y = this.circleBorderSprite.width / 2;
    this.cacheAsBitmap = true;
  };
}
