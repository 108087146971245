import {
  Graphics,
  TilingSprite,
  Container,
  Sprite,
  Texture,
  RenderTexture,
  utils
} from "pixi.js";

import PixiApp from "./PixiApp";
import EmitterManager from "./EmitterManager";
import MouseManager, { MOUSE_DOWN, MOUSE_UP } from "./MouseManager";
import { getPointsDist, angleBetween, range } from "../utils";
import store from "../datas/store";
// import { TweenMax } from "gsap";
import detectIt from "detect-it";
export default class InvertedDrawingMask extends Graphics {
  constructor({ sprite, isPointer, cb, timerFn }) {
    super();

    this.cb = cb;
    this.sprite = sprite;
    this.timerFn = timerFn;
    this.isPointer = isPointer;

    this.x = sprite.x;
    this.y = sprite.y;

    this.newSprite = new Sprite(this.sprite.texture);
    this.newSprite.tint = 0xff0000;

    this.beginFill(0xffffff);
    this.drawRect(0, 0, sprite.width, sprite.height);
    this.endFill();
    this.mask = sprite;

    // this.graph = new Graphics();
    // this.graph.beginFill(0x0000ff, 0.03);
    this.graphContainer = new Container();
    // this.graph = new Sprite(Texture.WHITE);
    // this.graph.alpha = 0.03;

    this.addChild(this.graphContainer);

    this.tilingTex = new TilingSprite(
      PixiApp.loader.resources.crayon.texture,
      sprite.width,
      sprite.height
    );

    this.tilingTex.alpha = 0.4;

    this.addChild(this.tilingTex);

    if (this.isPointer) {
      EmitterManager.on(MOUSE_DOWN, this.handleMouseDown);
    }

    this.timer = setTimeout(this.timerFn, 90000);

    EmitterManager.on("COLOR:CHANGED", () => {
      while (this.graphContainer.children.length) {
        this.graphContainer.removeChildAt(0);
      }

      if (this.children.length === 3) this.removeChildAt(0);

      // for (let i = 0; i < this.graphContainer.children.length; i++) {
      //   let child = this.graphContainer.children[i];
      //   TweenMax.to(child, 0.2, {
      //     alpha: 0,
      //     onComplete: () => {
      //       this.graphContainer.removeChild(child);
      //     }
      //   });
      // }
    });
  }

  handleMouseDown = () => {
    clearTimeout(this.timer);

    if (this.isPointer) {
      EmitterManager.on(MOUSE_UP, this.handleMouseUp);
    }

    PixiApp.ticker.add(this.handleMouseMove);

    this.lastPoint = {
      x: MouseManager.x,
      y: MouseManager.y
    };
  };

  handleMouseUp = () => {
    // const renderTex = RenderTexture.create(this.graphContainer.width, this.graphContainer.height)
    // PixiApp.renderer.render(this.graphContainer, renderTex)

    this.timer = setTimeout(this.timerFn, 90000);

    if (this.isPointer) {
      EmitterManager.off(MOUSE_UP, this.handleMouseUp);
    }

    PixiApp.ticker.remove(this.handleMouseMove);

    // const newGraph = this.graph.clone();

    const cont = new Container();
    cont.addChild(this.newSprite);

    const rt = RenderTexture.create(this.sprite.width, this.sprite.height);

    if (this.children.length === 3)
      this.graphContainer.addChild(this.getChildAt(0));

    PixiApp.renderer.render(this.graphContainer, rt);
    const newSprite = new Sprite(rt);
    cont.addChild(newSprite);

    const pixels = PixiApp.renderer.extract.pixels(cont);

    // newSprite.destroy();

    if (this.graphContainer.children.length) {
      while (this.graphContainer.children.length) {
        this.graphContainer.removeChildAt(0);
      }
    }

    if (this.children.length === 3) this.removeChildAt(0);

    this.addChild(newSprite);
    this.setChildIndex(newSprite, 0);

    cont.destroy(false);

    let nbrRestPx = 0;
    let nbrDrawPx = 0;

    for (let i = 0; i < pixels.length; i += 4) {
      if (
        pixels[i] === 255 &&
        pixels[i + 1] === 0 &&
        pixels[i + 2] === 0 &&
        pixels[i + 3] !== 0
      ) {
        nbrRestPx++;
      } else if (pixels[i + 3] !== 0) {
        nbrDrawPx++;
      }

      if (nbrRestPx >= 1100) break;
    }

    // console.log(nbrRestPx);

    if (nbrRestPx < 1100 && nbrDrawPx > 50) {
      this.stop();
      EmitterManager.emit("COLORIZE:END");
      EmitterManager.emit("HIDE:COLORSELECTION");
      if (this.cb) this.cb();
    }

    // this.graph.cacheAsBitmap = true;
  };

  stop = () => {
    clearTimeout(this.timer);
    PixiApp.ticker.remove(this.handleMouseMove);
    EmitterManager.off(MOUSE_UP, this.handleMouseUp);
    EmitterManager.off(MOUSE_DOWN, this.handleMouseDown);

    // console.log("STTOOOP");

    this.isStopped = true;
    this.newSprite.destroy();
    this.tilingTex.destroy();
    this.graphContainer.destroy(true);
  };

  handleMouseMove = () => {
    if (this.isStopped) return;

    this.currentPoint = {
      x: MouseManager.x,
      y: MouseManager.y
    };

    const dist = getPointsDist(this.lastPoint, this.currentPoint);

    if (!dist) return;

    const angle = angleBetween(this.lastPoint, this.currentPoint);

    const radius = range(
      Math.min(dist, 20),
      0,
      20,
      2,
      detectIt.primaryInput === "touch" ? 7 : 5
    );

    let x;
    let y;

    for (let i = 0; i < Math.min(dist, 20); i += radius / 2) {
      x = this.lastPoint.x + Math.sin(angle) * i;
      y = this.lastPoint.y + Math.cos(angle) * i;

      const local = this.toLocal({ x, y });

      const spr = new Sprite(Texture.WHITE);
      spr.x = local.x - radius * 2;
      spr.y = local.y - radius * 2;
      spr.anchor.x = spr.anchor.y = 0.5;
      spr.scale.x = spr.scale.y = radius;
      spr.tint = utils.string2hex(store.color.colorSelected);
      spr.alpha = 0.06;
      this.graphContainer.addChild(spr);

      // this.graph.drawRect(
      //   local.x - radius * 2,
      //   local.y - radius * 2,
      //   radius * 4,
      //   radius * 4
      // );
    }

    this.lastPoint = this.currentPoint;
  };
}
