import { css } from "@emotion/core";

import { FS, LINE_HEIGHT } from "./style";

import GothamLightFont from "../fonts/GothamLight.woff";
import GothamBoldFont from "../fonts/GothamBold.woff";
import GothamBookFont from "../fonts/GothamBook.woff";
import GothamMediumFont from "../fonts/GothamMedium.woff";

export default css`
  @font-face {
    font-family: Gotham;
    src: url(${GothamLightFont}) format("woff");
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: Gotham;
    src: url(${GothamBoldFont}) format("woff");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: Gotham;
    src: url(${GothamMediumFont}) format("woff");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: Gotham;
    src: url(${GothamBookFont}) format("woff");
    font-weight: 400;
    font-style: normal;
  }

  #loccitaneOmy {
    box-sizing: border-box;
    font-family: Gotham, sans-serif;
    font-size: ${FS}px;
    line-height: ${LINE_HEIGHT};
    overflow-x: hidden;
    overflow-y: scroll;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  #loccitaneOmy input,
  #loccitaneOmy textarea,
  #loccitaneOmy button,
  #loccitaneOmy select,
  #loccitaneOmy a {
    -webkit-tap-highlight-color: transparent !important;
  }

  #loccitaneOmy *,
  #loccitaneOmy *::before,
  #loccitaneOmy *::after {
    box-sizing: inherit;
  }

  #loccitaneOmy * {
    user-select: none;
  }

  #loccitaneOmy a {
    color: inherit;
    text-decoration: inherit;
  }

  #loccitaneOmy button {
    appearance: none;
    /* background: transparent; */
    border: 0;
    cursor: pointer;
    outline: none;
    padding: 0;
  }

  #loccitaneOmy input {
    background-color: transparent;
  }

  #loccitaneOmy img {
    display: block;
  }

  .fly {
    position: fixed;
    width: 70px;
    height: 70px;
    z-index: 20;
    top: 0;
    left: 0;
    pointer-events: none;
    will-change: transform;
    transform-style: preserve-3d;

    svg {
      transform: translate3d(0px, 0px, 0px) rotate(-90deg) !important;
    }
  }

  .target {
    position: fixed;
    width: 100px;
    height: 100px;
    z-index: 20;
    top: 0;
    left: 0;
    pointer-events: none;
    will-change: transform;
    transform-style: preserve-3d;
    background: red;
    border-radius:50%;
  }

  .interacting {
    * {
      pointer-events: none;
    }
  }

  .is-interacting {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;
