/** @jsx jsx */
import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
// import classNames from "classnames";
// import penImg from "../images/home/pen.png";
import giftImg from "../images/home/gift.png";
import giftTestImg from "../images/home/gift-test.png";
// import flyShadowImg from "../images/home/fly-shadow.png";
import textureBck from "../images/home/popin-bck.png";
import arrowImg from "../images/home/fleche.png";
import { YELLOW, mq } from "../css/style";
import Particlefly from "../helpers/Particlefly";
import PixiApp from "../helpers/PixiApp";
// import Victor from "victor";

import { TweenMax, Elastic, TimelineMax, Power3 } from "gsap";
import { map } from "../utils";
import detectIt from "detect-it";
import Viewport from "../helpers/Viewport";

export class PopinLoose extends Component {
  sectionRef = React.createRef();

  componentDidMount() {
    this.nbParticle = 3;
    this.particleArr = [];
    this.initParticle();
  }

  initParticle = () => {
    // add new particle to screen
    for (let i = 0; i < this.nbParticle; i++) {
      const minPos = 50;
      const minPosX = window.innerWidth / 2 - 500;
      const minPosY = window.innerHeight / 2 - 500;
      const maxPosX = window.innerWidth / 2 + 500;
      const maxPoY = window.innerHeight / 2 + 500;

      let randomX = map(Math.random(), 0, 1, minPosX, maxPosX);
      let randomY = map(Math.random(), 0, 1, minPosY, maxPoY);

      this.fly = new Particlefly(this.sectionRef.current);
      this.particleArr.push(this.fly);
      this.fly.init(randomX, randomY);
    }

    PixiApp.ticker.add(() => {
      for (let i = 0; i < this.nbParticle; i++) {
        this.particleArr[i].update();
      }
    });
  };

  render() {
    return (
      <div css={wrapperPopinCss} ref={this.sectionRef}>
        <div className="popin" css={popinCss}>
          <div css={wrapperContent}>
            <img className="img1" css={giftImgCss} src={giftImg} alt="" />
            <p className="firstSentence" css={firstSentenceCss(this.props.locale)}>
              {this.props.lang.sorry}
            </p>
            <img className="img2" src={giftTestImg} css={giftTestImgCss(this.props.locale)} alt="" />
            <p className="secondSentence" css={secondSentenceCss}>
              {this.props.lang.tryAgain}
            </p>
          </div>

          <button
            className="button no-dra"
            css={buttonClass}
            onClick={() => {
              this.props.closePopin();
            }}
          >
            <span>{this.props.lang.close}</span>
          </button>
          {this.props.lang.discoverUrl && (
            <button css={sentenceDiscoverCss}>
              <a href={this.props.lang.discoverUrl} target="blank">
                Discover all the completed frescos
              </a>{" "}
              <img src={arrowImg} alt="" />
            </button>
          )}
        </div>
      </div>
    );
  }
}

export const onExit = html => {
  TweenMax.to(html, 0.4, {
    opacity: 0
  });
};

export const onEnter = html => {
  const popin = html.querySelector(".popin");
  const img1 = html.querySelector(".img1");
  const img2 = html.querySelector(".img2");
  const firstSentence = html.querySelector(".firstSentence");
  const secondSentence = html.querySelector(".secondSentence");
  const button = html.querySelector(".button");
  const flees = html.querySelectorAll(".fly");
  if(Viewport.screenHeight < 600) {
    TweenMax.set(popin, { opacity: 0, scale: 0.5 });
  } else {
    TweenMax.set(popin, { opacity: 0, scale: 0.7 });
  }
  TweenMax.set(img1, { opacity: 0, y: 10 });
  TweenMax.set(img2, { opacity: 0, y: 10 });
  TweenMax.set(firstSentence, { opacity: 0, y: 10 });
  TweenMax.set(secondSentence, { opacity: 0, y: 10 });
  TweenMax.set(button, { opacity: 0 });
  TweenMax.set(flees, { opacity: 0 });

  const tl = new TimelineMax({ delay: 0.1 });

  tl.to(html, 0.3, { opacity: 1 });

  if(Viewport.screenHeight < 600) {
    tl.to(
      popin,
      0.5,
      {
        opacity: 1,
        scale: .7,
        ease: Elastic.easeOut.config(1, 0.3)
      },
      "+=.2"
    );  } else {
    tl.to(
      popin,
      0.5,
      {
        opacity: 1,
        scale: detectIt.primaryInput === "touch" ? 0.7 : 1,
        ease: Elastic.easeOut.config(1, 0.3)
      },
      "+=.2"
    ); 
   }


  tl.to(img1, 0.5, { y: 0, opacity: 1, ease: Power3.easeOut }, "-=.28");
  tl.to(firstSentence, 0.5, { y: 0, opacity: 1, ease: Power3.easeOut }, "-=.28");
  tl.to(img2, 0.5, { y: 0, opacity: 1, ease: Power3.easeOut }, "-=.28");
  tl.to(secondSentence, 0.5, { y: 0, opacity: 1, ease: Power3.easeOut }, "-=.28");
  tl.to(button, 0.5, { opacity: 1, ease: Power3.easeOut }, "-=.28");
  tl.staggerTo(flees, 0.5, { opacity: 1, ease: Power3.easeOut }, 0.1, "-=.28");
};

const giftImgCss = css`
  margin: 0 auto;
  margin-bottom: 15px;
`;

const giftTestImgCss = locale => css`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  ${mq.xxLarge(css`
    margin-bottom: ${locale === "br_pt" ? '40px' : '70px'};
  `)};
`;
const wrapperContent = css`
  ${mq.medium(css`
    margin-top: -30px;
  `)};
`;

const buttonClass = css`
  align-items: center;
  bottom: -30px;
  background-color: ${YELLOW} !important;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 60px;
  justify-content: center;
  margin-left: -67.5px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  text-transform: uppercase;
  width: 135px;

  &::after {
    background-color: rgba(255, 255, 255, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.1s;
    width: 100%;
  }

  span {
    position: relative;
    z-index: 1;
  }

  &:hover {
    span {
      transform: scale(1.05);
    }

    &::after {
      transform: translateX(0);
    }
  }
`;

const wrapperPopinCss = css`
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const popinCss = css`
  position: relative;
  background-color: #fff;
  background-image: url(${textureBck});
  width: 100vw;
  height: 100vw;
  /* min-width: 360px; */
  /* min-height: 360px; */
  border-radius: 50%;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);

  ${mq.medium(css`
    margin-top: -25px;
    min-height: 500px;
    min-width: 500px;
    max-width: 650px;
    max-height: 650px;
    padding-top: 45px;
    transform: none;
    width: 84vh;
    height: 84vh;
  `)};

  ${mq.mHeight(css`
    transform: scale(0.7);
  `)};
`;

const firstSentenceCss = (locale) => css`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  margin: 0 auto 10px;
  padding: 0 2em;

  white-space: pre-line;
  width: 70%;

  ${mq.medium(css`
    font-size: ${locale === "br_pt" ? '18px' : '22px'};
    line-height: 28px;
    margin-bottom: ${locale === "br_pt" ? '15px' : '55px'};
    ${locale === "au_en" && 'margin-bottom:30px'};
  `)};
`;

const secondSentenceCss = css`
  font-size: 12px;
  font-weight: 500;
  margin: 0 auto;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  top: -45px;
  width: 70%;

  ${mq.medium(css`
    font-size: 14px;
    top: -25px;
    width: 50%;
    /* position: static; */
  `)};
`;

const sentenceDiscoverCss = css`
  font-size: 17px;
  color: #fff;
  position: absolute;
  bottom: -135px;
  text-align: center;
  margin: 0 auto;
  width: 100%;

  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 15px;
    width: 40px;
  }
`;

export default PopinLoose;
