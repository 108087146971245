export default {
  color: '為由OMY團隊設計的城市插圖填上繽紛色彩，有機會獲得活動驚喜優惠！',
  btnStart: '開始填色吧!',
  avatar: '選擇你的OMY人物頭像',
  instructionTitle: '填色遊戲說明',
  instruction: '點選插圖空白範圍，以填上顏色。',
  usersColorize: '有XX位參加者正在繪製插圖中',
  instructionColorize: `選擇你喜愛的顏色，並為你選取的插圖範圍上色。
為整個插圖位置填色後，有機會獲得隱藏的小驚喜哦!`,
  sorry: `下次有機會獲得隱藏的小驚喜。`,
  eshop: "折扣碼可於L'Occitane 香港官網使用",
  discoverMore: '探索更多',
  win: `恭喜你獲得優惠碼！
  可於L'Occitane 香港官網使用。`,
  tryAgain: '再試一次！',
  close: '關閉',
  discover: '繼續探索插圖的其他位置尋找驚喜。',
  discoverUrl: '',
  howTo: '如何使用優惠碼?',
  push: '探索乳油木OMY限定版系列商品',
  rangeURL:
    'https://fr.loccitane.com/cr%C3%A8me-mains-fleurs-de-cerisier,74,1,24788,432117.htm',
  colorAll: '',
  globalFBTitle: '為普羅旺斯的風景填上顏色！',
  globalFBText:
    "立即參加L'OCCITANE OMY 地圖填色遊戲，有機會獲得限時優惠碼。#provenceeverywhere",
  globalTWText:
    "立即參加L'OCCITANE OMY 地圖填色遊戲，有機會獲得限時優惠碼。#provenceeverywhere",
  localFBTitle: '為普羅旺斯的風景填上顏色！',
  localFBText:
    "輪到你為普羅旺斯的風景填上顏色！ 立即參加L'OCCITANE OMY 地圖填色遊戲，有機會獲得限時優惠碼！#provenceEverywhere",
  localTWText:
    "輪到你為普羅旺斯的風景填上顏色！ 立即參加L'OCCITANE OMY 地圖填色遊戲，有機會獲得限時優惠碼！#provenceEverywhere",
  frescoPourcent: `百分率代表地圖完成度。
想知道整個城市填上色彩後的樣子嗎？歡迎到香港L'Occitane Instagram官方帳號: @loccitane_hk`,
  tc: '',
  tcTitle: '',
  frescoCompleted: '',
  ie:
    'Il tuo browser non è compatibile con il nostro gioco. Ti preghiamo di utilizzarne uno più recente.',
  continue: 'Continua a colorare per trovare il tuo buono!',
  eye: '',
  howToURL: 'https://it.loccitane.com/offerta-colorata,80,2,97763,1465875.htm'
}
