export default {
  color: '開始為由OMY團隊設計的城市插圖著上繽紛色彩，有機會獲得驚喜優惠！',
  btnStart: '開始著色吧!',
  avatar: '你的OMY大頭貼',
  instructionTitle: '彩繪遊戲說明',
  instruction: '點選插圖空白區塊，以進行著色。',
  usersColorize: '位參加者正在繪製插圖中',
  instructionColorize: `選擇你喜愛的顏色，並填滿整個區塊。
完成插圖區塊著色後，有機會獲得隱藏的小驚喜哦!`,
  sorry: `很可惜這次沒有獲得隱藏的活動折扣碼。`,
  eshop: '折扣碼可至歐舒丹官網使用',
  discoverMore: '探索更多',
  win: `恭喜你! 獲得活動折扣碼。`,
  tryAgain: '再試一次吧！',
  close: '關閉',
  discover: '探索其他插圖區塊。',
  discoverUrl: '',
  howTo: '如何使用活動折扣碼?',
  push: '探索乳油木OMY彩繪限定版系列商品',
  rangeURL: 'https://tw.loccitane.com/?s=97756',
  colorAll: '選擇一個顏色後，請以同一種顏色塗滿整個區塊。',
  globalFBTitle: '一起線上瘋畫畫!',
  globalFBText:
    "參加L'OCCITANE 歐舒丹線上OMY彩繪遊戲，有機會獲得活動折扣碼。#provenceEverywhere",
  globalTWText:
    "參加L'OCCITANE 歐舒丹線上OMY彩繪遊戲，有機會獲得活動折扣碼。#provenceEverywhere",
  localFBTitle: '一起線上瘋畫畫!',
  localFBText:
    "參加L'OCCITANE 歐舒丹線上OMY彩繪遊戲，有機會獲得活動折扣碼。#provenceEverywhere",
  localTWText:
    "你還沒有玩過歐舒丹線上彩繪嗎? 快至L'OCCITANE 歐舒丹線上OMY彩繪地圖進行著色，有機會獲彩繪乳油木活動折扣碼。#provenceEverywhere",
  frescoPourcent: `百分比是表示彩繪地圖完成度。
想知道彩繪地圖完成的樣子嗎？歡迎至台灣歐舒丹Instagram官方帳號: @loccitanetw`,
  tc: 'https://tw.loccitane.com/color_game_rule,40,2,97755,1465048.htm',
  tcTitle: '遊戲條款及規範',
  frescoCompleted: `此彩繪地圖自X天前開著色`,
  ie: '您使用的瀏覽器不適用，請嘗試其他瀏覽器以進行遊戲。',
  continue: '繼續下一次著色尋找折扣碼。',
  eye: '',
  howToURL: 'https://tw.loccitane.com/color_game_rule,40,2,97755,1465048.htm'
}
