export default `precision lowp float;

uniform sampler2D uSampler;
uniform vec2 uResolution;
uniform float uRadius;
uniform float uSoftness;
uniform float uOpacity;

varying vec2 vTextureCoord;

void main() {

	vec4 texColor = texture2D(uSampler, vTextureCoord);

	vec2 position = gl_FragCoord.xy / uResolution - vec2(0.5);

	float len = length(position);

	float vignette = smoothstep(uRadius, uRadius - uSoftness, len);

	texColor.rgb = mix(texColor.rgb, texColor.rgb * vignette, uOpacity);

	gl_FragColor = texColor;
}
`;
