import { isMobile, range } from "../utils";
// import lethargy from "lethargy";
import { TweenMax, Sine, Power3, TimelineMax } from "gsap";
import * as PIXI from "pixi.js";
// import normalizeWheel from "normalize-wheel";
// import * as dat from "dat.gui";
import { ZoomBlurFilter } from "@pixi/filter-zoom-blur";
// import { TiltShiftFilter } from "@pixi/filter-tilt-shift";

import EmitterManager from "./EmitterManager";
// import PixiApp from "./PixiApp";
import store from "../datas/store";
import BlurFilter from "./BlurFilter";
import detectIt from "detect-it";
// import "gsap/PixiPlugin";

// import MainContainer from "./MainContainer";
// import MainContainer from "./MainContainer";

class Viewport {
  constructor() {
    // global
    this.mult = 1;
    this.tileNbX = 12;
    this.tileNbY = 10;
    this.gui =
      /*process.env.NODE_ENV === "development" ? new dat.GUI() : */ null;
    this.isMobile = isMobile();
    this.Hpercentage = this.isMobile ? 0.7 : 0.5;
    this.Wpercentage = this.isMobile ? 0.95 : 0.8;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.interactive = false;
    this.isDragging = false;
    // mousemove
    this.mouseMoving = false;
    this.xTo = 0;
    this.yTo = 0;
    this.distanceToMove = 100;
    this.easeRatio = 0.09;
    // click
    this.spriteSelected = null;
    // drag
    this.lastMouseX = null;
    this.lastMouseY = null;
    this.thresholdDist = 15;
    this.mouseDistance = {
      x: 0,
      y: 0,
    };
    this.xMultiplicator = 2.5;
    this.yMultiplicator = 2.5;
    this.reverse = -1;
    this.lastDistX = 0;
    this.dragMoved = false;
    this.maxRotation = 0.04;
    this.currentRotation = this.factorXrotation = 0;
    // zoom
    this.direction = 0;
    this.isZooming = false;
    this.lastZoom = null;
    this.currentZoom = this.isMobile ? 0.2 : 0.27;
    this.initalZoom = this.isMobile ? 0.2 : 0.27;
    this.maxZoom = this.isMobile ? 0.7 : 0.9;
    this.minZoom = this.isMobile ? 0.2 : 0.27;
    this.zoomToAdd = 0;
    this.maxBlurStrengh = 1.4;
    this.minBlurStrengh = 0.8;
    this.maxBlurQuality = 3.8;
    this.minBlurQuality = 2.1;

    // pinch
    this.activePinch = false;
    this.isPinching = false;
    this.pinchEaseRatio = 0.1;
    this.touches = [];
    this.pinchSpeed = 1;
    // bounce
    this.time = 400;
    this.startBouncing = false;
    // delerate
    this.friction = 0.95;
    this.minSpeed = 0.01;
    this.distX = 0;
    this.allowScroll = false;
    this.justClicked = false;
  }

  easeInOutQuart = (t, b, c, d) => {
    if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t + b;
    return (-c / 2) * ((t -= 2) * t * t * t - 2) + b;
  };

  easeInOutElastic = (t, b, c, d) => {
    var s = 1.70158;
    var p = 0;
    var a = c;
    if (t === 0) return b;
    if ((t /= d / 2) === 2) return b + c;
    if (!p) p = d * (0.3 * 1.5);
    if (a < Math.abs(c)) {
      a = c;
      var s = p / 4;
    } else var s = (p / (2 * Math.PI)) * Math.asin(c / a);
    if (t < 1)
      return (
        -0.5 *
          (a *
            Math.pow(2, 10 * (t -= 1)) *
            Math.sin(((t * d - s) * (2 * Math.PI)) / p)) +
        b
      );
    return (
      a *
        Math.pow(2, -10 * (t -= 1)) *
        Math.sin(((t * d - s) * (2 * Math.PI)) / p) *
        0.5 +
      c +
      b
    );
  };

  // initGUI = () => {
  //   let globalFolder = this.gui.addFolder("Global");
  //   globalFolder.add(this, "interactive");

  //   let marginXChanger = () => {
  //     this.maxX = (this.worldWidth - this.screenWidth) * -1 + this.marginX;
  //     this.minX = 0 - this.marginX;
  //   };

  //   let marginYChanger = () => {
  //     this.maxY = (this.worldHeight - this.screenHeight) * -1 + this.marginY;
  //     this.minY = 0 - this.marginY;
  //   };

  //   globalFolder.add(this, "easeRatio", 0, 1);
  //   globalFolder.add(this, "marginX", 0, 3000).onChange(marginXChanger);
  //   globalFolder.add(this, "marginY", 0, 3000).onChange(marginYChanger);
  //   let mouseMoveFolder = this.gui.addFolder("Mouse Movement");
  //   mouseMoveFolder.add(this, "distanceToMove", 20, 500);

  //   let dragFolder = this.gui.addFolder("Drag");
  //   dragFolder.add(this, "thresholdDist", 5, 30);
  //   dragFolder.add(this, "xMultiplicator", 0.1, 10);
  //   dragFolder.add(this, "yMultiplicator", 0.1, 10);

  //   const zoomPosition = {
  //     currentZoom: this.scene.scale.x
  //   };

  //   let zoomChanger = () => {
  //     const point = new PIXI.Point(
  //       window.innerWidth / 2,
  //       window.innerHeight / 2
  //     );
  //     let oldPoint;
  //     oldPoint = this.scene.toLocal(point);
  //     this.scene.scale.x = zoomPosition.currentZoom;
  //     this.scene.scale.y = zoomPosition.currentZoom;
  //     const newPoint = this.scene.toGlobal(oldPoint);
  //     this.scene.x += point.x - newPoint.x;
  //     this.scene.y += point.y - newPoint.y;
  //   };

  //   let zoomFolder = this.gui.addFolder("Zoom");
  //   zoomFolder
  //     .add(zoomPosition, "currentZoom", 0.1, 1, 0.05)
  //     .onChange(zoomChanger);
  //   zoomFolder.add(this, "minZoom", 0.15, 1);
  //   zoomFolder.add(this, "maxZoom", 0.15, 1);

  //   let pinchfolder = this.gui.addFolder("Pinch");
  //   pinchfolder.add(this, "pinchEaseRatio", 0, 1);
  //   pinchfolder.add(this, "pinchSpeed", 1, 5);

  //   let xLockChanger = () => {
  //     this.lockMinDragX = this.minX + this.xLock;
  //     this.lockMaxDragX = this.maxX - this.xLock;
  //   };

  //   let yLockChanger = () => {
  //     this.lockMinDragY = this.minY + this.yLock;
  //     this.lockMaxDragY = this.maxY - this.yLock;
  //   };

  //   let bounceFolder = this.gui.addFolder("Bounce");
  //   bounceFolder.add(this, "time", 1, 1000);
  //   bounceFolder.add(this, "xLock", 0, 1000).onChange(xLockChanger);
  //   bounceFolder.add(this, "yLock", 0, 1000).onChange(yLockChanger);

  //   let blurXChanger = () => {
  //     this.zoomBlurFilter.center.x = this.zoomBlurCenterX;
  //   };

  //   let blurYChanger = () => {
  //     this.zoomBlurFilter.center.y = this.zoomBlurCenterY;
  //   };

  //   let zoomBlurStrenghChanger = () => {
  //     this.zoomBlurFilter.strength = this.zoomBlurStrength;
  //   };

  //   let zoomBlurInnerRadiusChanger = () => {
  //     this.zoomBlurFilter.innerRadius = this.zoomBlurInnerRadius;
  //   };

  //   let zoomBlurFolder = this.gui.addFolder("Zoom Blur");
  //   zoomBlurFolder
  //     .add(this, "zoomBlurCenterX", 1, window.innerWidth)
  //     .onChange(blurXChanger);
  //   zoomBlurFolder
  //     .add(this, "zoomBlurCenterY", 1, window.innerWidth)
  //     .onChange(blurYChanger);
  //   zoomBlurFolder
  //     .add(this, "zoomBlurStrength", 0, 0.1)
  //     .onChange(zoomBlurStrenghChanger);
  //   zoomBlurFolder
  //     .add(this, "zoomBlurInnerRadius", 0, window.innerWidth)
  //     .onChange(zoomBlurInnerRadiusChanger);

  //   let blurStrenghChanger = () => {
  //     this.blurCustomFilter.blur = this.blurStrength;
  //   };

  //   let blurQualityChanger = () => {
  //     this.blurCustomFilter.quality = this.blurQuality;
  //   };

  //   let blurFolder = this.gui.addFolder("Blur");

  //   blurFolder.add(this, "blurStrength", 0, 50).onChange(blurStrenghChanger);
  //   blurFolder.add(this, "blurQuality", 0, 50).onChange(blurQualityChanger);

  //   let containerRotationChanger = () => {
  //     this.scene.rotation = this.containerRotation;
  //   };

  //   let containerRotationFolder = this.gui.addFolder("Container rotation");
  //   containerRotationFolder
  //     .add(this, "containerRotation", -1, 1, 0.01)
  //     .onChange(containerRotationChanger);
  // };

  /**
   * change zoom so the width fits in the viewport
   * @param {number} [width=this.worldWidth] in world coordinates
   * @param {boolean} [center] maintain the same center
   * @param {boolean} [scaleY=true] whether to set scaleY=scaleX
   * @param {boolean} [noClamp] whether to disable clamp-zoom
   * @returns {Viewport} this
   */
  fitWidth(width, center, scaleY = true) {
    let save;
    if (center) {
      save = this.center;
    }
    this.scale.x = this.screenWidth / width;

    if (scaleY) {
      this.scale.y = this.scale.x;
    }

    if (center) {
      this.moveCenter(save);
    }
    return this;
  }

  get right() {
    return -this.scene.x / this.scene.scale.x + this.worldScreenWidth;
  }

  /**
   * world coordinates of the left edge of the screen
   * @type { number }
   */
  get left() {
    return -this.scene.x / this.scene.scale.x;
  }

  /**
   * world coordinates of the top edge of the screen
   * @type {number}
   */
  get top() {
    return -this.scene.y / this.scene.scale.y;
  }

  /**
   * world coordinates of the bottom edge of the screen
   * @type {number}
   */
  get bottom() {
    return -this.scene.y / this.scene.scale.y + this.worldScreenHeight;
  }
  /**
   * screen width in world coordinates
   * @type {number}
   */
  get worldScreenWidth() {
    return this.screenWidth / this.scene.scale.x;
  }

  /**
   * screen height in world coordinates
   * @type {number}
   */
  get worldScreenHeight() {
    return this.screenHeight / this.scene.scale.y;
  }

  /**
   * center of screen in world coordinates
   * @type {PIXI.Point}
   */
  get center() {
    return new PIXI.Point(
      this.worldScreenWidth / 2 - this.scene.x / this.scene.scale.x,
      this.worldScreenHeight / 2 - this.scene.y / this.scene.scale.y
    );
  }

  /**
   * get visible bounds of viewport
   * @returns {PIXI.Rectangle}
   */
  getVisibleBounds() {
    return new PIXI.Rectangle(
      this.left,
      this.top,
      this.worldScreenWidth,
      this.worldScreenHeight
    );
  }

  /**
   * remove touch by number
   * @param {number} id
   */
  removeTouch(id) {
    for (let i = 0; i < this.touches.length; i++) {
      if (this.touches[i].id === id) {
        this.touches.splice(i, 1);
        return;
      }
    }
  }

  /**
   * get touch by id
   * @param {number} id
   * @return {ViewportTouch}
   */
  get(id) {
    for (let touch of this.touches) {
      if (touch.id === id) {
        return touch;
      }
    }
    return null;
  }

  /**
   * @param {number} index
   * @return {tile}
   */
  getOneTile = (index) => {
    let tile = null;
    this.scene.children.forEach((child) => {
      if (child.name && child.name.match(`tiles_${index}`)) {
        tile = child;
      }
    });
    return tile;
  };

  initEvent = () => {
    if (detectIt.primaryInput === "touch") {
      window.addEventListener("touchmove", (event) => {
        if (event.touches.length > 1) return;

        this.handleWindowMouseMove({
          clientX: event.changedTouches[0].clientX,
          clientY: event.changedTouches[0].clientY,
          target: event.target,
          id: event.changedTouches[0].identifier,
        });
      });
      window.addEventListener("touchstart", (event) => {
        if (event.touches.length > 1) return;

        this.handleWindowMouseDown({
          clientX: event.changedTouches[0].clientX,
          clientY: event.changedTouches[0].clientY,
          target: event.target,
          id: event.changedTouches[0].identifier,
        });
      });
      window.addEventListener("touchend", (event) => {
        this.handleWindowMouseUp({
          clientX: event.changedTouches[0].clientX,
          clientY: event.changedTouches[0].clientY,
          target: event.target,
          id: event.changedTouches[0].identifier,
        });
      });
      // document.body.addEventListener("touchend", this.handleWindowMouseOut);
    } else {
      window.addEventListener("mousemove", this.handleWindowMouseMove);
      window.addEventListener("mousedown", this.handleWindowMouseDown);
      // window.addEventListener("wheel", this.handleMouseWheel, {
      // passive: false
      // });
      window.addEventListener("mouseup", this.handleWindowMouseUp);
      document.body.addEventListener("mouseleave", this.handleWindowMouseOut);
      // window.addEventListener('pointerout', this.handleWindowMouseOut);
    }
  };

  init = (container) => {
    this.scene = container;
    this.tileW = 615 * 2;
    this.tileH = 548 * 2;

    this.sceneW = this.tileW * this.currentZoom * this.tileNbX;
    this.sceneH = this.tileH * this.currentZoom * this.tileNbY;

    this.scene.scale.x = this.currentZoom;
    this.scene.scale.y = this.currentZoom;
    this.worldWidth = this.sceneW;
    this.worldHeight = this.sceneH;

    this.marginX = this.isMobile ? 200 : 600;
    this.marginY = this.isMobile ? 300 : 400;

    this.xLock = 100;
    this.yLock = 150;

    this.maxX = (this.worldWidth - this.screenWidth) * -1 + this.marginX;
    this.maxY = (this.worldHeight - this.screenHeight) * -1 + this.marginY;
    this.minX = 0 - this.marginX;
    this.minY = 0 - this.marginY;

    this.lockMinDragX = this.minX + this.xLock;
    this.lockMaxDragX = this.maxX - this.xLock;

    this.lockMinDragY = this.minY + this.yLock;
    this.lockMaxDragY = this.maxY - this.yLock;

    // console.log("MAX", this.maxX);

    // console.log(
    //   "MAX MARGIN X",
    //   (this.marginX / this.initalZoom) * this.maxZoom
    // );
    // console.log(
    //   "MIN MARGIN X",
    //   (this.marginX / this.initalZoom) * this.minZoom
    // );

    // console.log("current lock min x", this.lockMinDragX);
    // console.log("current lock max x", this.lockMaxDragX);
    // init Zoom Blur

    this.cntExploration = new PIXI.Container();
    this.cntExploration.pivot.x = this.cntExploration.width / 2;
    this.cntExploration.pivot.y = this.cntExploration.height / 2;

    this.containerRotation = 0;
    this.zoomBlurCenterX = window.innerWidth / 2;
    this.zoomBlurCenterY = window.innerHeight / 2;
    this.zoomBlurStrength = 0.05;
    this.zoomBlurInnerRadius = 0;
    this.zoomBlurFilter = new ZoomBlurFilter();
    this.zoomBlurFilter.center.x = this.zoomBlurCenterX;
    this.zoomBlurFilter.center.y = this.zoomBlurCenterY;
    this.zoomBlurFilter.strength = this.zoomBlurStrength;
    this.zoomBlurFilter.innerRadius = this.zoomBlurInnerRadius;

    this.cntExploration.addChild(this.scene);
    // this.cntExploration.filters = [this.customFilter];
    this.blurQuality = this.maxBlurQuality / 2;
    this.blurStrength = this.maxBlurStrengh / 2;
    this.blurCustomFilter = new BlurFilter(this.blurStrength, this.blurQuality);
    this.blurCustomFilter.pixelSize.x = this.blurCustomFilter.pixelSize.y = 1;
    this.cntExploration.filters = [this.blurCustomFilter];

    EmitterManager.on("ALLOW_SCROLL", this.handleScrollViewport);
  };

  handleScrollViewport = (val) => {
    this.allowScroll = val;
  };

  setSelectedSprite = () => {
    // alert("event init");
    EmitterManager.on("SPRITE_SELECTED", (sprite) => {
      // alert("event received");
      this.spriteSelected = sprite;
    });
  };

  enableInteractive = () => {
    this.interactive = true;
  };

  disableInteractive = () => {
    this.interactive = false;
  };

  handleColorizeEnd = () => {
    // let scene = this.scene;
    EmitterManager.on("COLORIZE:END", () => {
      // let lastSelectedSprite = this.lastSelectedSprite;

      this.justClicked = true;
      TweenMax.delayedCall(2, () => {
        this.justClicked = false;
      });

      if (this.currentZoom > this.scaleTo && this.scaleTo > this.minZoom) {
        this.onZoomEnd();
        this.lastSelectedSprite = null;
      } else {
        this.isZoomingOnSprite = true;
        TweenMax.to(this.scene.scale, 0.9, {
          x: this.originalParams.zoom,
          y: this.originalParams.zoom,
          delay: 0.4,
          ease: Power3.easeInOut,
        });

        TweenMax.to(this.scene, 0.9, {
          // y: this.minZoom,
          x: this.originalParams.x,
          y: this.originalParams.y,
          delay: 0.4,
          ease: Power3.easeInOut,
          // onUpdate: () => {
          //   var bounds = lastSelectedSprite.getBounds();
          //   var center = new PIXI.Point(
          //     bounds.x + bounds.width / 2,
          //     bounds.y + bounds.height / 2
          //   );
          //   let newScenePos = {
          //     x: this.scene.x + -center.x,
          //     y: this.scene.y + -center.y
          //   };
          //   let point = new PIXI.Point(
          //     newScenePos.x + window.innerWidth / 2,
          //     newScenePos.y + window.innerHeight / 2
          //   );
          //   scene.x += point.x - scene.x;
          //   scene.y += point.y - scene.y;

          //   console.log("YOO", bounds);
          // },
          onComplete: () => {
            // console.log("done centering");
            this.isZoomingOnSprite = false;
            this.lastSelectedSprite = null;
            this.onZoomEnd();
          },
        });
      }

      if (this.currentZoom < this.scaleTo) {
        this.isZoomingOnSprite = true;

        TweenMax.to(this.scene.scale, 0.9, {
          x: this.originalParams.zoom,
          y: this.originalParams.zoom,
          delay: 0.4,
          ease: Power3.easeInOut,
        });

        TweenMax.to(this.scene, 0.9, {
          // scale: this.originalParams.scale,
          // y: this.currentZoom,
          x: this.originalParams.x,
          y: this.originalParams.y,
          delay: 0.4,
          ease: Power3.easeInOut,
          // onUpdate: () => {
          //   var bounds = lastSelectedSprite.getBounds();
          //   var center = new PIXI.Point(
          //     bounds.x + bounds.width / 2,
          //     bounds.y + bounds.height / 2
          //   );
          //   let newScenePos = {
          //     x: this.scene.x + -center.x,
          //     y: this.scene.y + -center.y
          //   };
          //   let point = new PIXI.Point(
          //     newScenePos.x + window.innerWidth / 2,
          //     newScenePos.y + window.innerHeight / 2
          //   );
          //   scene.x += point.x - scene.x;
          //   scene.y += point.y - scene.y;
          // },
          onComplete: () => {
            // console.log("done centering");
            this.lastSelectedSprite = null;
            this.isZoomingOnSprite = false;
          },
        });
      }
    });
  };

  /**
   * Zoom on center
   * @param zoomToAdd
   * @param duration
   * @param delay
   */
  zoomOnCenter = (zoomToAdd, duration, delay) => {
    var newScenePos = {
      x: this.scene.x + -(window.innerWidth / 2),
      y: this.scene.y + -(window.innerHeight / 2),
    };

    let multiplicator = (zoomToAdd + this.currentZoom) / this.currentZoom;
    let smoothingCenter = new PIXI.Point(newScenePos.x, newScenePos.y);

    let finalX = smoothingCenter.x * multiplicator + window.innerWidth / 2;
    let finalY = smoothingCenter.y * multiplicator + window.innerHeight / 2;

    this.lastCenter = {
      x: smoothingCenter.x * multiplicator,
      y: smoothingCenter.y * multiplicator,
    };

    const tl = new TimelineMax({
      delay: delay,
      onComplete: () => {
        this.currentZoom = this.scene.scale.x;
        this.initalZoom = this.scene.scale.x;
        this.onZoomEnd();
      },
    });
    tl.to(this.scene.scale, duration, {
      x: zoomToAdd + this.currentZoom,
      y: zoomToAdd + this.currentZoom,
      ease: Power3.easeInOut,
    });
    tl.to(
      this.scene,
      duration,
      {
        x: finalX,
        y: finalY,
        ease: Power3.easeInOut,
      },
      0
    );
  };

  /**
   * Zoom on center click on button +/-
   * @param zoomToAdd
   */
  zoomOncenterButton = (zoomToAdd) => {
    console.log(this.delecerateX, this.decelerateY);
    if (
      !this.interactive ||
      this.isDragging /* ||
      this.delecerateX !== false ||
      this.decelerateY !== false*/
    )
      return;

    this.mouseMoving = false;
    this.dragMoved = false;
    this.isDragging = false;
    if (this.isZooming) {
      this.onZoomEnd();
    }
    this.isPinching = false;
    // console.log("click on button");

    let newScenePos;

    if (this.particularZoom) {
      newScenePos = {
        x: this.lastFinalPos.x + -(window.innerWidth / 2),
        y: this.lastFinalPos.y + -(window.innerHeight / 2),
      };
    } else {
      newScenePos = {
        x: this.scene.x + -(window.innerWidth / 2),
        y: this.scene.y + -(window.innerHeight / 2),
      };
    }

    // console.log(newScenePos);

    this.smoothingCenter = new PIXI.Point(newScenePos.x, newScenePos.y);

    this.scaleTo = this.currentZoom + zoomToAdd;

    if (this.scaleTo > this.maxZoom) {
      this.scaleTo = this.maxZoom;
    }

    if (this.scaleTo < this.minZoom) {
      this.scaleTo = this.minZoom;
    }

    var mapValue = range(
      this.scaleTo,
      this.minZoom,
      this.maxZoom,
      0,
      this.maxBlurStrengh
    );
    var mapValueQuality = range(
      this.scaleTo,
      this.minZoom,
      this.maxZoom,
      this.minBlurQuality,
      this.maxBlurQuality
    );

    this.blurCustomFilter.blur = mapValue;
    this.blurCustomFilter.quality = mapValueQuality;

    let multiplicator = this.scaleTo / this.currentZoom;

    this.offsetX =
      this.smoothingCenter.x * multiplicator + window.innerWidth / 2;
    this.offsetY =
      this.smoothingCenter.y * multiplicator + window.innerHeight / 2;

    this.lastFinalPos = {
      x: this.offsetX,
      y: this.offsetY,
    };
    this.particularZoom = true;
    this.btnZoom = true;
    this.onZoomEnd(this.scaleTo);
  };

  centerOnOneSprite = (event) => {
    // if (!this.interactive) return;

    this.justClicked = true;
    TweenMax.delayedCall(2, () => {
      this.justClicked = false;
    });

    EmitterManager.emit("SHOW:COLORSELECTION");
    this.mouseMoving = false;
    this.dragMoved = false;
    this.isDragging = false;
    if (this.isZooming) {
      this.onZoomEnd();
    }
    this.isPinching = false;

    this.originalParams = {
      x: this.scene.x,
      y: this.scene.y,
      zoom: this.scene.scale.x,
    };

    // console.log(this.originalParams);

    this.disableInteractive();
    // console.log("click on sprite");

    this.lastSelectedSprite = this.spriteSelected;

    var bounds = this.spriteSelected.getBounds();
    var center = new PIXI.Point(
      bounds.x + bounds.width / 2,
      bounds.y + bounds.height / 2
    );
    let newScenePos = {
      x: this.scenePosX + -center.x,
      y: this.scenePosY + -center.y,
    };
    this.smoothingCenter = new PIXI.Point(newScenePos.x, newScenePos.y);
    /**
     * get container scale
     */

    let pertencagePxH = window.innerHeight * this.Hpercentage;
    let pertencagePxW = window.innerWidth * this.Wpercentage;

    let multiplicator = pertencagePxH / bounds.height;
    let finalW = multiplicator * bounds.width;

    if (finalW > this.screenWidth) {
      multiplicator = pertencagePxW / bounds.width;
    }

    multiplicator *= this.isMobile ? 0.5 : 1;
    // console.log(multiplicator);

    this.showBackground();
    this.scaleTo = multiplicator * this.scene.scale.x;

    /**
     *  define max zoom
     */
    if (this.scaleTo > this.maxZoom) {
      this.scaleTo = this.maxZoom;
      multiplicator = this.scaleTo / this.currentZoom;
    }

    this.offsetX =
      this.smoothingCenter.x * multiplicator + window.innerWidth / 2;
    this.offsetY =
      this.smoothingCenter.y * multiplicator + window.innerHeight / 2;

    this.particularZoom = true;
  };

  /**
   * Init scene on specific tile
   * @param {PIXI.Container} tile
   */
  centerOnOneTile = (tile) => {
    // let bounds = tile.getBounds();
    let bounds;
    // console.log(bounds);
    if (this.isMobile) {
      bounds = new PIXI.Rectangle(492, 657, 246, 219);
    } else {
      bounds = new PIXI.Rectangle(
        664.2000122070312,
        887.760009765625,
        332.0999755859375,
        295.9200439453125
      );
    }

    if (bounds.x === 0) {
      this.scenePosX = 0;
    } else if (bounds.x + bounds.width !== this.worldWidth && bounds.x !== 0) {
      let center = bounds.x + bounds.width / 2;
      this.scene.x = -center;
      this.scenePosX = this.scene.x;
    } else if (bounds.x + bounds.width === this.worldWidth) {
      this.scene.x = -bounds.x;
      this.scenePosX = this.scene.x;
    }

    if (bounds.y === 0) {
      this.scenePosY = 0;
    } else if (
      bounds.y + bounds.height !== this.worldHeight &&
      bounds.y !== 0
    ) {
      let center = bounds.y + bounds.height / 2;
      this.scene.y = -center;
      this.scenePosY = this.scene.y;
    } else if (bounds.y + bounds.height === this.worldHeight) {
      this.scene.y = -bounds.y;
      this.scenePosY = this.scene.y;
    }

    // console.log('SCENE POS X: ', this.scenePosX)
  };

  handleWindowMouseMove = (event) => {
    // const eventX = event.touches ? event.touches[0].clientX : event.clientX;
    // const eventY = event.touches ? event.touches[0].clientY : event.clientY;

    if (
      this.isDragging &&
      // !this.activePinch &&
      this.interactive &&
      !this.isZoomingOnSprite
    ) {
      /**
       * Difference click and drag
       */
      // this.mouseMoving = false
      let distX = this.lastMouseX - event.clientX;
      let distY = this.lastMouseY - event.clientY;

      if (
        distX > this.thresholdDist ||
        distX < -this.thresholdDist ||
        distY > this.thresholdDist ||
        distY < -this.thresholdDist
      ) {
        if (!this.dragMoved) {
          // if drag start reset click on sprite
          if (this.spriteSelected) {
            this.spriteSelected = null;
          }
          if (this.btnZoom) {
            this.onCenterBtnEnd();
          }
          // console.log("drag start");
          this.onDragStart();
          this.dragMoved = true;
        }
        this.handleDrag(event);
      }
    } else {
      let x = event.clientX;
      let y = event.clientY;
      if (this.xLast && !this.dragMoved) {
        let dx = x - this.xLast;
        let dy = y - this.yLast;

        this.mouseMoving = true;
        this.xTo +=
          ((dx * this.reverse) / window.innerWidth) * this.distanceToMove;
        this.yTo +=
          ((dy * this.reverse) / window.innerHeight) * this.distanceToMove;

        if (this.xDragTo >= this.minX) {
          this.lockMouse = true;
          // console.log('lock mouse moooove', console.log(this.xExplorationTo));
        } else {
          this.xExplorationTo = this.xTo;
          // this.lockMouse = false;
        }
        this.yExplorationTo = this.yTo;

        // this.lastX = this.xExplorationTo
      }
      this.xLast = x;
      this.yLast = y;
    }

    // if (this.activePinch) {
    // this.handlePinch(event);
    // }
  };

  handleWindowMouseDown = (event) => {
    // console.log(event.target);
    if (
      event.target.classList.contains("no-drag") ||
      event.target.parentNode.classList.contains("no-drag")
    )
      return;

    if (detectIt.primaryInput === "touch") {
      this.touches.push({
        id: event.id,
        last: null,
      });

      if (this.touches.length >= 2) {
        this.activePinch = true;
      }
    }

    this.isDragging = true;
    TweenMax.killDelayedCallsTo(this.removePointer);
    setTimeout(() => {
      document.documentElement.classList.add("pe-none");
    }, 100);

    EmitterManager.emit("DRAG_ON");

    this.lastMouseX = event.clientX;
    this.lastMouseY = event.clientY;
    this.scenePosX = this.scene.x;
    this.scenePosY = this.scene.y;

    this.onDecelerateDown();
  };

  handleWindowMouseUp = (event) => {
    // console.log('mouseup')
    if (this.touches.length) {
      this.removeTouch(event.id);
    }

    if (this.touches.length < 2) {
      this.activePinch = false;
    }

    if (this.spriteSelected && !this.isZoomingOnSprite && !this.activePinch) {
      this.centerOnOneSprite(event);
      this.spriteSelected = null;
    }

    if (this.isDragging) {
      this.onDragUp(event);
    }
  };

  handleWindowMouseOut = (event) => {
    // console.log("mouseout");
    if (this.isDragging) {
      this.onDragUp();
    }
  };

  // handleMouseWheel = event => {
  //   if (!this.allowScroll) event.preventDefault();
  //   if (!this.dragMoved && this.interactive) {
  //     if (this.particularZoom) {
  //       this.onZoomEnd();
  //       this.onCenterBtnEnd();
  //     }
  //     this.isZooming = true;
  //     if (!this.zoomStart) {
  //       console.log("zoom start");
  //       this.zoomStart = true;
  //     }
  //     this.direction = event.deltaY < 0 ? 1 : -1;
  //     var factor = 1 + this.direction * 0.1;
  //     this.scaleTo = this.currentZoom *= factor;
  //     if (this.scaleTo <= this.minZoom) {
  //       this.scaleTo = this.minZoom;
  //     }

  //     if (this.scaleTo >= this.maxZoom) {
  //       this.scaleTo = this.maxZoom;
  //     }

  //     var mapValue = range(
  //       this.scaleTo,
  //       this.minZoom,
  //       this.maxZoom,
  //       0,
  //       this.maxBlurStrengh
  //     );
  //     var mapValueQuality = range(
  //       this.scaleTo,
  //       this.minZoom,
  //       this.maxZoom,
  //       this.minBlurQuality,
  //       this.maxBlurQuality
  //     );
  //     this.blurCustomFilter.blur = mapValue;
  //     this.blurCustomFilter.quality = mapValueQuality;

  //     console.log("MAAP VALUE QUALITY", mapValueQuality);

  //     // Define limit of scene
  //     if (this.lastZoom === null) {
  //       this.marginX = (this.marginX / this.initalZoom) * this.currentZoom;
  //       this.marginY = (this.marginY / this.initalZoom) * this.currentZoom;
  //     } else {
  //       this.marginX = (this.marginX / this.lastZoom) * this.currentZoom;
  //       this.marginY = (this.marginY / this.lastZoom) * this.currentZoom;
  //     }
  //     this.lastZoom = this.currentZoom;

  //     let point = new PIXI.Point(event.clientX, event.clientY);
  //     this.smoothingCenter = point;
  //   }
  // };

  // handlePinch = event => {
  //   let x = event.clientX;
  //   let y = event.clientY;

  //   if (this.touches.length >= 2) {
  //     const first = this.touches[0];
  //     const second = this.touches[1];
  //     const last =
  //       first.last && second.last
  //         ? Math.sqrt(
  //             Math.pow(second.last.x - first.last.x, 2) +
  //               Math.pow(second.last.y - first.last.y, 2)
  //           )
  //         : null;
  //     if (first.id === event.id) {
  //       first.last = {
  //         x,
  //         y
  //       };
  //     } else if (second.id === event.id) {
  //       second.last = {
  //         x,
  //         y
  //       };
  //     }

  //     if (last) {
  //       const point = {
  //         x: first.last.x + (second.last.x - first.last.x) / 2,
  //         y: first.last.y + (second.last.y - first.last.y) / 2
  //       };
  //       this.smoothingCenter = point;
  //       const dist = Math.sqrt(
  //         Math.pow(second.last.x - first.last.x, 2) +
  //           Math.pow(second.last.y - first.last.y, 2)
  //       );
  //       const change =
  //         ((dist - last) / this.screenWidth) * this.currentZoom * 1;
  //       this.currentZoom += change;

  //       if (this.currentZoom <= this.minZoom) {
  //         this.currentZoom = this.minZoom;
  //       }

  //       if (this.currentZoom >= this.maxZoom) {
  //         this.currentZoom = this.maxZoom;
  //       }
  //     } else {
  //       if (!this.isPinching) {
  //         console.log("pinch start");
  //         this.isPinching = true;
  //       }
  //     }
  //   }
  // };

  handleDrag = (event) => {
    if (this.lastMouseX) {
      this.mouseDistance.x +=
        (this.lastMouseX - event.clientX) / this.xMultiplicator;
      this.mouseDistance.y +=
        (this.lastMouseY - event.clientY) / this.yMultiplicator;
    }

    this.distX += this.lastMouseX - event.clientX;
    this.factorXrotation = (this.distX / window.innerWidth) * this.maxRotation;
    this.ratio = this.isMobile ? 2.5 : 1;
    this.xDragTo =
      this.scenePosX + this.mouseDistance.x * this.ratio * this.reverse;
    this.yDragTo = this.scenePosY + this.mouseDistance.y * this.reverse;
    if (this.xDragTo <= this.maxX) {
      this.xDragTo =
        this.xDragTo <= this.lockMaxDragX ? this.lockMaxDragX : this.xDragTo;
      this.bounceXmax = true;
      this.toX = {
        time: 0,
        start: this.xDragTo,
        delta: this.maxX - this.xDragTo,
        end: this.maxX,
      };
    } else {
      this.bounceXmax = false;
    }

    if (this.xDragTo >= this.minX) {
      this.xDragTo =
        this.xDragTo >= this.lockMinDragX ? this.lockMinDragX : this.xDragTo;
      this.bounceXmin = true;
      this.toX = {
        time: 0,
        start: this.xDragTo,
        delta: this.minX - this.xDragTo,
        end: this.minX,
      };
    } else {
      this.bounceXmin = false;
    }

    if (this.yDragTo <= this.maxY) {
      this.yDragTo =
        this.yDragTo <= this.lockMaxDragY ? this.lockMaxDragY : this.yDragTo;
      this.bounceYmax = true;
      this.toY = {
        time: 0,
        start: this.yDragTo,
        delta: this.maxY - this.yDragTo,
        end: this.maxY,
      };
    } else {
      this.bounceYmax = false;
    }

    if (this.yDragTo >= this.minY) {
      this.yDragTo =
        this.yDragTo >= this.lockMinDragY ? this.lockMinDragY : this.yDragTo;
      this.bounceYmin = true;
      this.toY = {
        time: 0,
        start: this.yDragTo,
        delta: this.minY - this.yDragTo,
        end: this.minY,
      };
    } else {
      this.bounceYmin = false;
    }

    this.saved.push({
      x: this.scenePosX,
      y: this.scenePosY,
      time: performance.now(),
    });
    if (this.saved.length > 60) {
      this.saved.splice(0, 30);
    }

    this.lastMouseX = event.clientX;
    this.lastMouseY = event.clientY;
  };

  onDecelerateDown = () => {
    this.saved = [];
    this.delecerateX = this.decelerateY = false;
  };

  resetMouseDistance = () => {
    this.mouseDistance = {
      x: 0,
      y: 0,
    };
  };

  /**
   * Trigger dragEvent
   */
  onDragging = () => {
    if (this.dragMoved) {
      // if (this.dragMoved && !this.isZooming && !this.particularZoom ) {
      this.scene.x += (this.xDragTo - this.scene.x) * this.easeRatio;
      this.scene.y += (this.yDragTo - this.scene.y) * this.easeRatio;

      if (!this.bounceXmax || !this.bounceXmin) {
        this.cntExploration.rotation +=
          (this.factorXrotation - this.cntExploration.rotation) * 0.055;
        this.lastRotation = this.cntExploration.rotation;
      }

      /**
       * detect drag really end
       */
      if (
        (!this.isDragging &&
          Math.abs((this.xDragTo - this.scene.x) * this.easeRatio) < 1.7 &&
          Math.abs((this.yDragTo - this.scene.y) * this.easeRatio) < 1.7) ||
        this.activePinch
      ) {
        console.log("drag end");
        this.dragMoved = false;
        this.scenePosX = this.xDragTo;
        this.scenePosY = this.yDragTo;
        this.xExplorationTo = this.cntExploration.x;
        this.yExplorationTo = this.cntExploration.y;
        this.xLast = null;
        this.yLast = null;
        if (
          !this.bounceXmax ||
          !this.bounceXmin ||
          !this.bounceYmax ||
          !this.bounceYmin
        ) {
          // TweenMax.to(this.cntExploration, 0.55, {
          //   // delay: 0.15,
          //   rotation: 0,
          //   ease: Power3.easeInOut
          // });
          this.currentRotation = 0;
        }
      }
    }

    // this.scene.x = Math.min(Math.max(this.scene.x, this.minX), this.maxX);
    // this.scene.y = Math.min(Math.max(this.scene.y, this.minY), this.maxY);
  };

  showBackground = () => {
    this.scene.background.visible = true;
    TweenMax.to(this.scene.background, 0.8, {
      alpha: 0.9,
      ease: Power3.easeInOut,
    });
  };

  onDragStart = () => {
    TweenMax.to(this.cntExploration.scale, 0.45, {
      x: 0.95,
      y: 0.95,
      ease: Sine.easeOut,
    });
    this.mouseMoving = false;
    document.documentElement.classList.add("is-interacting");
  };

  removePointer = () => {
    document.documentElement.classList.remove("pe-none");
  };

  onDragUp = (event) => {
    // console.log(this.bounceXmax, this.bounceXmin);
    this.isDragging = false;
    document.documentElement.classList.remove("is-interacting");
    TweenMax.delayedCall(3, this.removePointer);
    EmitterManager.emit("DRAG_OFF");
    this.lastMouseX = null;
    this.lastMouseY = null;
    this.distX = null;
    this.factorXrotation = 0;
    if (
      !this.bounceXmax ||
      !this.bounceXmin ||
      !this.bounceYmax ||
      !this.bounceYmin
    ) {
      TweenMax.to(this.cntExploration, 1.1, {
        rotation: 0,
        ease: Power3.easeInOut,
      });

      TweenMax.to(this.cntExploration.scale, 0.9, {
        x: 1,
        y: 1,
        ease: Power3.easeInOut,
      });
    }
    this.scenePosX = this.xDragTo;
    this.scenePosY = this.yDragTo;
    this.xExplorationTo = this.cntExploration.x;
    this.yExplorationTo = this.cntExploration.y;
    this.xLast = null;
    this.yLast = null;

    this.lastMouseDistance = {
      x: this.mouseDistance.x,
      y: this.mouseDistance.y,
    };
    this.mouseDistance = {
      x: 0,
      y: 0,
    };

    // for decelerate prevent decelerate when container's bouncing
    if (
      this.saved.length &&
      !this.bounceYmin &&
      !this.bounceYmax &&
      !this.bounceXmin &&
      !this.bounceXmax
    ) {
      const now = performance.now();
      for (let save of this.saved) {
        if (save.time >= now - 250) {
          const time = now - save.time;
          this.delecerateX = (this.scenePosX - save.x) / time;
          this.decelerateY = (this.scenePosY - save.y) / time;
          this.percentChangeX = this.percentChangeY = this.friction;
          break;
        }
      }
    }
  };

  onZoomEnd = (zoomValue) => {
    console.log("zoom end");
    this.isZooming = false;

    let finalZoom = zoomValue || this.scene.scale.x;

    this.sceneW = this.tileW * finalZoom * this.tileNbX;
    this.sceneH = this.tileH * finalZoom * this.tileNbY;
    this.worldWidth = this.sceneW;
    this.worldHeight = this.sceneH;
    this.currentZoom = finalZoom;

    if (this.lastZoom === null) {
      this.marginX = (this.marginX / this.initalZoom) * this.currentZoom;
      this.marginY = (this.marginY / this.initalZoom) * this.currentZoom;
    } else {
      this.marginX = (this.marginX / this.lastZoom) * this.currentZoom;
      this.marginY = (this.marginY / this.lastZoom) * this.currentZoom;
    }
    this.lastZoom = this.currentZoom;
    this.minX = 0 - this.marginX;
    this.minY = 0 - this.marginY;
    this.maxX = (this.worldWidth - this.screenWidth) * -1 + this.marginX;
    this.maxY = (this.worldHeight - this.screenHeight) * -1 + this.marginY;
    this.lockMinDragX = this.minX + this.xLock;
    this.lockMaxDragX = this.maxX - this.xLock;
    this.lockMinDragY = this.minY + this.yLock;
    this.lockMaxDragY = this.maxY - this.yLock;
    this.scenePosX = this.scene.x;
    this.scenePosY = this.scene.y;

    if (store.currentZoom.toFixed(2) !== this.currentZoom.toFixed(2)) {
      EmitterManager.emit("ZOOM:END", this.currentZoom);
    }
    store.currentZoom = this.currentZoom;
  };

  // onPinchEnd = () => {
  //   console.log("pinch end");
  //   this.isPinching = false;
  //   document.documentElement.classList.remove("is-interacting");
  // };

  onCenterSpriteEnd = () => {
    this.particularZoom = false;
  };

  onCenterBtnEnd = () => {
    this.particularZoom = false;
    this.btnZoom = false;
  };

  /**
   * Trigger MouseMove
   */
  onMouseMove = () => {
    if (
      this.mouseMoving &&
      !this.dragMoved &&
      !this.isDragging &&
      !this.bounceXmin &&
      !this.bounceXmax &&
      !this.bounceYmin &&
      !this.bounceYmax
    ) {
      this.cntExploration.x +=
        (this.xExplorationTo - this.cntExploration.x) * this.easeRatio;
      this.cntExploration.y +=
        (this.yExplorationTo - this.cntExploration.y) * this.easeRatio;
    }
  };

  /**
   * Trigger zoom
   */
  // onZooming = () => {
  //   if (this.isZooming && this.dragMoved === false) {
  //     const point = this.smoothingCenter;
  //     let oldPoint;
  //     oldPoint = this.scene.toLocal(point);

  //     this.scene.scale.x += (this.scaleTo - this.scene.scale.x) * 0.1;
  //     this.scene.scale.y += (this.scaleTo - this.scene.scale.y) * 0.1;

  //     const newPoint = this.scene.toGlobal(oldPoint);

  //     this.scene.x += point.x - newPoint.x;
  //     this.scene.y += point.y - newPoint.y;
  //     console.log(this.scene.x);
  //     this.sceneW = this.tileW * this.scene.scale.x * this.tileNbX;
  //     this.sceneH = this.tileH * this.scene.scale.x * this.tileNbY;
  //     this.worldWidth = this.sceneW;
  //     this.worldHeight = this.sceneH;

  //     this.minX = 0 - this.marginX;
  //     this.minY = 0 - this.marginY;
  //     this.maxX = (this.worldWidth - this.screenWidth) * -1 + this.marginX;
  //     this.maxY = (this.worldHeight - this.screenHeight) * -1 + this.marginY;

  //     this.lockMinDragX = this.minX + this.xLock;
  //     this.lockMaxDragX = this.maxX - this.xLock;
  //     this.lockMinDragY = this.minY + this.yLock;
  //     this.lockMaxDragY = this.maxY - this.yLock;

  //     this.scenePosX = this.scene.x;
  //     this.scenePosY = this.scene.y;

  //     if (
  //       Math.abs((point.x - newPoint.x) * 0.95) < 0.01 &&
  //       Math.abs((point.y - newPoint.y) * 0.95) < 0.01
  //     ) {
  //       this.onZoomEnd();
  //     }
  //   }
  // };
  /**
   * Trigger pinch
   */
  // onPinching = () => {
  //   if (this.isPinching && this.smoothingCenter) {
  //     const point = this.smoothingCenter;
  //     let oldPoint;
  //     oldPoint = this.scene.toLocal(point);

  //     this.scene.scale.x +=
  //       (this.currentZoom - this.scene.scale.x) * this.pinchEaseRatio;
  //     this.scene.scale.y +=
  //       (this.currentZoom - this.scene.scale.y) * this.pinchEaseRatio;

  //     document.documentElement.classList.add("is-interacting");

  //     const newPoint = this.scene.toGlobal(oldPoint);

  //     this.scene.x += point.x - newPoint.x;
  //     this.scene.y += point.y - newPoint.y;

  //     if (
  //       !this.activePinch &&
  //       Math.abs((point.x - newPoint.x) * 0.95) < 0.01 &&
  //       Math.abs((point.y - newPoint.y) * 0.95) < 0.01
  //     ) {
  //       this.onPinchEnd();
  //     }

  //     // set data
  //     this.sceneW = this.tileW * this.scaleTo * this.tileNbX;
  //     this.sceneH = this.tileH * this.scaleTo * this.tileNbY;
  //     this.worldWidth = this.sceneW;
  //     this.worldHeight = this.sceneH;

  //     this.maxX = (this.worldWidth - this.screenWidth) * -1 + this.marginX;
  //     this.maxY = (this.worldHeight - this.screenHeight) * -1 + this.marginY;
  //     this.scenePosX = this.scene.x;
  //     this.scenePosY = this.scene.y;
  //   }
  // };

  // onBounce = elapsedMs => {
  //   if (
  //     (this.bounceXmin || this.bounceXmax) &&
  //     this.toX &&
  //     !this.isDragging &&
  //     !this.dragMoved
  //   ) {
  //     const toX = this.toX;
  //     toX.time += elapsedMs;
  //     if (toX.time >= this.time) {
  //       this.scene.x = toX.end;
  //       this.toX = null;
  //       this.bounceXmin = false;
  //       this.bounceXmax = false;
  //       this.lastRotation = 0;
  //       this.startBouncing = false;
  //       console.log("BOUNCE X END");
  //     } else {
  //       this.scene.x = this.easeInOutQuart(
  //         toX.time,
  //         toX.start,
  //         toX.delta,
  //         this.time
  //       );
  //       // this.cntExploration.rotation = this.easeInOutQuart(toX.time, this.lastRotation, 0, this.time);
  //     }
  //   }

  //   if (
  //     (this.bounceYmin || this.bounceYmax) &&
  //     this.toY &&
  //     !this.isDragging &&
  //     !this.dragMoved
  //   ) {
  //     const toY = this.toY;
  //     toY.time += elapsedMs;
  //     if (toY.time >= this.time) {
  //       this.scene.y = toY.end;
  //       this.toY = null;
  //       this.bounceYmax = false;
  //       this.bounceYmin = false;
  //       this.startBouncing = false;
  //       console.log("BOUNCE Y END");
  //     } else {
  //       this.scene.y = this.easeInOutQuart(
  //         toY.time,
  //         toY.start,
  //         toY.delta,
  //         this.time
  //       );
  //     }
  //   }

  //   // To scale and rotate container on bounce event
  //   if (
  //     (this.bounceYmin ||
  //       this.bounceYmax ||
  //       this.bounceXmin ||
  //       this.bounceXmax) &&
  //     !this.isDragging &&
  //     !this.dragMoved
  //   ) {
  //     if (!this.startBouncing) {
  //       TweenMax.to(this.cntExploration.scale, 0.4, {
  //         x: 1,
  //         y: 1,
  //         ease: Power3.easeInOut
  //       });
  //       TweenMax.to(this.cntExploration, 0.4, {
  //         rotation: 0,
  //         ease: Power3.easeInOut
  //       });
  //       this.startBouncing = true;
  //     }
  //   }
  // };

  // onDecelerate = elapsedMs => {
  //   if (this.delecerateX) {
  //     if (this.scene.x >= this.minX) {
  //       this.bounceXmin = true;
  //       this.toX = {
  //         time: 0,
  //         start: this.scene.x,
  //         delta: this.minX - this.scene.x,
  //         end: this.minX
  //       };
  //       this.delecerateX = 0;
  //     }
  //     if (this.scene.x <= this.maxX) {
  //       this.delecerateX = 0;
  //     }
  //     // console.log("TEEEEEST",this.delecerateX * elapsedMs);
  //     this.scene.x += this.delecerateX * elapsedMs;
  //     this.delecerateX *= this.percentChangeX;
  //     // console.log('percent change',this.percentChangeX)
  //     if (Math.abs(this.delecerateX) < this.minSpeed) {
  //       this.delecerateX = 0;
  //     }
  //   }
  //   if (this.decelerateY) {
  //     if (this.scene.y >= this.minY) {
  //       this.bounceYmin = true;
  //       this.toY = {
  //         time: 0,
  //         start: this.scene.y,
  //         delta: this.minY - this.scene.y,
  //         end: this.minY
  //       };
  //       this.delecerateY = 0;
  //     }
  //     if (this.scene.y <= this.maxY) {
  //       this.bounceYmax = true;
  //       this.toY = {
  //         time: 0,
  //         start: this.scene.y,
  //         delta: this.maxY - this.scene.y,
  //         end: this.maxY
  //       };
  //       this.delecerateY = 0;
  //     }
  //     this.scene.y += this.decelerateY * elapsedMs;
  //     this.decelerateY *= this.percentChangeY;
  //     if (Math.abs(this.decelerateY) < this.minSpeed) {
  //       this.decelerateY = 0;
  //     }
  //   }
  // };

  onCenterSprite = () => {
    if (this.particularZoom && this.offsetX) {
      this.scene.x += (this.offsetX - this.scene.x) * 0.08;
      this.scene.y += (this.offsetY - this.scene.y) * 0.08;

      this.scene.scale.x += (this.scaleTo - this.scene.scale.x) * 0.08;
      this.scene.scale.y += (this.scaleTo - this.scene.scale.y) * 0.08;

      if (
        Math.abs((this.offsetX - this.scene.x) * 0.08) < 0.3 &&
        Math.abs((this.scaleTo - this.scene.scale.y) * 0.08) < 0.3
      ) {
        console.log("center sprite end");
        if (this.btnZoom) {
          this.onCenterBtnEnd();
        } else {
          this.onCenterSpriteEnd();
        }
      }
    }
  };

  onResize = () => {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    // this.maxX = (this.worldWidth - this.screenWidth) * -1 + this.marginX;
    // this.maxY = (this.worldHeight - this.screenHeight) * -1 + this.marginY;

    if (this.blurCustomFilter)
      this.blurCustomFilter.uniforms.iResolution = [
        window.innerWidth,
        window.innerHeight,
      ];
    if (this.lastCenter) {
      this.scene.x = this.lastCenter.x + window.innerWidth / 2;
      this.scene.y = this.lastCenter.y + window.innerHeight / 2;
    }
  };

  update = () => {
    // this.testFilter.uniforms.customUniform += 0.04;
  };
}
export default new Viewport();
