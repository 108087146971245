export default {
  color:
    'Coloriez la fresque illustrée par OMY et tentez de trouver nos codes promos dans le dessin.',
  btnStart: 'Commencez à colorier',
  avatar: 'Votre avatar OMY est ...',
  instructionTitle: 'Instructions',
  instruction:
    'Parcourez la fresque, choisissez une zone et appuyez dessus pour la colorier',
  usersColorize: 'utilisateurs sont en train de colorier cette fresque.',
  instructionColorize: `Choisissez la couleur de votre crayon et coloriez toute la zone sélectionnée.
Remplissez toute la zone pour valider votre dessin et découvrez si vous avez gagné quelque chose.`,
  sorry: `Désolé! Aucun code promo n'était caché ici.`,
  eshop:
    'Utilisez-le sur le site de L’OCCITANE pour bénéficier votre cadeau GRATUIT.*',
  discoverMore: 'En savoir plus',
  win: `Félicitations! Vous gagnez un code promo !Utilisez-le sur fr.loccitane.com`,
  tryAgain: 'Réessayez encore une fois!',
  close: 'Fermer',
  discover: 'Découvrez toutes les fresques réalisées. ',
  discoverUrl: '',
  howTo: 'COMMENT UTILISER MON CODE PROMO? ',
  push: "Découvrez la gamme Karité en édition limitée de L'OCCITANE X OMY.",
  rangeURL: "https://fr.loccitane.com/l'occitane-x-omy,74,1,97550,1455156.htm",
  colorAll:
    "Une fois que vous avez choisi une couleur, gardez la même pour colorer tout l'élément.",
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText:
    'Venez colorier la fresque online OMY pour  de L’Occitane, et tentez de gagner des codes promos sur les soins de la collection Karité. À vos crayons ! #ProvenceEverywhere',
  globalTWText: `LET’S COLOR TOGETHER
Venez colorier la fresque online OMY pour de L’Occitane, et tentez de gagner des codes promos sur les soins de la collection Karité. À vos crayons ! #ProvenceEverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText:
    'Vous aussi coloriez la fresque online OMY de L’Occitane et tentez de gagner des codes promos sur les soins de la collection Karité. À vos crayons ! #ProvenceEverywhere',
  localTWText: `LET’S COLOR TOGETHER
Vous aussi coloriez la fresque online OMY de L’Occitane et tentez de gagner des codes promos sur les soins de la collection Karité. À vos crayons ! #ProvenceEverywhere`,
  frescoPourcent:
    "Ce pourcentage est le degré d'achèvement de la fresque. Curieux de savoir à quoi ressemblera cette fresque terminée ? Consultez notre compte Instagram : @loccitane_fr",
  tc:
    'https://fr.loccitane.com/reglement-jeu--let-s-color-together-,74,1,97823,1471979.htm',
  tcTitle: 'Conditions Générales de Vente',
  frescoCompleted: 'La fresque est commencée depuis X heures',
  ie:
    "Ce navigateur n'est pas compatible avec notre animation, merci d'en utiliser un plus recent",
  continue:
    'Continuer à colorier pour trouver le code!Réessayez encore une fois!',
  eye: "https://fr.loccitane.com/l'occitane-x-omy,74,1,97550,1455156.htm",
  howToURL: 'https://fr.loccitane.com/offre-couleur,74,1,88563,1455152.htm'
}
