export default {
  color:
    'Obojite ilustraciju i istražite animiranu mapu jer možda otkrijete i osvojite promotivni kôd za ekskluzivnu pogodnost!',
  btnStart: 'ZAPOČNITE BOJENJE',
  avatar: 'Vaš OMY avatar je:',
  instructionTitle: 'UPUTE',
  instruction:
    'Istražite crtež, odaberite i kliknite na područje koje želite obojiti.',
  usersColorize: 'korisnika trenutno sudjeluje u bojenju crteža',
  instructionColorize: `Odaberite boju svoje bojice i obojite njome odabrano područje.
ISPUNITE BOJOM CIJELO PODRUČJE I OTKRIJTE JESTE LI OSVOJILI EKSKLUZIVNU POGODNOST.`,
  sorry: `Žao nam je!
  Na ovom području ne nalazi se skriveni promotivni kôd.`,
  eshop: "Kôd možete iskoristiti online ili u L'OCCITANE trgovini.",
  discoverMore: 'Saznajte više',
  win: `Čestitamo!
Pronašli ste promotivni kôd za popust od 70 kn uz kupnju iznad 320 kn!`,
  tryAgain: 'Probajte ponovno!',
  close: 'Zatvorite',
  discover: 'Pogledajte sve obojene ilustracije.',
  discoverUrl: '',
  howTo: 'KAKO ISKORISTITI PROMOTIVNI KÔD?',
  push: "Otkrijte L'OCCITANEOVU limitiranu kolekciju Karite x OMY!",
  rangeURL: 'https://hr.loccitane.com/loccitane-x-omy,51,2,97814,1471235.htm',
  colorAll:
    'Jednom kada ste odabrali boju, njome obojite cijelu odabranu ilustraciju.',
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText: `Obojite L'OCCITANE X OMY illustraciju i osvojite promotivni kôd za ekskluzivnu pogodnost. Priprema, pozor, bojite!
#provenceeverywhere`,
  globalTWText: `LET’S COLOR TOGETHER! Obojite L'OCCITANE X OMY illustraciju i osvojite promotivni kôd za ekskluzivnu pogodnost. Priprema, pozor, bojite!
#provenceeverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText: `Sada je na vama da obojite L'OCCITANE X OMY illustraciju i možda osvojite promotivni kôd za ekskluzivnu pogodnost. Priprema, pozor, bojite!
#provenceeverywhere`,
  localTWText: `LET’S COLOR TOGETHER! Sada je na vama da obojite L'OCCITANE X OMY illustraciju i možda osvojite promotivni kôd za ekskluzivnu pogodnost. Priprema, pozor, bojite!
#provenceeverywhere`,
  frescoPourcent: `Ovo je postotak završenosti ilustracije.
Zanima vas kako izgleda gotova ilustracija? Provjerite na našem Instagram profilu: @loccitane_hr`,
  tc: 'https://hr.loccitane.com/omy-pravila,51,2,97797,1470592.htm',
  tcTitle: 'Uvjeti i pravila',
  frescoCompleted: 'Natječaj je započeo prije $ dana.',
  ie:
    'Ovaj preglednik nije kompatibilan s našom aplikacijom. Molimo koristite noviji preglednik.',
  continue: 'Nastavite bojiti kako biste pronašli promotivni kôd.',
  eye: '',
  howToURL: 'https://hr.loccitane.com/omy-pogodnost,51,2,97815,1471259.htm'
}
