import { css, keyframes } from "@emotion/core";

/**
 * COLORS
 */

export const YELLOW = "#edbf2b";
export const DARKPURPLE = "#685ec4";
export const PURPLE = "#b3afe2";
export const GREENBLUE = "#23b6cd";
export const GREEN = "#18a3ac";
export const BLUE = "#98d1d5";
export const LIGHTYELLOW = "#fee29b";
export const ORANGE = "#e16b60";
export const LIGHTORANGE = "#eea89f";

/**
 * FONT SIZES
 */

export const FS = 10;

/**
 * FONT
 */
export const GOTHAM = "Gotham";

/**
 * VARS
 */

export const wiggle = keyframes`
    0% {
      filter: url("#squiggly-0");
    }
    25% {
      filter: url("#squiggly-1");
    }
    50% {
      filter: url("#squiggly-2");
    }
    75% {
      filter: url("#squiggly-3");
    }
    100% {
      filter: url("#squiggly-4");
    }
`;

/**
 * RHYTHM
 */

export const LINE_HEIGHT = 1.5;
export const rhythm = val => `${val * LINE_HEIGHT}rem`;

/**
 * EASINGS
 */

export const EASE_NONE = "cubic-bezier(0, 0, 1, 1)";

export const POWER1_EASE_IN = "cubic-bezier(0.26, 0, 0.6, 0.2)";
export const POWER2_EASE_IN = "cubic-bezier(0.4, 0, 0.68, 0.06)";
export const POWER3_EASE_IN = "cubic-bezier(0.52, 0, 0.74, 0)";
export const POWER4_EASE_IN = "cubic-bezier(0.64, 0, 0.78, 0)";
export const EXPO_EASE_IN = "cubic-bezier(0.66, 0, 0.86, 0)";
export const SINE_EASE_IN = "cubic-bezier(0.32, 0, 0.6, 0.36)";
export const CIRC_EASE_IN = "cubic-bezier(0.54, 0, 1, 0.44)";

export const POWER1_EASE_OUT = "cubic-bezier(0.4, 0.8, 0.74, 1)";
export const POWER2_EASE_OUT = "cubic-bezier(0.32, 0.94, 0.6, 1)";
export const POWER3_EASE_OUT = "cubic-bezier(0.26, 1, 0.48, 1)";
export const POWER4_EASE_OUT = "cubic-bezier(0.22, 1, 0.36, 1)";
export const EXPO_EASE_OUT = "cubic-bezier(0.14, 1, 0.34, 1)";
export const SINE_EASE_OUT = "cubic-bezier(0.4, 0.64, 0.68, 1)";
export const CIRC_EASE_OUT = "cubic-bezier(0, 0.56, 0.46, 1)";

export const POWER1_EASE_IN_OUT = "cubic-bezier(0.48, 0.04, 0.52, 0.96)";
export const POWER2_EASE_IN_OUT = "cubic-bezier(0.66, 0, 0.34, 1)";
export const POWER3_EASE_IN_OUT = "cubic-bezier(0.76, 0, 0.24, 1)";
export const POWER4_EASE_IN_OUT = "cubic-bezier(0.84, 0, 0.16, 1)";
export const EXPO_EASE_IN_OUT = "cubic-bezier(0.9, 0, 0.1, 1)";
export const SINE_EASE_IN_OUT = "cubic-bezier(0.36, 0, 0.64, 1)";
export const CIRC_EASE_IN_OUT = "cubic-bezier(0.88, 0.14, 0.12, 0.86)";

/**
 * RESPONSIVE
 */

const breakpoints = {
  // small: 576
  medium: 768,
  // large: 992,
  //   xLarge: 1200,
  xxLarge: 1920,
  mHeight: "max-height: 600px"
  // tallPhone: '(max-width: 360px) and (min-height: 740px)',
};

export const mq = Object.keys(breakpoints).reduce((accumulator, label) => {
  let prefix = typeof breakpoints[label] === "string" ? "" : "min-width:";
  let suffix = typeof breakpoints[label] === "string" ? "" : "px";

  accumulator[label] = cls =>
    css`
      @media (${prefix + breakpoints[label] + suffix}) {
        ${cls};
      }
    `;
  return accumulator;
}, {});
