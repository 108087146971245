import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Global, css } from "@emotion/core";
import bodymovin from "lottie-web";

import normalizeStyle from "./css/normalize.css";
import layoutStyle from "./css/layout.css";
import "flag-icon-css/css/flag-icon.min.css";

window.requestIdleCallback =
  window.requestIdleCallback ||
  function(cb) {
    return setTimeout(function() {
      var start = Date.now();
      cb({
        didTimeout: false,
        timeRemaining: function() {
          return Math.max(0, 50 - (Date.now() - start));
        }
      });
    }, 1);
  };

window.cancelIdleCallback =
  window.cancelIdleCallback ||
  function(id) {
    clearTimeout(id);
  };

bodymovin.setQuality("low");

ReactDOM.render(
  <>
    <Global
      styles={css`
        ${normalizeStyle};
        ${layoutStyle};
      `}
    />
    <App />
  </>,
  document.getElementById("root")
);
