export default {
  color:
    'Pinte o desenho ilustrado por OMY e tente encontrar os códigos de promoção.',
  btnStart: 'Começar a pintar',
  avatar: 'O seu avatar OMY é...',
  instructionTitle: 'Instruções',
  instruction: 'Navegue pelo desenho, escolha uma área e clique para a pintar',
  usersColorize: 'utilizadores estão a pintar o desenho neste momento.',
  instructionColorize: `Escolha a cor do seu pincel e pinte toda a área selecionada.
Preencha toda a área para validar a pintura e descobrir se encontrou algum código de promoção.`,
  sorry: `Não há códigos de promoção escondidos nesta área.`,
  eshop: 'Use-o em pt.loccitane.com',
  discoverMore: `Descobrir mais`,
  win: `Parabéns!
Encontrou um código de promoção!
Use-o na loja online.`,
  tryAgain: 'Tente novamente!',
  close: 'Fechar',
  discover: 'Descubra todos os desenhos pintados.',
  discoverUrl: '',
  howTo: 'Como usar este código de promoção?',
  push: 'Descubra a coleção de edição limitada Karité by OMY.',
  rangeURL: 'https://pt.loccitane.com/l-occitane-x-omy,31,2,97675,1459371.htm',
  colorAll: 'Quando escolher uma cor, use-a para pintar todo o elemento.',
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText:
    'Pinte o desenho online OMY pour L’Occitane e tente ganhar códigos de promoção. Dê asas à sua imaginação! #ProvenceEverywhere',
  globalTWText: `LET’S COLOR TOGETHER
  Pinte o desenho online OMY pour L’Occitane e tente ganhar códigos de promoção. Dê asas à sua imaginação! #ProvenceEverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText:
    'Pinte o desenho online OMY pour L’Occitane e tente ganhar códigos de promoção. Dê asas à sua imaginação! #ProvenceEverywhere',
  localTWText: `LET’S COLOR TOGETHER
  Pinte o desenho online OMY pour L’Occitane e tente ganhar códigos de promoção. Dê asas à sua imaginação! #ProvenceEverywhere`,
  frescoPourcent: `Esta é a percentagem do desenho pintado.
  Tem curiosidade para descobrir como ficará o desenho no final? Visite-nos no Instagram : @loccitane_fr`,
  tc:
    'https://pt.loccitane.com/regulamento-passatempo-let-s-color-together,31,2,97675,1472029.htm',
  tcTitle: 'Termos e condições',
  frescoCompleted: 'O desenho foi começado há $ dias',
  ie:
    'Este navegador não é compatível com a nossa animação, por favor utilize a versão mais recente.',
  continue: 'Continue a pintar para encontrar o seu código!',
  eye: '',
  howToURL: 'https://pt.loccitane.com/oferta-cor,31,2,97674,1459362.htm'
}
