export default {
  color:
    'Coloriez votre hiver avec la fresque illustrée par OMY! Voyagez dans la carte animée pour tenter votre chance de découvrir une offre exclusive dans le dessin.',
  btnStart: 'Commencer le coloriage',
  avatar: 'Votre avatar OMY est :',
  instructionTitle: 'Instructions',
  instruction: 'Choisissez une zone vierge et touchez pour la colorier',
  usersColorize: 'utilisateurs colorient présentement la fresque.',
  instructionColorize: `Choisissez une couleur et remplissez la zone que vous avez sélectionnée.
Coloriez la zone entière pour découvrir ce que vous avez gagné.`,
  sorry: `Désolé,
aucun code promotionnel n’était caché dans cette zone.`,
  eshop:
    'Magasinez sur le site de L’OCCITANE pour réclamer votre cadeau GRATUIT.*',
  discoverMore: 'Découvrir davantage',
  win: `Félicitations :
vous avez gagné une offre exclusive!`,
  tryAgain: 'Continuer à colorier pour trouver le code!',
  close: 'Fermer',
  discover: 'Découvrez toutes les fresques complétées.',
  discoverUrl: '',
  howTo: 'COMMENT UTILISER MON CODE PROMO?',
  push: 'Découvrez notre collection Karité x OMY en édition limitée!',
  rangeURL:
    'https://www.loccitane.com/fr-ca/collections/beurre-de-karite?prefn1=OCC_productType&prefv1=Edition%20Limit%C3%A9e',
  colorAll:
    "Une fois que vous avez choisi une couleur, gardez la même pour colorer tout l'élément.",
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText: `Venez colorier la fresque online OMY de L’Occitane, et tentez de gagner des codes promos sur les soins de la collection Karité. À vos crayons ! #ProvenceEverywhere`,
  globalTWText: `LET’S COLOR TOGETHER
Venez colorier la fresque online OMY de L’Occitane, et tentez de gagner des codes promos sur les soins de la collection Karité. À vos crayons ! #ProvenceEverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText:
    'Vous aussi coloriez la fresque online OMY de L’Occitane et tentez de gagner des codes promos sur les soins de la collection Karité. À vos crayons ! #ProvenceEverywhere',
  localTWText: `LET’S COLOR TOGETHER
Vous aussi coloriez la fresque online OMY de L’Occitane et tentez de gagner des codes promos sur les soins de la collection Karité. À vos crayons ! #ProvenceEverywhere`,
  frescoPourcent:
    "Ce pourcentage est le degré d'achèvement de la fresque. Curieux de savoir à quoi ressemble cette fresque finie ? Consultez notre compte Instagram :@loccitane",
  tc: 'https://www.loccitane.com/fr-ca/OMY-termes-conditions',
  tcTitle: 'Réglement',
  frescoCompleted: 'La fresque a commencé il y a $ heures.',
  ie:
    "Ce navigateur n'est pas compatible avec notre animation, merci d'en utiliser un plus recent.",
  continue: 'Continuer à colorier pour trouver le code!',
  eye:
    'https://www.loccitane.com/fr-ca/collections/beurre-de-karite?prefn1=OCC_productType&prefv1=Edition%20Limit%C3%A9e',
  howToURL: 'https://www.loccitane.com/fr-ca/shea'
}
