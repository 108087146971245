export default {
  color:
    'Colour the illustrated fresco by OMY and get a chance to discover promo codes in the drawing.',
  btnStart: 'Start colouring',
  avatar: 'Your OMY avatar is...',
  instructionTitle: 'Instructions',
  instruction: 'Browse the sketch, choose an area and tap to colour it.',
  usersColorize: 'users are now colouring this illustration.',
  instructionColorize: `Choose the colour of your pencil and colour the whole selected area.
Fill the entire area to validate the colouring and discover if you have won something.`,
  sorry: `Oh no!
No promo code was hidden in this area.`,
  eshop: 'Use it on the eshop.',
  discoverMore: 'Discover more',
  win: `Congratulations!
Redeem your promo code on our website now. `,
  tryAgain: 'How about you try again!',
  close: 'Close',
  discover: 'Discover all the completed illustrations.',
  discoverUrl: '',
  howTo: 'HOW TO USE THIS PROMO CODE ?',
  push: 'Discover our illustrated limited edition Shea range with OMY.',
  rangeURL: "https://nz.loccitane.com/l'occitane-x-omy,26,1,97791,1472170.htm",
  colorAll:
    'Once you choose a colour, keep the same to colour the entire element.',
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText: `Come color the online L'Occitane fresco by OMY, and get a chance to win promo code on the shea collection. Get your pencils ready! #provenceeverywhere`,
  globalTWText: `LET'S COLOR TOGETHER
  Come color the online L'Occitane fresco by OMY, and get a chance to win promo code on the shea collection. Get your pencils ready! #provenceeverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText: `It's your turn to color the L'Occitane online fresco by OMY and get a chance to win promo code on the shea collection. Get your pencils ready! #ProvenceEverywhere`,
  localTWText: `LET'S COLOR TOGETHER
It's your turn to color the L'Occitane online fresco by OMY and get a chance to win promo code on the shea collection. Get your pencils ready! #ProvenceEverywhere`,
  frescoPourcent:
    "This shows the percentage of this illustration's completion. Curious to know what this finished illustrated Fresco looks like? Check out our Instagram account",
  tc:
    'https://nz.loccitane.com/omy-game-terms-conditions,26,1,97791,1469579.htm',
  tcTitle: 'Terms and Conditions ',
  frescoCompleted: 'Fresco started X hours ago',
  ie:
    'This browser is not compatible with our animation, please use a newer one.',
  continue: 'Please continue to color to find the code!',
  eye: '',
  howToURL: "https://nz.loccitane.com/l'occitane-x-omy,26,1,97791,1472170.htm"
}
