import React, { Component } from "react";
import { Sprite, Container, Point, AnimatedSprite, SCALE_MODES } from "pixi.js";
import "pepjs"; // pointer event polyliff
import PixiApp from "./helpers/PixiApp";
import Viewport from "./helpers/Viewport";
// import { Viewport as ViewportCull } from "pixi-viewport";
import MainContainer from "./helpers/MainContainer";
import FirebaseManager from "./helpers/FirebaseManager";
import Pre from "./components/Pre";
import Draw from "./components/Draw";
import { TweenMax } from "gsap";
import AiDraw from "./helpers/AiDraw";
import Stats from "stats.js";
import { randomInt, getUrlParam } from "./utils";
import store from "./datas/store";
import UIContainer from "./helpers/UIContainer";
import ColorContainer from "./helpers/ColorContainer";
import EmitterManager from "./helpers/EmitterManager";
import PopinWin from "./components/PopinWin";
import PopinLoose from "./components/PopinLoose";
import { onEnter as PopinLooseEnter } from "./components/PopinLoose";
import { onExit as PopinLooseExit } from "./components/PopinLoose";
import { onEnter as PopinWinEnter } from "./components/PopinWin";
import { onExit as PopinWinExit } from "./components/PopinWin";
import fresqueNames from "./datas/fresque-names";
import VignetteFilter from "./shaders/VignetteFilter";
import Unpacker from "mm-unpacker";
// import Cull from "pixi-cull";

import frFr from "./datas/lang/fr_fr";
import auEn from "./datas/lang/au_en";
import beFr from "./datas/lang/be_fr";
import beNl from "./datas/lang/be_nl";
import brPt from "./datas/lang/br_pt";
import caEn from "./datas/lang/ca_en";
import caFr from "./datas/lang/ca_fr";
import chDe from "./datas/lang/ch_de";
import chFr from "./datas/lang/ch_fr";
import coEs from "./datas/lang/co_es";
import deDe from "./datas/lang/de_de";
import esEs from "./datas/lang/es_es";
import hkZh from "./datas/lang/hk_zh";
import hrHr from "./datas/lang/hr_hr";
import itIt from "./datas/lang/it_it";
import jpJa from "./datas/lang/jp_ja";
import krKr from "./datas/lang/kr_kr";
import meAr from "./datas/lang/me_ar";
import myEn from "./datas/lang/my_en";
import meEn from "./datas/lang/me_en";
import nlNl from "./datas/lang/nl_nl";
import nzEn from "./datas/lang/nz_en";
import ptPt from "./datas/lang/pt_pt";
import ruRu from "./datas/lang/ru_ru";
import saEn from "./datas/lang/sa_en";
import sgEn from "./datas/lang/sg_en";
import slSl from "./datas/lang/si_si";
import twZh from "./datas/lang/tw_zh";
import ukEn from "./datas/lang/uk_en";
import usEn from "./datas/lang/us_en";
import aeAr from "./datas/lang/ae_ar";
import aeEn from "./datas/lang/ae_en";

import pack from "./datas/pack";
import back from "./datas/back";

import { Transition } from "react-transition-group";
import detectIt from "detect-it";

class App extends Component {
  refMain = React.createRef();
  stats = process.env.NODE_ENV === "development" ? new Stats() : null;
  now = performance.now();

  state = {
    isLoaded: false,
    showPopinWin: false,
    showPopinLoose: false,
    codePromo: "",
    currentStep: 0,
    connectedUsers: [],
    lang: {},
    locale: "",
  };

  drawings = [];
  ctaProductsContainer = [];

  listenChange = () => {
    FirebaseManager.currentFresco.onSnapshot(
      (snapshot) => {
        if (snapshot.size) {
          snapshot.docChanges().forEach((change) => {
            if (change.doc.id === "init") return;

            // console.log(change);

            const datas = change.doc.data();

            // console.log(FirebaseManager.data, change, datas)

            if (change.type === "removed") {
              const target = MainContainer.getChildByName(change.doc.id);
              if (target) {
                target.alpha = 0;
                // console.log('removed')
                return MainContainer.drawSlot({
                  slotName: change.doc.id,
                  color: "#ffffff",
                });
              }
              return;
            }

            //TODO: ajust locked
            // REMOVE LOCKED CASE IF TIME > 120 secs
            if (datas.isLocked && (Date.now() - datas.timestamp) / 1000 > 120) {
              // console.log("delete lock");
              // console.log(change, change.doc);
              // change.doc.ref.delete()
              // ELSE DRAW LOCKED SLOT
            } else if (
              datas.isLocked &&
              datas.user &&
              FirebaseManager.userId !== datas.user
            ) {
              // console.log("draw lock");

              // if (MainContainer.spriteAccess[change.doc.id]) {
              //   MainContainer.spriteAccess[change.doc.id].alpha = 1

              //   MainContainer.drawSlot({
              //     slotName: change.doc.id,
              //     color: '#555555'
              //   })
              // }

              if (datas.user && FirebaseManager.userId !== datas.user) {
                this.setState((state) => {
                  return {
                    connectedUsers: state.connectedUsers.concat([
                      {
                        userName: datas.userName,
                        userCountry: datas.userCountry,
                        userId: datas.user,
                      },
                    ]),
                  };
                });
              }
            }

            // DRAW COLORED SLOT
            if (
              datas.user &&
              FirebaseManager.userId !== datas.user &&
              !datas.isLocked
            ) {
              // if drawing was less than 10s, draw It
              if (
                (Date.now() - datas.timestamp) / 1000 < 10000 &&
                this.state.currentStep > 0
              ) {
                // console.log("draw auto");
                new AiDraw({
                  target: MainContainer.spriteAccess[change.doc.id],
                  color: datas.color,
                  name: datas.userName,
                });
                this.setState((state) => {
                  return {
                    connectedUsers: state.connectedUsers.concat([
                      {
                        userName: datas.userName,
                        userCountry: datas.userCountry,
                        userId: datas.user,
                      },
                    ]),
                  };
                }); // FirebaseManager.toAutoDraw.push(
                //   Object.assign({}, datas, { id: change.doc.id })
                // );
              } else {
                // console.log("draw normal");
                if (MainContainer.spriteAccess[change.doc.id]) {
                  MainContainer.spriteAccess[change.doc.id].alpha = 1;

                  TweenMax.delayedCall(
                    this.state.currentStep === 0 ? Math.random() : 0,
                    () => {
                      MainContainer.drawSlot({
                        slotName: change.doc.id,
                        color: datas.color,
                      });
                    }
                  );
                }
              }
            } else if (!datas.isLocked) {
              // console.log("draw current user normal");
              MainContainer.drawSlot({
                slotName: change.doc.id,
                color: datas.color,
                current: true,
              });
            }
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  createNewFresco = (resolve, size) => {
    FirebaseManager.data = {
      name: fresqueNames[size],
      timestamp: Date.now(),
    };
    // alert("cool");
    FirebaseManager.db.add(FirebaseManager.data).then((docRef) => {
      FirebaseManager.currentFresco = docRef.collection("participations");
      // alert("ici");
      FirebaseManager.currentFresco
        .doc("init")
        .set({ init: true })
        .then(() => {
          resolve();
        });
    });
  };

  setupRtDb = () => {
    const isViewer = getUrlParam("viewer", false);

    return new Promise((resolve) => {
      window.firebase
        .auth()
        .signInAnonymously()
        .then((res) => {
          console.log(res.user.uid);
          FirebaseManager.userId = res.user.uid;
          // // Get last fresq
          const call = isViewer
            ? FirebaseManager.db.where(
                "timestamp",
                "==",
                parseInt(getUrlParam("id", 0), 10)
              )
            : FirebaseManager.db.orderBy("timestamp", "desc").limit(1);

          call.get().then((snapshot) => {
            console.log("snaaapshot", snapshot);
            if (snapshot.size === 0) {
              // window.location.reload();
              // alert("no fresque");
              this.createNewFresco(resolve, snapshot.size);
            } else {
              FirebaseManager.data = snapshot.docs[0].data();
              snapshot.docs[0].ref
                .collection("participations")
                .get()
                .then((snapshopParticipations) => {
                  console.log(snapshopParticipations);
                  //         // console.log(isViewer);
                  console.log("SIZE", snapshopParticipations.size);
                  //         // TODO: change limit = nbr of total slot per fresco
                  //         // if fresco is full, create new one
                  if (
                    snapshopParticipations.size &&
                    snapshopParticipations.size > 700 &&
                    !isViewer
                  ) {
                    this.createNewFresco(
                      resolve,
                      Math.floor(Math.random() * (fresqueNames.length - 2))
                    );
                  } else {
                    FirebaseManager.data.percent = Math.min(
                      Math.round((snapshopParticipations.size / 700) * 100),
                      100
                    );
                    FirebaseManager.currentFresco =
                      snapshot.docs[0].ref.collection("participations");
                    console.log(
                      "DDB OK",
                      (performance.now() - this.now) / 1000
                    );
                    resolve();
                  }
                });
            }
          });
        });
    });
  };

  loadAssets = () => {
    return new Promise((resolve) => {
      PixiApp.loader.concurrency = 100;
      // PixiApp.loader.onProgress.add(e => console.log(e.progress))
      PixiApp.loader.load(() => {
        for (let i = 0; i < this.containerProducts.children.length; i++) {
          const singleTileWidth = MainContainer.width / 8;
          const singleTileHeight = MainContainer.height / 6;
          this.containerProducts.children[i].x = randomInt(
            singleTileWidth,
            MainContainer.width - singleTileWidth * 2
          );
          this.containerProducts.children[i].y = randomInt(
            singleTileHeight,
            MainContainer.height - singleTileHeight * 2
          );
        }
        console.log("ASSET LOADED", (performance.now() - this.now) / 1000);

        resolve();
      });
    });
  };

  setCurrentStep = (val) => {
    this.setState({ currentStep: val });
  };

  addProductToFresque = (resource) => {
    const singleProductContainer = new Container();
    singleProductContainer.interactive = true;
    const sprite = new Sprite(resource.texture);
    sprite.interactive = true;
    // console.log(resource);
    // sprite.name = resource.name;
    sprite.on("click", (event) => {
      if (sprite.getColorByPoint(event.data.global)[0]) {
        MainContainer.productClicked = true;
        setTimeout(() => (MainContainer.productClicked = false), 100);
      } else {
        // console.log("not good");
      }
    });
    singleProductContainer.addChild(sprite);
    this.ctaProductsContainer.push(singleProductContainer);

    // const graph = new Graphics();
    // graph.lineStyle(4, 0x000000);
    // graph.beginFill(0x000000, 0.01);
    // graph.drawCircle(0, 0, 60);

    // graph.interactive = true;
    // graph.on("mouseover", () => {
    //   graph.scale.x = graph.scale.y = 2;
    // });

    // graph.on("mouseout", () => {
    //   graph.scale.x = graph.scale.y = 1;
    // });

    // graph.on("click", () => {
    //   MainContainer.productClicked = true;
    //   setTimeout(100, () => (MainContainer.productClicked = false));
    //   window.open("http://www.google.fr");
    // });

    // singleProductContainer.addChild(graph);

    this.containerProducts.addChild(singleProductContainer);
  };

  addCloseSprite = (resource) => {
    const closeSprite = new Sprite(resource.texture);
    closeSprite.buttonMode = true;
    closeSprite.name = "closeSprite";
    closeSprite.interactive = true;
    closeSprite.visible = false;
    closeSprite.alpha = 0;
    UIContainer.addChild(closeSprite);
    closeSprite.x = window.innerWidth / 2 - closeSprite.width / 2;
    closeSprite.y = detectIt.primaryInput === "touch" ? 25 : 55;
    UIContainer.addChild(ColorContainer);
  };

  showPopin = () => {
    EmitterManager.on("POPIN:WIN", (code) => {
      Viewport.disableInteractive();
      Viewport.interactive = false;

      this.setState({ codePromo: code });
      setTimeout(() => {
        this.setState({ showPopinWin: true });
      }, 1700);
    });

    EmitterManager.on("POPIN:LOOSE", () => {
      Viewport.disableInteractive();
      Viewport.interactive = false;
      // setTimeout(() => {
      this.setState({ showPopinLoose: true });
      // }, 1700);
    });
  };

  hidePopin = () => {
    // console.log("close popin");
    EmitterManager.emit("DRAWING_OFF");
    MainContainer.hideBackground();
    let pencil = PixiApp.stage.getChildByName("pencil");
    pencil.show();

    Viewport.enableInteractive();
    if (this.state.showPopinWin) {
      this.setState({ showPopinWin: false });
    }
    if (this.state.showPopinLoose) {
      this.setState({ showPopinLoose: false });
    }
  };

  normalizeMixedDataValue = (value) => {
    var padding = "000000000000000";

    value = value.replace(
      /(\d+)((\.\d+)+)?/g,
      function ($0, integer, decimal, $3) {
        if (decimal !== $3) {
          return padding.slice(integer.length) + integer + decimal;
        }

        decimal = decimal || ".0";

        return (
          padding.slice(integer.length) +
          integer +
          decimal +
          padding.slice(decimal.length)
        );
      }
    );

    return value;
  };

  componentDidMount() {
    // console.log("App useEffect");

    // console.log(document.referrer);

    const packSort = pack.sort((a, b) => {
      const aN = this.normalizeMixedDataValue(a[0]);
      const bN = this.normalizeMixedDataValue(b[0]);

      return aN < bN ? -1 : 1;
    });

    const backSort = back.sort((a, b) => {
      const aN = this.normalizeMixedDataValue(a[0]);
      const bN = this.normalizeMixedDataValue(b[0]);

      return aN < bN ? -1 : 1;
    });

    // console.log(packSort);

    let lang;
    this.setState({ locale: getUrlParam("lang", "us_en") });
    switch (getUrlParam("lang", "us_en")) {
      case "fr_fr":
        lang = frFr;
        break;

      case "ca_fr":
        lang = caFr;
        break;

      case "ca_en":
        lang = caEn;
        break;

      case "br_pt":
        lang = brPt;
        break;

      case "au_en":
        lang = auEn;
        break;

      case "kr_kr":
        lang = krKr;
        break;

      case "my_en":
        lang = myEn;
        break;

      case "tw_zh":
        lang = twZh;
        break;

      case "sg_en":
        lang = sgEn;
        break;

      case "jp_ja":
        lang = jpJa;
        break;

      case "de_de":
        lang = deDe;
        break;

      case "es_es":
        lang = esEs;
        break;

      case "uk_en":
        lang = ukEn;
        break;

      case "ch_de":
        lang = chDe;
        break;

      case "ch_fr":
        lang = chFr;
        break;

      case "be_fr":
        lang = beFr;
        break;

      case "be_nl":
        lang = beNl;
        break;

      case "it_it":
        lang = itIt;
        break;

      case "nl_nl":
        lang = nlNl;
        break;

      case "pt_pt":
        lang = ptPt;
        break;

      case "ru_ru":
        lang = ruRu;
        break;

      case "nz_en":
        lang = nzEn;
        break;

      case "hk_zh":
        lang = hkZh;
        break;

      case "sa_en":
        lang = saEn;
        break;

      case "co_es":
        lang = coEs;
        break;

      case "hr_hr":
        lang = hrHr;
        break;

      case "si_si":
        lang = slSl;
        break;

      case "me_ar":
        lang = meAr;
        document.body.style.direction = "rtl";
        break;

      case "me_en":
        lang = meEn;
        break;

      case "ae_ar":
        lang = aeAr;
        document.body.style.direction = "rtl";
        break;

      case "ae_en":
        lang = aeEn;
        break;

      case "us_en":
      default:
        lang = usEn;
    }

    this.setState({
      lang,
    });

    const metatitle = document.getElementsByName("title")[0];
    metatitle.setAttribute(
      "content",
      lang.globalFBTitle || "LET’S COLOR TOGETHER"
    );
    console.log(metatitle, lang);

    document.title = lang.globalFBTitle || "LET’S COLOR TOGETHER";

    // let randomColor = randomProperty(colors);

    // store.color.name = randomColor.name;
    // store.color.colorSelected = randomColor.color;
    window.addEventListener("resize", this.handleResize);

    const promiseMount = () => {
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    };

    const promiseMountBack = () => {
      return new Promise((resolve) => {
        this.resolveBack = resolve;
      });
    };

    if (this.stats) this.stats.showPanel(0);

    Object.assign(PixiApp.view.style, {
      display: "block",
      left: 0,
      position: "absolute",
      top: 0,
      userSelect: "none",
      zIndex: -1,
    });
    if (this.stats) this.refMain.current.appendChild(this.stats.dom);
    this.refMain.current.appendChild(PixiApp.view);

    this.vignette = new VignetteFilter({ opacity: 0.2 });

    PixiApp.stage.filters = [this.vignette];

    // this.viewport = new ViewportCull({
    //   screenWidth: PixiApp.view.offsetWidth,
    //   screenHeight: PixiApp.view.offsetHeight,
    //   worldWidth: 615 * 2 * 12,
    //   worldHeight: 548 * 2 * 10
    // });

    // PixiApp.stage.addChild(this.viewport);

    PixiApp.stage.addChild(MainContainer);
    // PixiApp.stage.alpha = 0.3;

    this.containerProducts = new Container();
    store.containerProducts = this.containerProducts;
    this.containerProducts.name = "products";
    MainContainer.addChild(this.containerProducts);
    PixiApp.stage.addChild(UIContainer);
    MainContainer.spriteAccess = [];

    for (let i = 0; i < 12; i++) {
      PixiApp.loader.add(
        `atlas${i}`,
        `${process.env.PUBLIC_URL}/images/atlas/atlas${i + 1}.json`,
        (resource) => {
          // console.log(resource);
          //const containerAtlas = new Container();
          //MainContainer.addChild(containerAtlas);
          for (const tex in resource.spritesheet.textures) {
            const coloredPart = new Sprite(resource.spritesheet.textures[tex]);
            coloredPart.alpha = 0;
            // coloredPart.tint = 0xff0000;

            coloredPart.name = tex;

            coloredPart.x =
              resource.data.frames[tex].spriteSourceSize.x *
                (1 / resource.data.meta.scale) +
              1;
            coloredPart.y =
              resource.data.frames[tex].spriteSourceSize.y *
                (1 / resource.data.meta.scale) +
              0;

            MainContainer.addChild(coloredPart);
            MainContainer.spriteAccess[tex] = coloredPart;
          }
        }
      );
    }

    const total = 120;

    // for (let i = 0; i < total; i++) {
    //   PixiApp.loader.add(
    //     `drawing${i}`,
    //     `${process.env.PUBLIC_URL}/images/split/image_${Math.floor(i / 12) +
    //       1}_${(i % 12) + 1}.png`,
    //     resource => {
    //       const drawing = new Sprite(resource.texture);
    //       drawing.texture.baseTexture.mipmap = false;
    //       drawing.scale.x = drawing.scale.y = 2;
    //       this.drawings.push(drawing);
    //       drawing.x = (i % 12) * drawing.texture.baseTexture.realWidth * 2;
    //       drawing.y =
    //         Math.floor(i / 12) * drawing.texture.baseTexture.realHeight * 2;
    //       drawing.name = `tiles_${i + 1}`;
    //       store.tiles.push(drawing);

    //       TweenMax.delayedCall(
    //         0.03 * i,
    //         param => {
    //           MainContainer.addChild(drawing);

    //           if (param === total - 1) {
    //             console.log("front", (performance.now() - this.now) / 1000);
    //             this.resolve();
    //           }
    //         },
    //         [i]
    //       );
    //     }
    //   );
    // }

    // for (let i = 0; i < total; i++) {
    //   PixiApp.loader.add(
    //     `drawingBck${i}`,
    //     `${process.env.PUBLIC_URL}/images/split-bck/image_${Math.floor(i / 12) +
    //       1}_${(i % 12) + 1}.png`,
    //     resource => {
    //       const drawingBck = new Sprite(resource.texture);
    //       drawingBck.scale.x = drawingBck.scale.y = 4;
    //       drawingBck.x =
    //         (i % 12) * drawingBck.texture.baseTexture.realWidth * 4;
    //       drawingBck.y =
    //         Math.floor(i / 12) * drawingBck.texture.baseTexture.realHeight * 4;
    //       store.tilesBck.push(drawingBck);

    //       TweenMax.delayedCall(
    //         0.03 * i,
    //         param => {
    //           MainContainer.addChildAt(drawingBck, 0);

    //           if (param === total - 1) {
    //             console.log("back", (performance.now() - this.now) / 1000);
    //             this.resolveBack();
    //           }
    //         },
    //         [i]
    //       );
    //     }
    //   );
    // }

    PixiApp.loader.add(
      "back",
      `${process.env.PUBLIC_URL}/images/packed/back.pack`,
      { xhrType: "arraybuffer" },
      (resource) => {
        const unpacker = new Unpacker(resource.data, backSort);

        for (let i = 0; i < total; i++) {
          const tex = new Image();
          TweenMax.delayedCall(
            0.035 * i,
            (param) => {
              tex.addEventListener("load", () => {
                const drawingBck = new Sprite.from(tex);
                // console.log(tex.src);
                drawingBck.scale.x = drawingBck.scale.y = 4;
                drawingBck.x = (i % 12) * 307 * 4;
                drawingBck.y = Math.floor(i / 12) * 274 * 4;
                store.tilesBck.push(drawingBck);

                MainContainer.addChildAt(drawingBck, 0);

                if (param === total - 1) {
                  console.log("back", (performance.now() - this.now) / 1000);
                  this.resolveBack();
                }
              });

              const reader = new FileReader();
              reader.addEventListener("load", (event) => {
                tex.src = event.target.result;
              });
              // const blob = unpacker.getAsURI(back[i][0])
              // tex.src = blob
              // window.URL.revokeObjectURL(blob)
              reader.readAsDataURL(
                new Blob([unpacker.getData(back[i][0])], { type: "image/png" })
              );
            },
            [i]
          );
        }
      }
    );

    PixiApp.loader.add(
      "pack",
      `${process.env.PUBLIC_URL}/images/packed/pack.pack`,
      { xhrType: "arraybuffer" },
      (resource) => {
        const unpacker = new Unpacker(resource.data, packSort);

        for (let i = 0; i < total; i++) {
          const tex = new Image();
          TweenMax.delayedCall(
            0.035 * i,
            (param) => {
              tex.addEventListener("load", () => {
                const drawing = new Sprite.from(tex);
                drawing.scale.x = drawing.scale.y = 2;
                this.drawings.push(drawing);
                drawing.x = (i % 12) * 615 * 2;
                drawing.y = Math.floor(i / 12) * 548 * 2;
                drawing.name = `tiles_${i + 1}`;
                store.tiles.push(drawing);

                MainContainer.addChild(drawing);

                if (param === total - 1) {
                  console.log("front", (performance.now() - this.now) / 1000);
                  this.resolve();
                }
              });

              const reader = new FileReader();
              reader.addEventListener("load", (event) => {
                tex.src = event.target.result;
              });
              // const blob = unpacker.getAsURI(pack[i][0])
              // tex.src = blob
              // window.URL.revokeObjectURL(blob)
              reader.readAsDataURL(
                new Blob([unpacker.getData(pack[i][0])], { type: "image/png" })
              );
            },
            [i]
          );
        }
      }
    );

    PixiApp.loader.add(
      "paperTexture",
      `${process.env.PUBLIC_URL}/images/PAPER_TEXTURE.png`
    );
    PixiApp.loader.add(
      "backgroundTile",
      `${process.env.PUBLIC_URL}/images/background-tile.jpg`
    );
    PixiApp.loader.add(
      "close",
      `${process.env.PUBLIC_URL}/images/close.png`,
      this.addCloseSprite
    );
    PixiApp.loader.add("pencil", `${process.env.PUBLIC_URL}/images/pencil.png`);
    PixiApp.loader.add(
      "pencil-1",
      `${process.env.PUBLIC_URL}/images/pencil-1.png`
    );
    PixiApp.loader.add(
      "pencil-2",
      `${process.env.PUBLIC_URL}/images/pencil-2.png`
    );
    PixiApp.loader.add("crayon", `${process.env.PUBLIC_URL}/images/crayon.png`);
    PixiApp.loader.add(
      "share",
      `${process.env.PUBLIC_URL}/images/share/share.png`
    );
    PixiApp.loader.add("tw", `${process.env.PUBLIC_URL}/images/share/tw.png`);
    PixiApp.loader.add("fb", `${process.env.PUBLIC_URL}/images/share/fb.png`);
    PixiApp.loader.add(
      "insta",
      `${process.env.PUBLIC_URL}/images/share/insta.png`
    );
    PixiApp.loader.add(
      "productFresque",
      `${process.env.PUBLIC_URL}/images/50ml.png`,
      this.addProductToFresque
    );
    PixiApp.loader.add(
      "ctaProduct",
      `${process.env.PUBLIC_URL}/images/cta-product.json`
    );

    Promise.all([
      this.loadAssets(),
      this.setupRtDb(),
      promiseMount(),
      promiseMountBack(),
    ]).then(() => {
      this.listenChange();
      // console.log(Draw)
      // for (let i = 0; i < store.tilesBck.length; i++) {
      //   MainContainer.setChildIndex(store.tilesBck[i], 0);
      // }

      // this.cull = new Cull.Simple();
      // this.cull.addList(MainContainer.children);
      // this.cull.cull(MainContainer.getBounds());

      EmitterManager.emit("IS_LOADED");
      this.setState({ isLoaded: true });
      Viewport.init(MainContainer);
      Viewport.initEvent();
      // TweenMax.delayedCall(1, () =>
      // );
      // if (process.env.NODE_ENV === "development") Viewport.initGUI();
      Viewport.setSelectedSprite();
      Viewport.handleColorizeEnd();
      // Viewport.zoomOnCenter(0.15, 1.3, 2.5);

      MainContainer.addPaperTexture(7389 * 2, 5489 * 2);
      PixiApp.stage.addChild(Viewport.cntExploration);
      Viewport.onResize();
      PixiApp.handleResize();
      requestAnimationFrame(() => {
        Viewport.onResize();
        PixiApp.handleResize();
      });
      MainContainer.setChildIndex(
        this.containerProducts,
        MainContainer.children.length - 1
      );
      // this.addCtaToProduct()
      this.graphShare = new Container();
      this.graphShare.name = "share";
      this.shareSprite = new Sprite(PixiApp.loader.resources["share"].texture);
      this.twSprite = new Sprite(PixiApp.loader.resources["tw"].texture);
      this.fbSprite = new Sprite(PixiApp.loader.resources["fb"].texture);
      this.instaSprite = new Sprite(PixiApp.loader.resources["insta"].texture);
      this.graphShare.addChild(this.shareSprite);
      this.twSprite.interactive =
        this.fbSprite.interactive =
        this.instaSprite.interactive =
          true;
      this.twSprite.buttonMode =
        this.fbSprite.buttonMode =
        this.instaSprite.buttonMode =
          true;
      this.graphShare.addChild(this.twSprite);
      this.twSprite.on(
        detectIt.primaryInput === "touch" ? "touchend" : "click",
        () => {
          MainContainer.productClicked = true;
          setTimeout(() => (MainContainer.productClicked = false), 100);
          const w = 600;
          const h = 300;

          const groups =
            /\/loccitane-omy-3a690\.appspot\.com\/o\/([\w%-]+)\.png/.exec(
              MainContainer.shareUrl
            );

          window.gtag("event", "shareDrawingTwitter");

          window.open(
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              this.state.lang.localTWText
            )}&url=${encodeURIComponent(
              `https://loccitane-omy-experience.com/sharingPage/${getUrlParam(
                "lang",
                "us_en"
              )}/${encodeURIComponent(groups[1])}/${document.referrer}`
            )}`,
            "twitter",
            `width=${w},height=${h},top=${(window.innerHeight - h) / 2},left=${
              (window.innerWidth - w) / 2
            }`
          );
        }
      );
      this.twSprite.on("mouseover", () => {
        this.socialOn = true;
      });
      this.twSprite.on("mouseout", () => {
        this.socialOn = false;
      });
      this.graphShare.addChild(this.fbSprite);
      this.fbSprite.on(
        detectIt.primaryInput === "touch" ? "touchend" : "click",
        () => {
          MainContainer.productClicked = true;
          setTimeout(() => (MainContainer.productClicked = false), 100);
          const groups =
            /\/loccitane-omy-3a690\.appspot\.com\/o\/([\w%-]+)\.png/.exec(
              MainContainer.shareUrl
            );

          window.FB.ui(
            {
              method: "share",
              href: `https://loccitane-omy-experience.com/sharingPage/${getUrlParam(
                "lang",
                "us_en"
              )}/${encodeURIComponent(groups[1])}/${document.referrer}`,
            },
            function (response) {
              window.gtag("event", "shareDrawingFB");
            }
          );
        }
      );
      this.fbSprite.on("mouseover", () => {
        this.socialOn = true;
      });
      this.fbSprite.on("mouseout", () => {
        this.socialOn = false;
      });
      this.graphShare.addChild(this.instaSprite);

      this.instaSprite.on(
        detectIt.primaryInput === "touch" ? "touchend" : "click",
        () => {
          MainContainer.productClicked = true;
          setTimeout(() => (MainContainer.productClicked = false), 100);
          const a = document.createElement("a");
          a.setAttribute("download", "insta.png");
          a.setAttribute("target", "blank");
          a.href = MainContainer.shareUrl;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          window.gtag("event", "shareDrawingInsta");
        }
      );
      this.instaSprite.on("mouseover", () => {
        this.socialOn = true;
      });
      this.instaSprite.on("mouseout", () => {
        this.socialOn = false;
      });
      this.instaSprite.x = -this.shareSprite.width * 0.85;
      this.instaSprite.y = -this.shareSprite.height * 0.3;
      this.twSprite.y = -this.shareSprite.height * 0.8;
      this.fbSprite.x = this.shareSprite.width * 0.85;
      this.fbSprite.y = -this.shareSprite.height * 0.3;
      this.shareSprite.anchor =
        this.instaSprite.anchor =
        this.twSprite.anchor =
        this.fbSprite.anchor =
          new Point(0.5, 0.5);
      this.instaSprite.visible =
        this.twSprite.visible =
        this.fbSprite.visible =
          false;
      this.shareSprite.interactive = true;
      this.shareSprite.on(
        detectIt.primaryInput === "mouse" ? "mouseover" : "touchend",
        () => {
          if (this.shareEntered) return;

          MainContainer.productClicked = true;
          setTimeout(() => (MainContainer.productClicked = false), 100);

          this.shareEntered = true;
          this.shareSprite.scale.x = this.shareSprite.scale.y = 1.5;
          this.instaSprite.visible =
            this.twSprite.visible =
            this.fbSprite.visible =
              true;
          this.instaSprite.visible =
            detectIt.primaryInput === "touch" ? false : true;
          TweenMax.staggerFromTo(
            [this.instaSprite, this.twSprite, this.fbSprite],
            0.4,
            {
              alpha: 0,
            },
            {
              alpha: 1,
            },
            0.2
          );
        }
      );
      this.shareSprite.on("mouseout", () => {
        if (!this.shareEntered || this.socialOn) return;
        if (this.timerOut) clearTimeout(this.timerOut);
        this.timerOut = setTimeout(() => {
          if (this.socialOn) return;
          this.shareEntered = false;
          TweenMax.killTweensOf(this.shareSprite);
          TweenMax.killTweensOf(this.instaSprite);
          TweenMax.killTweensOf(this.twSprite);
          TweenMax.killTweensOf(this.fbSprite);
          this.shareSprite.scale.x = this.shareSprite.scale.y = 1;
          this.instaSprite.visible =
            this.twSprite.visible =
            this.fbSprite.visible =
              false;
        }, 2000);
      });
      this.containerProducts.addChild(this.graphShare);
      this.graphShare.scale.x = this.graphShare.scale.y =
        detectIt.primaryInput === "touch" ? 2 : 1;
      this.graphShare.visible = false;
      PixiApp.ticker.add((delta) => {
        if (this.stats) this.stats.update();
        if (Viewport.interactive) {
          Viewport.onDragging();
          //Viewport.onBounce(PixiApp.ticker.deltaMS);
          // Viewport.onZooming();
          // Viewport.onPinching();
          // Viewport.onDecelerate(PixiApp.ticker.deltaMS);
        }
        Viewport.onCenterSprite();

        // if (this.viewport.dirty) {
        //   console.log("dirty");
        //   this.cull.cull(MainContainer.get());
        //   this.viewport.dirty = false;
        // }
      });

      if (getUrlParam("viewer", false)) {
        TweenMax.delayedCall(8, () => {
          const bounds = MainContainer.getBounds();
          bounds.width /= 2;
          bounds.width -= 11;
          bounds.height /= 2;
          bounds.height -= 6;
          const tex = PixiApp.renderer.generateTexture(
            MainContainer,
            SCALE_MODES.LINEAR,
            1,
            bounds
          );

          const spr = new Sprite(tex);

          const img = PixiApp.renderer.extract.image(spr);
          // const link = document.createElement("a");
          document.body.appendChild(img);
          // console.log(img.src);
          // link.href = img.src;
          // link.download = "omy.png";
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
        });
      }
    });

    this.showPopin();
  }

  addCtaToProduct = () => {
    this.ctaProductsContainer.forEach((el) => {
      const animatedSprite = new AnimatedSprite(
        PixiApp.loader.resources["ctaProduct"].spritesheet.animations[
          "CTA_PRODUCTv"
        ]
      );
      console.log("EL", el.position);
      el.addChild(animatedSprite);

      animatedSprite.interactive = true;
      animatedSprite.loop = false;
      if (getUrlParam("viewer", false)) {
        this.ctaProductsContainer.forEach((el) => (el.visible = false));
      }

      animatedSprite.onFrameChange = () => {
        if (animatedSprite.currentFrame === 16) animatedSprite.stop();
      };

      animatedSprite.on("mouseover", () => {
        animatedSprite.gotoAndPlay(0);
      });
      animatedSprite.on("mouseout", () => {
        animatedSprite.gotoAndStop(0);
      });
      animatedSprite.on(
        detectIt.primaryInput === "touch" ? "touchend" : "click",
        () => {
          MainContainer.productClicked = true;
          setTimeout(() => (MainContainer.productClicked = false), 100);
          window.open(this.state.lang.rangeURL);
          window.gtag("event", "productClick");
        }
      );
    });
  };

  handleResize = () => {
    const closeSprite = UIContainer.getChildByName("closeSprite");
    // PixiApp.renderer.resize(window.innerWidth, window.innerHeight);
    ColorContainer.resize();

    if (closeSprite)
      closeSprite.x = window.innerWidth / 2 - closeSprite.width / 2;

    if (this.vignette) {
      this.vignette.uResolution[0] = window.innerWidth;
      this.vignette.uResolution[1] = window.innerHeight;
    }
  };

  render() {
    return (
      <div ref={this.refMain} id="loccitaneOmy">
        {this.state.currentStep === 0 && (
          <Pre
            isLoaded={this.state.isLoaded}
            setCurrentStep={this.setCurrentStep}
            lang={this.state.lang}
          ></Pre>
        )}

        {this.state.currentStep === 1 && (
          <Draw
            connectedUsers={this.state.connectedUsers}
            lang={this.state.lang}
          ></Draw>
        )}

        <Transition
          in={this.state.showPopinWin}
          timeout={{ enter: 500, exit: 400 }}
          appear={false}
          mountOnEnter={true}
          unmountOnExit={true}
          onEnter={PopinWinEnter}
          onExit={PopinWinExit}
        >
          <PopinWin
            closePopin={this.hidePopin}
            codePromo={this.state.codePromo}
            lang={this.state.lang}
            locale={this.state.locale}
          />
        </Transition>

        <Transition
          in={this.state.showPopinLoose}
          timeout={{ enter: 500, exit: 400 }}
          appear={false}
          mountOnEnter={true}
          unmountOnExit={true}
          onEnter={PopinLooseEnter}
          onExit={PopinLooseExit}
        >
          <PopinLoose
            closePopin={this.hidePopin}
            lang={this.state.lang}
            locale={this.state.locale}
          />
        </Transition>
      </div>
    );
  }
}

export default App;
