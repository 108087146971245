export default {
  color: 'Kleur de OMY fresco en wie weet vind je wel en promocode onderweg.',
  btnStart: 'Start met kleuren',
  avatar: 'Je OMY avatar is...',
  instructionTitle: 'Instructies',
  instruction:
    'Verken de fresco, kies een ongekleurd gedeelte en klik om te kleuren',
  usersColorize: 'gebruikers zijn nu aan het kleuren.',
  instructionColorize: `"Kies de kleur van je potlood en kleur het geselecteerde gedeelte.
  Vul het hele gedeelte om de kleur te valideren en ontdek of je iets gewonnen hebt."`,
  sorry: `Sorry! 
  Er is geen promocode verborgen in dit gedeelte.`,
  eshop: 'Gebruik het op nl.loccitane.com',
  discoverMore: `Proficiat! Je wint een promocode!
  Gebruik het in onze webshop.`,
  win: `Proficiat!
Je wint een promocode!`,
  tryAgain: 'Probeer nog eens!',
  close: 'Sluiten',
  discover: "Ontdek alle gekleurde fresco's.",
  discoverUrl: '',
  howTo: 'Hoe deze code gebruiken?',
  push: 'Ontdek de Shea limited edition collectie met OMY.',
  rangeURL: "https://nl.loccitane.com/l'occitane-x-omy,50,2,88989,1457430.htm",
  colorAll:
    'Als je een kleur gekozen hebt, hou je deze kleur om het hele element mee in te kleuren. ',
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText:
    "Kom en kleur de OMY online fresco voor de L'Occitane, en probeer promotiecodes te winnen op de huidverzorgingsproducten van de Shea-collectie. Potloden omhoog! #ProvenceEverywhere",
  globalTWText: `LET'S COLOR TOGETHER
  Kom en kleur de OMY online fresco voor de L'Occitane, en probeer promotiecodes te winnen op de huidverzorgingsproducten van de Shea-collectie. Potloden omhoog! #ProvenceEverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText:
    "Ook jij kan kleuren in L'Occitane's OMY online fresco en proberen promotiecodes te winnen voor de Shea collectie huidverzorgingsproducten. Potloden omhoog! #ProvenceEverywhere",
  localTWText: `LET'S COLOR TOGETHER
  Ook jij kan kleuren in L'Occitane's OMY online fresco en proberen promotiecodes te winnen voor de Shea collectie huidverzorgingsproducten. Potloden omhoog! #ProvenceEverywhere`,
  frescoPourcent:
    'Dit percentage is de mate van voltooiing van de muurschildering... Benieuwd hoe deze afgewerkte muurschildering eruit zal zien? Bekijk onze Instagram-account: @loccitane_nl',
  tc:
    'https://nl.loccitane.com/reglement-van-de-game-lets-color-together,50,2,88989,1462656.htm',
  tcTitle: 'Koopvoorwaarden',
  frescoCompleted: 'De fresco is $ dagen geleden begonnen',
  ie:
    'Deze browser is niet compatibel voor onze animatie, kies een nieuwere versie aub.',
  continue: 'Kleur verder om je code te vinden!',
  eye: '',
  howToURL: 'https://nl.loccitane.com/couleur-aanbieding,50,2,88989,1457441.htm'
}
