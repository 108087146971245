import { Renderer, Ticker, Container, Loader } from "pixi.js";

import Visibility from "visibilityjs";

// settings.RESOLUTION = window.devicePixelRatio || 1;

class PixiApp {
  constructor() {
    this.renderer = new Renderer({
      autoDensity: true,
      antialias: true,
      clearBeforeRender: false,
      backgroundColor: 0xffffff,
      preserveDrawingBuffer: true
      // sharedLoader: true,
      // sharedTicker: true,
      // resizeTo: window,
      // forceFXAA: true
    });

    // super({
    //   autoDensity: false,
    //   antialias: false,
    //   backgroundColor: 0xffffff,
    //   sharedLoader: true,
    //   sharedTicker: true,
    //   resizeTo: window,
    //   forceFXAA: true
    // });

    this.ticker = new Ticker({});

    this.ticker.start();

    this.loader = new Loader();

    this.stage = new Container();

    window.addEventListener("resize", this.handleResize);

    this.handleResize();
    this.ticker.add(this.render);

    Visibility.change((e, state) => {
      if (state === "visible") {
        setTimeout(() => {
          this.view.width = window.innerWidth - 1;
          requestAnimationFrame(() => (this.view.width = window.innerWidth));
        }, 100);
      }
    });
  }

  handleResize = () => {
    this.renderer.resize(window.innerWidth, window.innerHeight);
  };

  render = () => {
    this.renderer.render(this.stage);
  };

  get view() {
    return this.renderer.view;
  }

  get screen() {
    return this.renderer.screen;
  }
}

export default new PixiApp();
