import { Container } from "pixi.js";
import ColorCircle from "./ColorCircle";
import colors from "../datas/colors";
import store from "../datas/store";
import { isMobile } from "../utils";
import detectIt from "detect-it";
// import circleSvg from '../../src/images/icons/circle.svg';
// import EmitterManager from "./EmitterManager";

class ColorContainer extends Container {
  constructor() {
    super();
    this.visible = false;
    this.alpha = 0;
    this.ratio = 70 / 1915;
    this.minRAdius = 35;
    this.radius =
      (window.innerWidth * this.ratio) / 2 < this.minRAdius
        ? this.minRAdius
        : (window.innerWidth * this.ratio) / 2;
    this.marginBottom = this.radius * 2 + 10;
    if (isMobile()) {
      let marginBottom = 12;
      this.addCircleMobile(marginBottom);
      this.x = 40;
      this.y = window.innerHeight / 2 - this.height / 2 + marginBottom; // margin bottom
    } else {
      this.addCircleDesktop();
      this.x = window.innerWidth / 2 - this.width / 2 + 40; // margin right
      this.y = window.innerHeight - this.marginBottom;
    }
  }

  addCircleDesktop = () => {
    this.groupColor = new Container();
    this.groupColor.interactive = true;
    this.groupColor.buttonMode = true;
    const marginRight = 40;
    const border = 10;

    Object.keys(colors).forEach((key, index) => {
      let xPosition = (this.radius * 2 + marginRight) * index;
      this.addChild(
        new ColorCircle(
          xPosition,
          0,
          this.radius,
          border,
          colors[key],
          key,
          store.color.name === key,
          index,
          isMobile()
        )
      );
    });
  };

  addCircleMobile = marginBottom => {
    this.groupColor = new Container();
    this.groupColor.interactive = true;
    this.groupColor.buttonMode = true;
    const border = 5;

    let minRAdius = 17;

    Object.keys(colors).forEach((key, index) => {
      let yPosition = (minRAdius * 2 + marginBottom) * index;
      this.addChild(
        new ColorCircle(
          0,
          yPosition,
          minRAdius,
          border,
          colors[key],
          key,
          store.color.name === key,
          index,
          isMobile()
        )
      );
    });
  };

  resize = () => {
    if (detectIt.primaryInput === "touch") {
      let marginBottom = 17;
      this.x = 40;
      this.y = window.innerHeight / 2 - this.height / 2 + marginBottom; // margin bottom
    } else {
      this.radius =
        (window.innerWidth * this.ratio) / 2 < this.minRAdius
          ? this.minRAdius
          : (window.innerWidth * this.ratio) / 2;

      this.x = window.innerWidth / 2 - this.width / 2 + 40; // margin right
      this.y = window.innerHeight - this.marginBottom;
      // const marginRight = 40;
    }

    // this.children.forEach((colorCircle, index) => {
    //   let xPosition = (this.radius * 2 + marginRight) * index;
    //   colorCircle.resize(this.radius, xPosition, 10)
    // })
  };
}

export default new ColorContainer();
