export default {
  color:
    "Colora i disegni illustrati da OMY e prova a vincere i codici promozionali che si nascondono all'interno del disegno.",
  btnStart: 'Inizia a colorare',
  avatar: 'Il tuo OMY avatar è...',
  instructionTitle: 'Istruzioni',
  instruction: "Clicca su un'area del disegno per colorarla.",
  usersColorize: 'utenti stanno colorando questo disegno.',
  instructionColorize: `Scegli il colore della tua matita e colora l'area selezionata.
Riempi l'intera area per confermare il colore e scopri se hai vinto.`,
  sorry: `Peccato!
Nessun codice promozionale si nascondeva in quest'area.`,
  eshop: 'Approfittane su it.loccitane.com',
  discoverMore: 'SCOPRO',
  win: `Congratulazioni! Hai vinto un codice promozionale!
  Approfittane nella nostra boutique online.`,
  tryAgain: 'Prova ancora!',
  close: 'Chiudi',
  discover: 'Scopri tutti gli disegni completati.',
  discoverUrl: '',
  howTo: 'Come usare il codice promozionale?',
  push: 'Scopro la collezione Karité in Edizione Limitata OMY.',
  rangeURL: 'https://it.loccitane.com/l-occitane-x-omy,80,2,97746,1464194.htm',
  colorAll:
    "Scegli un colore e dipingi tutto l'elemento con il colore selezionato.",
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText:
    'Colora online i disegni di OMY per  L’Occitane, e prova vincere fantastici codici promozionali. Sfoga la tua creattività! #ProvenceEverywhere',
  globalTWText:
    'Colora online i disegni di OMY per  L’Occitane, e prova vincere fantastici codici promozionali. Sfoga la tua creattività! #ProvenceEverywhere',
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText:
    'Colora anche tu i disegni di OMY per  L’Occitane, e prova vincere fantastici codici promozionali. Sfoga la tua creattività! #ProvenceEverywhere',
  localTWText: `LET’S COLOR TOGETHER 
    Colora anche tu i disegni di OMY per  L’Occitane, e prova vincere fantastici codici promozionali. Sfoga la tua creattività! #ProvenceEverywhere`,
  frescoPourcent: `Questa percentuale è il grado di completamento del disegno. 
  Curioso di sapere che aspetto avrà questo affresco finito? Scoprilo sul nostro account Instagram: @loccitane_it`,
  tc:
    "https://it.loccitane.com/regolamento-omy-x-l'occitane,80,2,97746,1464198.htm",
  tcTitle: 'Termini e condizioni',
  frescoCompleted: 'Disegno iniziato da X giorni.',
  ie:
    'Il tuo browser non è compatibile con il nostro gioco. Ti preghiamo di utilizzarne uno più recente.',
  continue: 'Continua a colorare per trovare il tuo buono!',
  eye: '',
  howToURL: 'https://it.loccitane.com/offerta-colorata,80,2,97763,1465875.htm'
}
