/** @jsx jsx */
import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import backgroundPromoCode from "../images/home/backgroundPromoCode.png";
import giftWinImg from "../images/home/gift-win.png";
import giftWin2Img from "../images/home/gift-win-2.png";
// import giftTestImg from "../images/home/gift-test.png";
import textureBck from "../images/home/popin-bck.png";
// import arrowImg from "../images/home/fleche.png";
import { mq } from "../css/style";
import Particlefly from "../helpers/Particlefly";
import PixiApp from "../helpers/PixiApp";
import Victor from "victor";

import { map } from "../utils";
import { TweenMax, Power3, Elastic, TimelineMax } from "gsap";
import detectIt from "detect-it";
import Viewport from "../helpers/Viewport";

export class PopinWin extends Component {
  sectionRef = React.createRef();

  componentDidMount() {
    this.nbParticle = 3;
    this.particleArr = [];
    // this.initParticle();
  }

  initParticle = () => {
    // add new particle to screen
    for (let i = 0; i < this.nbParticle; i++) {
      const minPos = 50;
      const minPosX = window.innerWidth / 2 - 500;
      const minPosY = window.innerHeight / 2 - 500;
      const maxPosX = window.innerWidth / 2 + 500;
      const maxPoY = window.innerHeight / 2 + 500;

      let randomX = map(Math.random(), 0, 1, minPosX, maxPosX);
      let randomY = map(Math.random(), 0, 1, minPosY, maxPoY);

      this.fly = new Particlefly(this.sectionRef.current);
      this.particleArr.push(this.fly);
      this.fly.init(randomX, randomY);
    }

    PixiApp.ticker.add(() => {
      for (let i = 0; i < this.nbParticle; i++) {
        this.particleArr[i].update();
      }
    });
  };

  render() {
    return (
      <div css={wrapperPopinCss} ref={this.sectionRef}>
        <div className="popin" css={popinCss}>
          <div css={wrapperContent}>
            <img className="img1" css={giftImgCss} src={giftWinImg} alt="" />
            <p className="firstSentence" css={firstSentenceCss(this.props.locale)}>
              {this.props.lang.win}
            </p>
            <div css={wrapperPromoCodeCss}>
              <span css={promoCodeCss}>{this.props.codePromo}</span>
              <img src={backgroundPromoCode} css={backgroundPromoCodeCss} alt="" />
            </div>
            <img className="img2" src={giftWin2Img} css={giftTestImgCss} alt="" />
            <p className="secondSentence" css={secondSentenceCss}>
              <a className="button " href={this.props.lang.howToURL} target="blank">
                {this.props.lang.howTo}
              </a>
            </p>
          </div>

          <button
            css={buttonClass}
            className="no-drag"
            onClick={() => {
              this.props.closePopin();
            }}
          >
            <span>{this.props.lang.close}</span>
          </button>
          {/* <button css={sentenceDiscoverCss}>
            <span>Discover all the completed frescos</span>
            <img src={arrowImg} alt="" />
          </button> */}
        </div>
      </div>
    );
  }
}

export const onExit = html => {
  TweenMax.to(html, 0.4, {
    opacity: 0
  });
};

export const onEnter = html => {
  const popin = html.querySelector(".popin");
  const img1 = html.querySelector(".img1");
  const img2 = html.querySelector(".img2");
  const firstSentence = html.querySelector(".firstSentence");
  const secondSentence = html.querySelector(".secondSentence");
  const button = html.querySelector(".button");
  const flees = html.querySelectorAll(".fly");

  if(Viewport.screenHeight < 400) {
    TweenMax.set(popin, { opacity: 0, scale: 0.5 });
  } else {
    TweenMax.set(popin, { opacity: 0, scale: 0.7 });
  }
  TweenMax.set(img1, { opacity: 0, y: 10 });
  TweenMax.set(img2, { opacity: 0, y: 10 });
  TweenMax.set(firstSentence, { opacity: 0, y: 10 });
  TweenMax.set(secondSentence, { opacity: 0, y: 10 });
  TweenMax.set(button, { opacity: 0 });
  TweenMax.set(flees, { opacity: 0 });

  const tl = new TimelineMax({ delay: 0.1 });

  tl.to(html, 0.3, { opacity: 1 });
  if(Viewport.screenHeight < 400) {
    tl.to(
      popin,
      0.5,
      {
        opacity: 1,
        scale: .7,
        ease: Elastic.easeOut.config(1, 0.3)
      },
      "+=.2"
    );  } else {
    tl.to(
      popin,
      0.5,
      {
        opacity: 1,
        scale: detectIt.primaryInput === "touch" ? 0.7 : 1,
        ease: Elastic.easeOut.config(1, 0.3)
      },
      "+=.2"
    ); 
   }
  tl.to(img1, 0.5, { y: 0, opacity: 1, ease: Power3.easeOut }, "-=.28");
  tl.to(firstSentence, 0.5, { y: 0, opacity: 1, ease: Power3.easeOut }, "-=.28");
  tl.to(img2, 0.5, { y: 0, opacity: 1, ease: Power3.easeOut }, "-=.28");
  tl.to(secondSentence, 0.5, { y: 0, opacity: 1, ease: Power3.easeOut }, "-=.28");
  tl.to(button, 0.5, { opacity: 1, ease: Power3.easeOut }, "-=.28");
  tl.staggerTo(flees, 0.5, { opacity: 1, ease: Power3.easeOut }, 0.1, "-=.28");
};

const wrapperContent = css`
  ${mq.medium(css`
    margin-top: -30px;
  `)};
`;

const giftImgCss = css`
  display: none !important;
  margin: 0 auto;
  margin-bottom: 20px;

  ${mq.medium(css`
    display: block;
  `)};
`;

const backgroundPromoCodeCss = css`
  height: 80px;
  margin: 0 auto;

  ${mq.medium(css`
    height: initial;
  `)};
`;
const giftTestImgCss = css`
  height: 10vh;
  margin-left: auto;
  margin-right: auto;

  ${mq.medium(css`
    height: initial;
  `)};
`;

const wrapperPromoCodeCss = css`
  position: relative;
  display: flex;
  align-items: center;
`;

const promoCodeCss = css`
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 600;

  ${mq.medium(css`
    font-size: 47px;
  `)};
`;

const buttonClass = css`
  align-items: center;
  bottom: -30px;
  background-color: #fff !important;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 60px;
  justify-content: center;
  margin-left: -67.5px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  text-transform: uppercase;
  width: 135px;

  &::after {
    background-color: rgba(255, 255, 255, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.1s;
    width: 100%;
  }

  span {
    position: relative;
    z-index: 1;
  }

  &:hover {
    span {
      transform: scale(1.05);
    }

    &::after {
      transform: translateX(0);
    }
  }
`;

const wrapperPopinCss = css`
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const popinCss = css`
  position: relative;
  background-color: #ffcb00;
  background-image: url(${textureBck});
  width: 100vw;
  height: 100vw;
  /* min-width: 360px; */
  /* min-height: 360px; */
  border-radius: 50%;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);

  ${mq.medium(css`
    margin-top: -25px;
    min-height: 500px;
    min-width: 500px;
    max-width: 650px;
    max-height: 650px;
    transform: none;
    width: 84vh;
    height: 84vh;
  `)};

  ${mq.mHeight(css`
    transform: scale(0.7);
  `)};
`;

const firstSentenceCss= (locale) => css`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 0 3em;
  white-space: pre-line;

  ${mq.medium(css`
    /* font-size: ${locale === "br_pt" ? '15px' : '22px'}; */
    font-size: ${locale === "br_pt" ? '15px' : '22px'};
  ${locale === 'au_en' && 
  'font-size:15px;max-width:80%;margin-left:auto;margin-right:auto;'}
    margin-bottom: 25px;
  `)};
`;

const secondSentenceCss = css`
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
  padding: 1em 6em;

  ${mq.medium(css`
    color: inherit;
    padding: 2em 6em;
    position: static;
  `)};
`;

// const sentenceDiscoverCss = css`
//   font-size: 17px;
//   color: #fff;
//   position: absolute;
//   bottom: -135px;
//   text-align: center;
//   margin: 0 auto;
//   width: 100%;

//   background: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   img {
//     margin-left: 15px;
//     width: 40px;
//   }
// `;

export default PopinWin;
