import EmitterManager from "./EmitterManager";
import detectIt from "detect-it";

export const MOUSE_DOWN = "MOUSE_DOWN";
export const MOUSE_UP = "MOUSE_UP";

class MouseManager {
  constructor() {
    this.x = 0;
    this.y = 0;

    if (detectIt.primaryInput === "touch") {
      window.addEventListener("touchmove", this.handleMouseMove);
      window.addEventListener("touchstart", this.handleMouseDown);
      window.addEventListener("touchend", this.handleMouseUp);
    } else {
      window.addEventListener("pointermove", this.handleMouseMove);
      window.addEventListener("pointerdown", this.handleMouseDown);
      window.addEventListener("pointerup", this.handleMouseUp);
    }
  }

  handleMouseMove = event => {
    this.x = event.touches ? event.touches[0].clientX : event.clientX;
    this.y = event.touches ? event.touches[0].clientY : event.clientY;
  };

  handleMouseDown = () => {
    EmitterManager.emit(MOUSE_DOWN);
  };

  handleMouseUp = () => {
    EmitterManager.emit(MOUSE_UP);
  };

  get isTouchOnly() {
    return detectIt.primaryInput === "touch";
  }
}

export default new MouseManager();
