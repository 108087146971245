export const fragment = `
    precision lowp float;
    varying vec2 vTextureCoord;
    uniform sampler2D uSampler;
    uniform vec2 iResolution;

    uniform vec2 uOffset;

    void main(void)
    {
        vec2 uvs = vTextureCoord.xy;
        vec2 uv = vTextureCoord.xy;

        uv.x = 1.0 - abs(uvs.y * 3. - 1.5);
        uv.x = smoothstep( 0.1,0.13,uv.x);

        vec4 blur = vec4(0.0);

        // Sample top left pixel
        blur += texture2D(uSampler, vec2(vTextureCoord.x - uOffset.x, vTextureCoord.y + uOffset.y));

        // Sample top right pixel
        blur += texture2D(uSampler, vec2(vTextureCoord.x + uOffset.x, vTextureCoord.y + uOffset.y));

        // Sample bottom right pixel
        blur += texture2D(uSampler, vec2(vTextureCoord.x + uOffset.x, vTextureCoord.y - uOffset.y));

        // Sample bottom left pixel
        blur += texture2D(uSampler, vec2(vTextureCoord.x - uOffset.x, vTextureCoord.y - uOffset.y));

        // Average
        blur *= 0.25;

        vec4 textureScene = texture2D(uSampler, uvs);
        vec4 maskTexture =  vec4(vec3(uv.x),1.);

        vec4 startColor = vec4(0.0, 0.0, 0.0, 1.0);
        vec4 endColor = vec4(1.0, 1.0, 1.0, 1.0);
        vec4 color = vec4(0.);
        float y = gl_FragCoord.y;

        float step1 = iResolution.y * 0.0;
        float step2 = iResolution.y * 0.3;
        float step3 = iResolution.y * 0.6;

        color = mix(startColor, endColor, smoothstep(step1, step2, y));
        color = mix(color, endColor, smoothstep(step2, step3, y));
        color = mix(color, startColor, smoothstep(step3, iResolution.y, y));

        blur.rgb = mix(blur.rgb,textureScene.rgb, color.r);

        gl_FragColor = vec4(blur);
    }
`;
