import { Sprite, TextStyle, Text } from "pixi.js";

import PixiApp from "./PixiApp";
import MouseManager, { MOUSE_DOWN, MOUSE_UP } from "./MouseManager";
import { toRadian, range, clamp } from "../utils";
import EmitterManager from "./EmitterManager";
import MainContainer from "./MainContainer";
import { TweenMax, Power3 } from "gsap";
import detectIt from "detect-it";

export default class Pencil extends Sprite {
  constructor({ x = 0, y = 0, pointer = false, name } = {}) {
    super(PixiApp.loader.resources["pencil-1"].texture);
    // alert(detectIt.deviceType);

    this.pencilLead = new Sprite(PixiApp.loader.resources["pencil-2"].texture);
    this.pencilLead.y = -this.pencilLead.height;
    this.pencilLead.x = -this.pencilLead.width / 2;

    this.addChild(this.pencilLead);
    this.anchor.set(0.5, 1);

    this.finalTargetX = 0;
    this.finalTargetY = 0;

    this.x = x;
    this.y = y;
    this.pointer = pointer;
    this.isMouseDown = false;
    this.counter = 0;
    this.name = "pencil";
    const style = new TextStyle({
      fontSize: 16,
      fill: 0xffffff
    });
    const text = new Text(name, style);
    text.rotation = toRadian(-90);
    text.anchor.set(0.5);
    text.y = -this.height / 2;

    this.addChild(text);

    PixiApp.ticker.add(this.movePen);

    EmitterManager.on(MOUSE_DOWN, this.handleMouseDown);
    EmitterManager.on(MOUSE_UP, this.handleMouseUp);
  }

  stop = () => {
    PixiApp.ticker.remove(this.movePen);

    EmitterManager.off(MOUSE_DOWN, this.handleMouseDown);
    EmitterManager.off(MOUSE_UP, this.handleMouseUp);
  };

  movePen = () => {
    this.counter += 0.1;
    if (this.counter === 1) this.counter = 0;

    let finalTargetX;
    let finalTargetY;

    if (this.pointer) {
      finalTargetX = MouseManager.x;
      finalTargetY = MouseManager.y;
    } else {
      finalTargetX = this.finalTargetX;
      finalTargetY = this.finalTargetY;
    }

    this.x += (finalTargetX + (this.pointer && !this.isMouseDown ? 20 : 0) - this.x) * (this.isMouseDown ? 0.9 : 0.2);
    this.y +=
      (finalTargetY - this.y + (this.pointer ? Math.sin(this.counter) * (this.isMouseDown ? 3 : 15) : 0)) *
      (this.isMouseDown ? 0.9 : 0.2);

    this.rotation +=
      (toRadian(
        range(clamp(finalTargetX + (this.pointer && !this.isMouseDown ? 20 : 0) - this.x, -50, 50), -50, 50, 45, -45) +
          (this.isMouseDown || !this.pointer ? 20 : 0)
      ) -
        this.rotation) *
      (this.isMouseDown ? 0.3 : 0.12);
  };

  handleMouseDown = () => {
    this.isMouseDown = MainContainer.isDrawing;
  };

  handleMouseUp = () => {
    this.isMouseDown = false;
  };

  hide = () => {
    if (detectIt.primaryInput === "mouse") {
      TweenMax.to(this, 0.2, {
        alpha: 0,
        ease: Power3.easeInOut
      });
    }
  };

  show = () => {
    if (detectIt.primaryInput === "mouse") {
      TweenMax.to(this, 0.2, {
        alpha: 1,
        ease: Power3.easeInOut
      });
    }
  };
}
