export default {
  color: 'Pinte os desenhos e tenha a chance de encontrar ofertas exclusivas.',
  btnStart: 'Comece a colorir',
  avatar: 'Seu avatar OMY é: ',
  instructionTitle: 'Instruções',
  instruction:
    'Navegue pelo desenho, escolha a área desejada e clique para colorir.',
  usersColorize: 'usuários estão colorindo esse desenho agora.',
  instructionColorize: `Escolha uma cor e preencha toda a área selecionada.
Complete o desenho para descobrir o que você ganhou.`,
  sorry: `Não foi dessa vez! Nenhum código promocional está escondido aqui`,
  eshop: 'Acesse o site e descubra como usar.',
  discoverMore: 'Saiba mais',
  win: `Parabéns!
Você ganhou uma oferta exclusiva!`,
  tryAgain: 'Tente novamente!',
  close: 'Fechar',
  discover: 'Descubra todos os desenhos.',
  discoverUrl: '',
  howTo: 'Como usar o código promocional?',
  push: 'Descubra a Edição Limitada de Karité & OMY',
  rangeURL: 'https://br.loccitane.com/linha-shea-colouring,43,2,98986,1559871.htm',
  colorAll:
    'Depois que escolher uma cor, a mantenha selecionada para pintar todo o espaço',
  globalFBTitle: 'Vamos colorir!',
  globalFBText:
    'Pinte os desenhos e tenha a chance de encontrar ofertas exclusivas.',
  globalTWText:
    'Pinte os desenhos e tenha a chance de encontrar ofertas exclusivas.',
  localFBTitle: 'Vamos colorir!',
  localFBText:
    "Pinte o desenho de L'Occitane en Provence em parceria com OMY e tenha a chance de ganhar oferta exclusiva na linha Karité",
  localTWText:
    "Pinte o desenho de L'Occitane en Provence em parceria com OMY e tenha a chance de ganhar oferta exclusiva na linha Karité",
  frescoPourcent: `O percentual é o quanto o desenho está completo`,
  tc: 'https://br.loccitane.com/termos-e-condicoes-animacao-karite,43,2,98863,1547229.htm',
  tcTitle: 'Termos e condições',
  frescoCompleted: 'Desenho iniciado a $ dias atrás',
  ie:
    'Este navegador não é compatível com a animação, por favor utilize outro para navegar',
  continue: 'Continue a colorir para achar o código!',
  eye: 'https://br.loccitane.com/creme-para-m%C3%A3os-karit%C3%A9,43,2,98863,1547231.htm',
  howToURL: 'https://br.loccitane.com/promo-animacao-karite,43,2,98863,1547230.htm'
}
