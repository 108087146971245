import { Container } from "pixi.js";

class UIContainer extends Container {
  constructor() {
    super();
    this.name = "uiContainer";
  }
}

export default new UIContainer();
