export default {
  fr: 'france',
  us: 'united states',
  ca: 'canada',
  br: 'brazil',
  au: 'australia',
  my: 'malaysia',
  tw: 'taiwan',
  sg: 'singapore',
  jp: 'japan',
  de: 'germany',
  es: 'spain',
  uk: 'united kingdom',
  ch: 'switzerland',
  be: 'belgium',
  it: 'italia',
  nl: 'netherlands',
  pt: 'portugal',
  ru: 'russia',
  nz: 'new zealand',
  hk: 'hong kong',
  sa: 'south africa',
  co: 'colombia',
  hr: 'croatia',
  si: 'slovenia',
  me: 'middle east',
  ae: 'united arab emirates'
}
