export default [
  "Blue Vanilla",
  "Blue Seed",
  "Blue Leaf",
  "Blue Cat",
  "Blue Bird",
  "Blue Cherry",
  "Blue Violet",
  "Blue Basil",
  "Blue Fruit",
  "Blue Tree",
  "Blue Lavender",
  "Blue Rose",
  "Blue Bee",
  "Blue Honey",
  "Blue Bicycle",
  "Blue Blossom",
  "Blue Dendelion",
  "Blue Verbena",
  "Blue Almond",
  "Green Flower",
  "Green Cloud",
  "Green Vanilla",
  "Green Seed",
  "Green Leaf",
  "Green Cat",
  "Green Bird",
  "Green Cherry",
  "Green Violet",
  "Green Basil",
  "Green Fruit",
  "Green Tree",
  "Green Lavender",
  "Green Rose",
  "Green Bee",
  "Green Honey",
  "Green Bicycle",
  "Green Blossom",
  "Green Dendelion",
  "Green Verbena",
  "Green Almond",
  "Yellow Flower",
  "Yellow Cloud",
  "Yellow Vanilla",
  "Yellow Seed",
  "Yellow Leaf",
  "Yellow Cat",
  "Yellow Bird",
  "Yellow Cherry",
  "Yellow Violet",
  "Yellow Basil",
  "Yellow Fruit",
  "Yellow Tree",
  "Yellow Lavender",
  "Yellow Rose",
  "Yellow Bee",
  "Yellow Honey",
  "Yellow Bicycle",
  "Yellow Blossom",
  "Yellow Dendelion",
  "Yellow Verbena",
  "Yellow Almond",
  "Red Flower",
  "Red Cloud",
  "Red Vanilla",
  "Red Seed",
  "Red Leaf",
  "Red Cat",
  "Red Bird",
  "Red Cherry",
  "Red Violet",
  "Red Basil",
  "Red Fruit",
  "Red Tree",
  "Red Lavender",
  "Red Rose",
  "Red Bee",
  "Red Honey",
  "Red Bicycle",
  "Red Blossom",
  "Red Dendelion",
  "Red Verbena",
  "Red Almond",
  "Pink Flower",
  "Pink Cloud",
  "Pink Vanilla",
  "Pink Seed",
  "Pink Leaf",
  "Pink Cat",
  "Pink Bird",
  "Pink Cherry",
  "Pink Violet",
  "Pink Basil",
  "Pink Fruit",
  "Pink Tree",
  "Pink Lavender",
  "Pink Rose",
  "Pink Bee",
  "Pink Honey",
  "Pink Bicycle",
  "Pink Blossom",
  "Pink Dendelion",
  "Pink Verbena",
  "Pink Almond",
  "Orange Flower",
  "Orange Cloud",
  "Orange Vanilla",
  "Orange Seed",
  "Orange Leaf",
  "Orange Cat",
  "Orange Bird",
  "Orange Cherry",
  "Orange Violet",
  "Orange Basil",
  "Orange Fruit",
  "Orange Tree",
  "Orange Lavender",
  "Orange Rose",
  "Orange Bee",
  "Orange Honey",
  "Orange Bicycle",
  "Orange Blossom",
  "Orange Dendelion",
  "Orange Verbena",
  "Orange Almond",
  "Shiny Flower",
  "Shiny Cloud",
  "Shiny Vanilla",
  "Shiny Seed",
  "Shiny Leaf",
  "Shiny Cat",
  "Shiny Bird",
  "Shiny Cherry",
  "Shiny Violet",
  "Shiny Basil",
  "Shiny Fruit",
  "Shiny Tree",
  "Shiny Lavender",
  "Shiny Rose",
  "Shiny Bee",
  "Shiny Honey",
  "Shiny Bicycle",
  "Shiny Blossom",
  "Shiny Dendelion",
  "Shiny Verbena",
  "Shiny Almond",
  "Bright Flower",
  "Bright Cloud",
  "Bright Vanilla",
  "Bright Seed",
  "Bright Leaf",
  "Bright Cat",
  "Bright Bird",
  "Bright Cherry",
  "Bright Violet",
  "Bright Basil",
  "Bright Fruit",
  "Bright Tree",
  "Bright Lavender",
  "Bright Rose",
  "Bright Bee",
  "Bright Honey",
  "Bright Bicycle",
  "Bright Blossom",
  "Bright Dendelion",
  "Bright Verbena",
  "Bright Almond",
  "Purple Flower",
  "Purple Cloud",
  "Purple Vanilla",
  "Purple Seed",
  "Purple Leaf",
  "Purple Cat",
  "Purple Bird",
  "Purple Cherry",
  "Purple Violet",
  "Purple Basil",
  "Purple Fruit",
  "Purple Tree",
  "Purple Lavender",
  "Purple Rose",
  "Purple Bee",
  "Purple Honey",
  "Purple Bicycle",
  "Purple Blossom",
  "Purple Dendelion",
  "Purple Verbena",
  "Purple Almond",
  "Cute Flower",
  "Cute Cloud",
  "Cute Vanilla",
  "Cute Seed",
  "Cute Leaf",
  "Cute Cat",
  "Cute Bird",
  "Cute Cherry",
  "Cute Violet",
  "Cute Basil",
  "Cute Fruit",
  "Cute Tree",
  "Cute Lavender",
  "Cute Rose",
  "Cute Bee",
  "Cute Honey",
  "Cute Bicycle",
  "Cute Blossom",
  "Cute Dendelion",
  "Cute Verbena",
  "Cute Almond",
  "Nice Flower",
  "Nice Cloud",
  "Nice Vanilla",
  "Nice Seed",
  "Nice Leaf",
  "Nice Cat",
  "Nice Bird",
  "Nice Cherry",
  "Nice Violet",
  "Nice Basil",
  "Nice Fruit",
  "Nice Tree",
  "Nice Lavender",
  "Nice Rose",
  "Nice Bee",
  "Nice Honey",
  "Nice Bicycle",
  "Nice Blossom",
  "Nice Dendelion",
  "Nice Verbena",
  "Nice Almond",
  "Pretty Flower",
  "Pretty Cloud",
  "Pretty Vanilla",
  "Pretty Seed",
  "Pretty Leaf",
  "Pretty Cat",
  "Pretty Bird",
  "Pretty Cherry",
  "Pretty Violet",
  "Pretty Basil",
  "Pretty Fruit",
  "Pretty Tree",
  "Pretty Lavender",
  "Pretty Rose",
  "Pretty Bee",
  "Pretty Honey",
  "Pretty Bicycle",
  "Pretty Blossom",
  "Pretty Dendelion",
  "Pretty Verbena",
  "Pretty Almond",
  "Mini Flower",
  "Mini Cloud",
  "Mini Vanilla",
  "Mini Seed",
  "Mini Leaf",
  "Mini Cat",
  "Mini Bird",
  "Mini Cherry",
  "Mini Violet",
  "Mini Basil",
  "Mini Fruit",
  "Mini Tree",
  "Mini Lavender",
  "Mini Rose",
  "Mini Bee",
  "Mini Honey",
  "Mini Bicycle",
  "Mini Blossom",
  "Mini Dendelion",
  "Mini Verbena",
  "Mini Almond",
  "Navy Flower",
  "Navy Cloud",
  "Navy Vanilla",
  "Navy Seed",
  "Navy Leaf",
  "Navy Cat",
  "Navy Bird",
  "Navy Cherry",
  "Navy Violet",
  "Navy Basil",
  "Navy Fruit",
  "Navy Tree",
  "Navy Lavender",
  "Navy Rose",
  "Navy Bee",
  "Navy Honey",
  "Navy Bicycle",
  "Navy Blossom",
  "Navy Dendelion",
  "Navy Verbena",
  "Navy Almond",
  "Turquoise Flower",
  "Turquoise Cloud",
  "Turquoise Vanilla",
  "Turquoise Seed",
  "Turquoise Leaf",
  "Turquoise Cat",
  "Turquoise Bird",
  "Turquoise Cherry",
  "Turquoise Violet",
  "Turquoise Basil",
  "Turquoise Fruit",
  "Turquoise Tree",
  "Turquoise Lavender",
  "Turquoise Rose",
  "Turquoise Bee",
  "Turquoise Honey",
  "Turquoise Bicycle",
  "Turquoise Blossom",
  "Turquoise Dendelion",
  "Turquoise Verbena",
  "Turquoise Almond",
  "Sweet Flower",
  "Sweet Cloud",
  "Sweet Vanilla",
  "Sweet Seed",
  "Sweet Leaf",
  "Sweet Cat",
  "Sweet Bird",
  "Sweet Cherry",
  "Sweet Violet",
  "Sweet Basil",
  "Sweet Fruit",
  "Sweet Tree",
  "Sweet Lavender",
  "Sweet Rose",
  "Sweet Bee",
  "Sweet Honey",
  "Sweet Bicycle",
  "Sweet Blossom",
  "Sweet Dendelion",
  "Sweet Verbena",
  "Sweet Almond",
  "Light Flower",
  "Light Cloud",
  "Light Vanilla",
  "Light Seed",
  "Light Leaf",
  "Light Cat",
  "Light Bird",
  "Light Cherry",
  "Light Violet",
  "Light Basil",
  "Light Fruit",
  "Light Tree",
  "Light Lavender",
  "Light Rose",
  "Light Bee",
  "Light Honey",
  "Light Bicycle",
  "Light Blossom",
  "Light Dendelion",
  "Light Verbena",
  "Light Almond",
  "Small Flower",
  "Small Cloud",
  "Small Vanilla",
  "Small Seed",
  "Small Leaf",
  "Small Cat",
  "Small Bird",
  "Small Cherry",
  "Small Violet",
  "Small Basil",
  "Small Fruit",
  "Small Tree",
  "Small Lavender",
  "Small Rose",
  "Small Bee",
  "Small Honey",
  "Small Bicycle",
  "Small Blossom",
  "Small Dendelion",
  "Small Verbena",
  "Small Almond",
  "Golden Flower",
  "Golden Cloud",
  "Golden Vanilla",
  "Golden Seed",
  "Golden Leaf",
  "Golden Cat",
  "Golden Bird",
  "Golden Cherry",
  "Golden Violet",
  "Golden Basil",
  "Golden Fruit",
  "Golden Tree",
  "Golden Lavender",
  "Golden Rose",
  "Golden Bee",
  "Golden Honey",
  "Golden Bicycle",
  "Golden Blossom",
  "Golden Dendelion",
  "Golden Verbena",
  "Golden Almond",
  "Coral Flower",
  "Coral Cloud",
  "Coral Vanilla",
  "Coral Seed",
  "Coral Leaf",
  "Coral Cat",
  "Coral Bird",
  "Coral Cherry",
  "Coral Violet",
  "Coral Basil",
  "Coral Fruit",
  "Coral Tree",
  "Coral Lavender",
  "Coral Rose",
  "Coral Bee",
  "Coral Honey",
  "Coral Bicycle",
  "Coral Blossom",
  "Coral Dendelion",
  "Coral Verbena",
  "Coral Almond",
  "Soft Flower",
  "Soft Cloud",
  "Soft Vanilla",
  "Soft Seed",
  "Soft Leaf",
  "Soft Cat",
  "Soft Bird",
  "Soft Cherry",
  "Soft Violet",
  "Soft Basil",
  "Soft Fruit",
  "Soft Tree",
  "Soft Lavender",
  "Soft Rose",
  "Soft Bee",
  "Soft Honey",
  "Soft Bicycle",
  "Soft Blossom",
  "Soft Dendelion",
  "Soft - Verbena",
  "Soft - Almond"
];
