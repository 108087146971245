export default {
  color:
    'Color your winter with the illustrated fresco by OMY! Travel around the animated map for a chance to discover an exclusive offer in the drawing.',
  btnStart: 'Start coloring',
  avatar: 'Your OMY avatar is...',
  instructionTitle: 'Instructions',
  instruction: 'Browse the fresco, choose a blank area and tap to color it',
  usersColorize: 'users are now coloring this fresco.',
  instructionColorize: `Choose a color and fill in your selected area.
  Complete the entire area to discover what you've won.`,
  sorry: `Sorry,
no promo code was hidden in this area.`,
  eshop: 'Use it on the eshop.',
  discoverMore: 'Discover more',
  win: `Congratulations -
you've won an exclusive offer!
Shop on the L'OCCITANE site to redeem your FREE gift.*`,
  tryAgain: 'Please try again!',
  close: 'Close',
  discover: 'DISCOVER',
  discoverUrl: '',
  howTo: 'HOW TO USE THIS PROMO CODE ?',
  push: "Discover L'OCCITANE's limited edition Shea x OMY Collection!",
  rangeURL: '',
  colorAll:
    'Once you choose a color, keep the same to color the entire element.',
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText: `Come color the online L'Occitane fresco by OMY, 
  and get a chance to win promo code on the 
  shea collection. Get your pencils ready! 
  #provenceeverywhere`,
  globalTWText: `LET'S COLOR TOGETHER
  Come color the online L'Occitane fresco by OMY, 
and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#provenceeverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText: `It's your turn to color the L'Occitane online fresco
by OMY and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#ProvenceEverywhere`,
  localTWText: `LET'S COLOR TOGETHER
It's your turn to color the L'Occitane online fresco
by OMY and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#ProvenceEverywhere`,
  frescoPourcent: '',
  tc: '',
  tcTitle: 'Terms and Conditions ',
  frescoCompleted: 'Fresco started $ hours ago',
  ie:
    'This browser is not compatible with our animation, please use a newer one. ',
  continue: '',
  eye: '',
  howToURL: 'https://it.loccitane.com/offerta-colorata,80,2,97763,1465875.htm'
}
