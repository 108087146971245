export default {
  color:
    'Color the illustrated fresco by OMY and get a chance  to find promo codes in the drawing.',
  btnStart: 'Start coloring',
  avatar: 'Your OMY avatar is...',
  instructionTitle: 'Instructions',
  instruction: 'Browse the fresco, choose an area and tap to color it',
  usersColorize: ' users are now coloring this illustration.',
  instructionColorize: `Choose the color of your pencil and color the whole selected area.
Fill the entire area to validate the coloring and discover if you have won something.`,
  sorry: `Oops! No promo code was hidden in this area.`,
  eshop: "Shop on the L'OCCITANE site to redeem your FREE gift.*",
  discoverMore: 'Discover more',
  win: `Congratulations! 
Redeem you promo code on our website now.`,
  tryAgain: ' How about you try again!',
  close: 'Close',
  discover: ' Discover all the completed illustrations.',
  discoverUrl: '',
  howTo: 'HOW TO USE THIS PROMO CODE ?',
  push: "Discover L'OCCITANE's limited edition Shea x OMY Collection!",
  rangeURL:
    'https://my.loccitane.com/shea-butter-body-hand-care-collection,42,1,85827,1069937.htm',
  colorAll:
    'Once you choose a color, keep the same to color the entire element.',
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText: `Come color the online L'Occitane fresco by OMY, 
  and get a chance to win promo code on the 
  shea collection. Get your pencils ready! 
  #provenceeverywhere`,
  globalTWText: `LET'S COLOR TOGETHER
  Come color the online L'Occitane fresco by OMY, 
and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#provenceeverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText: `It's your turn to color the L'Occitane online fresco
by OMY and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#ProvenceEverywhere`,
  localTWText: `LET'S COLOR TOGETHER
It's your turn to color the L'Occitane online fresco
by OMY and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#ProvenceEverywhere`,
  frescoPourcent: `This shows the percentage of this illustration's completion. 
Curious to know what this finished illustrated Fresco looks like? Check out our Instagram account: @loccitanemy`,
  tc:
    'https://my.loccitane.com/let-s-color-together-game-rules,42,1,38193,1472129.htm',
  tcTitle: 'Terms and Conditions ',
  frescoCompleted: 'Fresco started X hours ago',
  ie:
    'This browser is not compatible with our animation, please use a newer one.',
  continue: 'Please continue to color to find the code!',
  eye: '',
  howToURL:
    'https://my.loccitane.com/let-s-color-together-game-rules,42,1,38193,1472129.htm'
}
