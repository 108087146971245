export default {
  color: '色を塗ってクーポンコードをGetしよう',
  btnStart: '好きな色を塗ってみよう！',
  avatar: 'あなたのキャラクターは..',
  instructionTitle: 'ゲームの説明',
  instruction: '好きなエリアを選択＆タップして色をつけよう',
  usersColorize: '～人がこの塗り絵に参加しています',
  instructionColorize: `好きな色を選んで、選択した場所に色を塗ってみよう。
  全部塗り終わったら、お得なクーポンがもらえるかも♪`,
  sorry: `申し訳ございません。,
クーポンコードが見つけられませんでした。`,
  eshop: 'オンラインショップへ',
  discoverMore: 'もっと見る',
  win: `おめでとうございます！
オンラインショップでご利用いただける送料無料クーポンをプレゼント！`,
  tryAgain: '見つかるまで塗り絵をお楽しみください♪',
  close: '閉じる',
  discover: '完成した塗り絵を見てみましょう',
  discoverUrl: '',
  howTo: 'オンラインショップで送料無料クーポンを使うには？',
  push: 'シア限定製品をもっと見る',
  rangeURL: 'https://jp.loccitane.com/coloryourshea,123,2,68999,1440780.htm',
  colorAll: '色を選んだら、塗り絵に色を塗りましょう',
  globalFBTitle: 'みんなで好きな色を塗りましょう',
  globalFBText: '色を塗ってクーポンコードをGetしよう',
  globalTWText: '色を塗ってクーポンコードをGetしよう',
  localFBTitle: 'みんなで好きな色を塗りましょう',
  localFBText: `さぁ、あなたが色を塗る番です！
お好きな色を塗って、お得なクーポンをゲットするチャンスです。
色鉛筆の準備は出来ましたか？`,
  localTWText: `さぁ、あなたが色を塗る番です！
お好きな色を塗って、お得なクーポンをゲットするチャンスです。
色鉛筆の準備は出来ましたか？`,
  frescoPourcent: `下記の％の塗り絵が塗り終わっています。
完成した塗り絵がどんなものか見てみたい方はこちらのInstagramからご覧いただけます：@loccitane_jp`,
  tc: 'https://jp.loccitane.com/color_rule,123,2,69000,1462896.htm',
  tcTitle: 'ご利用規約',
  frescoCompleted: 'この塗り絵は$日前にスタートしました',
  ie: 'このブラウザでは起動しませんでしたので、他のブラウザをご使用ください。',
  continue: '見つかるまで塗り絵をお楽しみください♪',
  howToURL: 'https://jp.loccitane.com/color_rule,123,2,69000,1462896.htm'
}
