export default [
  "beebip",
  "bling duckling",
  "brocolily",
  "bulldoggo",
  "butterflysofine",
  "cheeky chick",
  "coffeeplease",
  "creamypot",
  "cutie kitty",
  "daddycool",
  "dancingcream",
  "duckiduck",
  "eatyourveggies",
  "fast and curious",
  "flowapawa",
  "gourmetbabe",
  "happygranny",
  "happytube",
  "holyflower",
  "jimini cricket",
  "ladyserum",
  "lilwalkie",
  "lovenderv",
  "magic bus",
  "mikie bikie",
  "missbrocoli",
  "misterlova",
  "momosquito",
  "nerdy boy",
  "papaws",
  "peachyblinder",
  "puppymaster",
  "queen bee",
  "sleepybaby",
  "speedy snail",
  "tomatoloco",
  "tutulip",
  "warmyworm",
  "watersavior",
  "yoga mamma"
];
