export default {
  color:
    'Pobarvajte ilustracijo, ki jo je ustvaril OMY, in se potegujte za promo kode, ki se skrivajo v sliki.',
  btnStart: 'Začnite barvati',
  avatar: 'Vaš avatar je …',
  instructionTitle: 'Navodila',
  instruction:
    'Oglejte si sliko, izberite področje, ki ga želite pobarvati, in tapnite nanj.',
  usersColorize: 'To je število uporabnikov, ki trenutno barvajo ilustracijo.',
  instructionColorize: `Izberite barvo svinčnika in pobarvajte celotno področje.
Pobarvati morate celotno področje, da se ilustracija shrani in vam razkrije, ali se pod njo skriva promo koda.`,
  sorry: `Na tem področju žal ni promo kode.`,
  eshop:
    "Uporabite jo v naši spletni trgovini oziroma v fizičnih trgovinah L'Occitane.",
  discoverMore: 'Odkrijte več',
  win: `Čestitamo! Našli ste promo kodo.`,
  tryAgain: 'Poskusite znova!',
  close: 'Zapri',
  discover: 'Oglejte si vse pobarvane ilustracije.',
  discoverUrl: '',
  howTo: 'Kako uporabiti promo kodo',
  push:
    'Odkrijte kolekcijo s karitejevim maslom v omejeni izdaji L’OCCITANE × OMY.',
  rangeURL: 'https://si.loccitane.com/shea-omy,2,2,97749,1464588.htm',
  colorAll: 'Izbranega področja ne morete pobarvati z več barvami.',
  globalFBTitle: 'BARVAJMO SKUPAJ',
  globalFBText:
    "Pobarvajte ilustracijo L'OCCITANE × OMY in osvojite skrivno kodo za ekskluzivno ugodnost. Pripravite barvice ... Tri, štiri, barvajte! #ProvenceEverywhere",
  globalTWText:
    "Pobarvajte ilustracijo L'OCCITANE × OMY in osvojite skrivno kodo za ekskluzivno ugodnost. Pripravite barvice ... Tri, štiri, barvajte! #ProvenceEverywhere",
  localFBTitle: 'BARVAJMO SKUPAJ',
  localFBText:
    "Zdaj ste vi na vrsti za barvanje ilustracije L'OCCITANE × OMY! Z nekaj sreče boste osvojili skrivno kodo za ekskluzivno ugodnost. Pripravite barvice ... Tri, štiri, barvajte! #ProvenceEverywhere",
  localTWText:
    "Zdaj ste vi na vrsti za barvanje ilustracije L'OCCITANE × OMY! Z nekaj sreče boste osvojili skrivno kodo za ekskluzivno ugodnost. Pripravite barvice ... Tri, štiri, barvajte! #ProvenceEverywhere",
  frescoPourcent: `To je odstotek pobarvane ilustracije.
  Vas zanima, kako je videti pobarvana ilustracija? Oglejte si jo na našem Instagram profilu @loccitane_si.`,
  tc: 'https://si.loccitane.com/omy-pravila,2,2,97796,1470553.htm',
  tcTitle: 'Pravila in splošni pogoji ',
  frescoCompleted: 'Spletna igra se je začela pred $ dnevi.',
  ie: 'Ta brskalnik ni združljiv s to animacijo. Uporabite novejšega.',
  continue: 'Barvajte še naprej, da odkrijete skrivno kodo.',
  eye: '',
  howToURL: 'https://si.loccitane.com/omy-promo-koda,2,2,97812,1471181.htm'
}
