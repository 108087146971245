export default {
  color:
    '시티맵을 색칠하여 도시에 컬러풀한 생기를 더하고, 선물을 받을 수 있는 프로모션 코드를 찾아보세요.',
  btnStart: '컬러링 시작하기',
  avatar: '당신의 아바타를 만나보세요',
  instructionTitle: '사용 가이드',
  instruction: '시티맵를 둘러보며 색칠하고 싶은 부분을 탭하세요',
  usersColorize: '명의 사용자가 지금 시티맵을 칠하고 있습니다',
  instructionColorize: `시티팔레트에서 색연필을 고르고, 원하는 부분을 탭하여 색칠하세요.
시티맵을 색칠하며  숨겨진 프로모션 코드도 찾아보세요.`,
  sorry: `여기에는 프로모션 코드가 없습니다.`,
  eshop: '',
  discoverMore: '자세히 보기',
  win: `축하합니다!
록시땅 공식몰에서 구매시 프로모션 코드를 입력하여 선물을 받아보세요.`,
  tryAgain: '다시 도전해보세요!',
  close: '',
  discover: '',
  discoverUrl: '',
  howTo: '',
  push: '',
  rangeURL:
    'https://fr.loccitane.com/savon-parfume-jasmin-immortelle-neroli,74,1,74731,1213687.htm#s=40168',
  colorAll: '',
  globalFBTitle: '',
  globalFBText: '',
  globalTWText: '',
  localFBTitle: '',
  localFBText: '',
  localTWText: '',
  frescoPourcent: '',
  tc: ''
}
