/** @jsx jsx */
import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import classNames from "classnames";
// import {
// CSSTransition,
// TransitionGroup
// Transition
// } from "react-transition-group";
import SpriteReader from "sprite-reader";

import MouseManager from "../helpers/MouseManager";
import Pencil from "../helpers/Pencil";
// import ColorCircle from "../helpers/ColorCircle";
import PixiApp from "../helpers/PixiApp";
// import AiDraw from "../helpers/AiDraw";
import MainContainer from "../helpers/MainContainer";
import ColorContainer from "../helpers/ColorContainer";
import UIContainer from "../helpers/UIContainer";
import EmitterManager, { COLORIZE } from "../helpers/EmitterManager";
// import FirebaseManager from "../helpers/FirebaseManager";
// import { TweenMax } from "gsap";
import Viewport from "../helpers/Viewport";
import bodymovin from "lottie-web";

// import colorImg from "../images/home/color.png";
import titleImg from "../images/home/title.png";
import infoImg from "../images/fresque/info.png";
import closeImg from "../images/fresque/close.png";
// import homeImg from "../images/fresque/home.png";
import shareImg from "../images/fresque/share.png";
import aboutImg from "../images/fresque/about.png";
import twImg from "../images/fresque/tw.png";
import fbImg from "../images/fresque/fb.png";
import userImg from "../images/fresque/user.png";
import { mq, YELLOW } from "../css/style";
import { TweenMax, Power3, Linear } from "gsap";
import ProgressRing from "./ProgressRing";
import FirebaseManager from "../helpers/FirebaseManager";
import lottieTitle from "../lottie/title-fresque.json";
import lottieTitleIng from "../lottie/title-fresqueing.json";
import colors from "../datas/colors";
import store from "../datas/store";
import country from "../datas/country";
import pop3Img from "../images/home/Large-01MA030K20DTS_CMJN___.png";

import { utils /*, Rectangle, Sprite, Texture*/ } from "pixi.js";

import minusJson from "../datas/minus.json";
import plusJson from "../datas/plus.json";
import closeCircle from "../images/fresque/CTA-CLOSE23.png";
import { getUrlParam, isMobile } from "../utils";

import detectIt from "detect-it";

class Draw extends Component {
  sectionRef = React.createRef();
  plusRef = React.createRef();
  minusRef = React.createRef();

  state = {
    // showColorSelection: false,
    colorSelected: store.color.name,
    isInstructionVisible: true,
    isColorInstructionVisible: true,
    isGammeInstructionVisible: true,
    isOdd: 0,
    isDrawing: false,
    isPopinConnected: false,
    currentZoom: 0,
    isShareVisible: false,
    showPercentPopin: false,
    drawingComplete: false,
  };

  handleResize = () => {
    // this.sectionRef.current.style.height = `${Math.min(window.innerHeight, )}px`
  };

  componentDidMount() {
    TweenMax.killAll();

    window.addEventListener("resize", this.handleResize);

    TweenMax.from(this.sectionRef.current, 1, {
      opacity: 0,
      // onComplete: () => {
      //   this.lottieTitle.play();
      // }
    });

    this.setState({ currentZoom: store.currentZoom });

    this.pencil = new Pencil({
      x: MouseManager.x || 0,
      y: MouseManager.y || 0,
      pointer: true,
      name: store.avatar ? store.avatar.name : "",
    });

    this.pencil.pencilLead.tint = utils.string2hex(store.color.colorSelected);
    if (MouseManager.isTouchOnly) this.pencil.alpha = 0;
    PixiApp.stage.addChild(this.pencil);

    // if (detectIt.deviceType !== "touchOnly") {
    TweenMax.delayedCall(1, () => {
      requestIdleCallback(() => {
        this.lottieTitle = bodymovin.loadAnimation({
          container: this.sectionRef.current.querySelector(".lottie-title"),
          renderer: "canvas",
          loop: false,
          autoplay: true,
          animationData:
            getUrlParam("lang", "us_en") === "uk_en" ||
            getUrlParam("lang", "us_en") === "au_en"
              ? lottieTitleIng
              : lottieTitle,
        });
      });
    });
    // }

    const imgMinus = new Image();
    imgMinus.onload = () => {
      this.spriteReaderMinus = new SpriteReader(imgMinus, minusJson, {
        canvas: this.minusRef.current,
        autoplay: false,
      });
    };
    imgMinus.src = `${process.env.PUBLIC_URL}/images/minus.png`;

    const imgPlus = new Image();
    imgPlus.onload = () => {
      this.spriteReaderPlus = new SpriteReader(imgPlus, plusJson, {
        canvas: this.plusRef.current,
        autoplay: false,
      });
    };
    imgPlus.src = `${process.env.PUBLIC_URL}/images/plus.png`;

    PixiApp.ticker.add(() => {
      if (this.spriteReaderMinus) this.spriteReaderMinus.update();
      if (this.spriteReaderPlus) this.spriteReaderPlus.update();
    });

    MainContainer.interactive = true;
    Viewport.enableInteractive();
    this.handleColorChanged();
    this.closebtnEvent();
    this.showColorSelection();
    EmitterManager.emit(COLORIZE);

    EmitterManager.on("DRAG_ON", this.handleDrag);
    EmitterManager.on("DRAG_OFF", this.handleDragOff);
    EmitterManager.on("DRAWING_ON", this.handleDrawing);
    EmitterManager.on("DRAWING_COMPLETE", this.handleDrawingComplete);
    EmitterManager.on("DRAWING_OFF", this.handleDrawingOff);
    EmitterManager.on("ZOOM:END", (currentZoom) => {
      // console.log('ccc', currentZoom)
      this.setState({ currentZoom: currentZoom });
    });
  }

  get isTouchOnly() {
    return detectIt.primaryInput === "touch";
  }

  handleDrag = () => {
    this.setState({
      isDraging: true,
    });
  };

  handleDragOff = () => {
    this.setState({
      isDraging: false,
    });
  };
  test = () => {
    // console.log('rogger that')
  };

  handleDrawing = () => {
    this.setState({
      isInstructionVisible: false,
      isDrawing: true,
    });
  };

  handleDrawingComplete = () => {
    this.setState({
      drawingComplete: true,
    });
  };

  handleDrawingOff = () => {
    this.setState((state) => {
      return {
        isDrawing: false,
        isColorInstructionVisible: false,
        isOdd: state.isOdd + 1,
        isGammeInstructionVisible: true,
        drawingComplete: false,
      };
    });
  };

  getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  closebtnEvent = () => {
    this.closeDrawModeBtn = UIContainer.getChildByName("closeSprite");

    this.closeDrawModeBtn.on("pointertap", () => {
      if (Viewport.justClicked) return;

      MainContainer.handleEndDraw();
      MainContainer.hideBackground();
      // Viewport.enableInteractive();
      this.handleDrawingOff();
      if (!this.isTouchOnly) {
        TweenMax.to(this.pencil, 0.2, { alpha: 1, ease: Power3.easeIn });
      }
    });

    this.closeDrawModeBtn.on("mouseover", () => {
      TweenMax.to(this.pencil, 0.2, { alpha: 0, ease: Power3.easeInOut });
    });

    this.closeDrawModeBtn.on("mouseout", () => {
      TweenMax.to(this.pencil, 0.2, { alpha: 1, ease: Power3.easeIn });
    });
  };

  showColorSelection = () => {
    EmitterManager.on("SHOW:COLORSELECTION", () => {
      ColorContainer.visible = true;

      this.closeDrawModeBtn.visible = true;

      TweenMax.to(ColorContainer, 0.6, {
        delay: 0.6,
        alpha: 1,
        ease: Linear.easeNone,
      });
      TweenMax.to(this.closeDrawModeBtn, 0.6, {
        delay: 0.6,
        alpha: 1,
        ease: Linear.easeNone,
      });

      ColorContainer.parent.setChildIndex(
        ColorContainer,
        ColorContainer.parent.children.length - 1
      );

      // this.setState({ showColorSelection: true });
    });

    EmitterManager.on("HIDE:COLORSELECTION", () => {
      TweenMax.to(ColorContainer, 0.4, {
        alpha: 0,
        onComplete: () => {
          ColorContainer.visible = false;
        },
      });

      TweenMax.to(this.closeDrawModeBtn, 0.4, {
        alpha: 0,
        ease: Power3.easeInOut,
        onComplete: () => {
          this.closeDrawModeBtn.visible = false;
        },
      });

      // this.setState({ showColorSelection: false });
    });
  };

  handleColorChanged = () => {
    EmitterManager.on("COLOR:CHANGED", (color) => {
      TweenMax.to(this.pencil.scale, 0.15, {
        x: 0,
        y: 0,
        ease: Power3.easeInOut,
        onComplete: () => {
          this.pencil.pencilLead.tint = utils.string2hex(color);
          this.pencil.x = MouseManager.x + 300;
          this.pencil.y = MouseManager.y - 300;
        },
      });
      TweenMax.to(this.pencil.scale, 0.15, {
        delay: 0.15,
        x: 1,
        y: 1,
        ease: Power3.easeInOut,
      });
    });
  };

  handlecolorButtonClick = (color) => {
    this.setState({ colorSelected: color });
    store.color.name = color;
    store.color.colorSelected = colors[color];
    EmitterManager.emit("COLOR:CHANGED");
    this.pencil.pencilLead.tint = utils.string2hex(store.color.colorSelected);
  };

  handleGlobalShare = () => {
    this.setState({
      isShareVisible: true,
    });
  };

  handleGlobalShareLeave = () => {
    this.setState({
      isShareVisible: false,
    });
  };

  handleGlobalShareTw = () => {
    const w = 600;
    const h = 300;

    window.gtag("event", "shareGlobalTw");

    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        this.props.lang.globalTWText
      )}&url=${`https://loccitane-omy-experience.com/sharingPage/${getUrlParam(
        "lang",
        "us_en"
      )}/n/${document.referrer}`}`,
      "twitter",
      `width=${w},height=${h},top=${(window.innerHeight - h) / 2},left=${
        (window.innerWidth - w) / 2
      }`
    );
  };

  handleGlobalShareFb = () => {
    window.FB.ui(
      {
        method: "share",
        href: `https://loccitane-omy-experience.com/sharingPage/${getUrlParam(
          "lang",
          "us_en"
        )}/n/${document.referrer}`,
      },
      function (response) {
        window.gtag("event", "shareGlobalFB");
      }
    );
  };

  handleGlobalShareInsta = () => {
    // const a = document.createElement("a");
    // a.setAttribute("download", "insta.png");
    // a.setAttribute("target", "blank");

    // a.href = `${process.env.PUBLIC_URL}/images/istg.jpg`;
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);

    window.gtag("event", "shareGlobalInsta");
  };

  removeInfoPercent = () => {
    window.removeEventListener("touchstart", this.removeInfoPercent);

    this.setState({
      showPercentPopin: false,
    });
  };

  render() {
    let users = [];

    const connectedUsers = this.getUnique(this.props.connectedUsers, "userId");
    // console.log(connectedUsers);

    const total = 2;
    const countryParam = getUrlParam("lang", "us_en").split("_")[0];
    // console.log(countryParam)
    for (let i = 0; i < total; i++) {
      users.push(
        // <CSSTransition
        //   classNames="item"
        //   key={connectedUsers[i] ? connectedUsers[i].userId : i}
        //   timeout={500}
        // >
        <div
          key={i}
          css={userRoundClassFn({
            img:
              i !== 0
                ? store.avatar
                  ? store.avatar.svg
                  : store.avatarTab.find((item) => {
                      return item.name === connectedUsers[i - 1].userName;
                    }).svg
                : "",
          })}
          className={classNames({
            first: i === 0,
            picto: i === 1,
          })}
          style={{ zIndex: 8 - i }}
          onClick={() => {
            EmitterManager.emit("ALLOW_SCROLL", true);
            this.setState({
              isPopinConnected: true,
            });
            Viewport.disableInteractive();
          }}
        >
          {i === 0 && <span>{connectedUsers.length + 1}</span>}
          {i === 1 && (
            <div
              className={`flag-icon-background flag-icon-${
                countryParam === "uk"
                  ? "gb-eng"
                  : countryParam === "me"
                  ? "sa"
                  : countryParam
              }`}
            ></div>
          )}
        </div>
        // </CSSTransition>
      );
    }

    return (
      <section
        ref={this.sectionRef}
        css={containerClass}
        className={classNames({ "is-hidden": this.state.isDrawing })}
      >
        <div
          css={headerClass}
          className={classNames({
            "is-out": this.state.isDraging && !MainContainer.productClicked,
          })}
        >
          {/* <img src={homeImg} alt="" css={homeClass} /> */}
          <div
            css={logoClassFn({
              isMobile: detectIt.primaryInput === "touch",
            })}
            // onClick={() => {
            //document.location.reload();
            // window.open(this.props.lang.rangeURL);
            // }}
            className="lottie-title no-drag"
          ></div>
          <img
            src={titleImg}
            alt=""
            css={titleClassFn({
              isMobile: detectIt.primaryInput === "touch",
            })}
          />
        </div>
        <div
          onMouseEnter={this.handleGlobalShare}
          onMouseLeave={this.handleGlobalShareLeave}
          onClick={() => {
            if (this.isTouchOnly) {
              if (this.state.isShareVisible) this.handleGlobalShareLeave();
              else this.handleGlobalShare();
            }
          }}
        >
          <img
            src={shareImg}
            alt=""
            css={shareClass}
            className={classNames({
              "is-padded": this.state.isShareVisible,
              "no-drag": true,
            })}
          />
          <a href={this.props.lang.tc} target="blank">
            <img src={aboutImg} alt="" css={[shareClass, aboutClass]} />
          </a>
          <div
            css={shareHandlerClass}
            className={classNames({
              "is-visible": this.state.isShareVisible,
            })}
          >
            <img
              src={twImg}
              alt=""
              css={shareClass}
              className="no-drag tw"
              onClick={this.handleGlobalShareTw}
            />
            <img
              src={fbImg}
              alt=""
              css={shareClass}
              className="no-drag fb"
              onClick={this.handleGlobalShareFb}
            />
            {/* <a
              href={`${process.env.PUBLIC_URL}/images/istg.jpg`}
              target="blank"
              download
            >
              <img
                src={instaImg}
                alt=""
                css={shareClass}
                className="no-drag insta"
                onClick={this.handleGlobalShareInsta}
              />
            </a> */}
          </div>
        </div>
        <div css={usersClass}>{users}</div>
        <div css={zoomClass}>
          <canvas
            width="150"
            height="180"
            css={zoomButton("plus", this.state.currentZoom)}
            className="no-drag"
            ref={this.plusRef}
            onMouseEnter={() => {
              this.spriteReaderPlus.goFromTo(0, 18);
              this.spriteReaderPlus.reverse(true);
              this.spriteReaderPlus.play();
            }}
            onMouseLeave={() => {
              this.spriteReaderPlus.reverse();
              this.spriteReaderPlus.play();
            }}
            onClick={() => {
              this.spriteReaderPlus.goFromTo(
                19,
                this.spriteReaderPlus.total - 1
              );
              this.spriteReaderPlus.reverse(true);
              this.spriteReaderPlus.play();
              Viewport.zoomOncenterButton(0.15);
            }}
          ></canvas>
          <canvas
            width="150"
            height="180"
            css={zoomButton("minus", this.state.currentZoom)}
            className="no-drag"
            ref={this.minusRef}
            onMouseEnter={() => {
              this.spriteReaderMinus.goFromTo(0, 18);
              this.spriteReaderMinus.reverse(true);
              this.spriteReaderMinus.play();
            }}
            onMouseLeave={() => {
              this.spriteReaderMinus.reverse();
              this.spriteReaderMinus.play();
            }}
            onClick={() => {
              this.spriteReaderMinus.goFromTo(
                19,
                this.spriteReaderMinus.total - 1
              );
              this.spriteReaderMinus.reverse(true);
              this.spriteReaderMinus.play();
              Viewport.zoomOncenterButton(-0.15);
            }}
          ></canvas>
        </div>
        <a href={this.props.lang.tc} target="blank" css={termColorClass}>
          {this.props.lang.tcTitle}
        </a>
        <p
          css={colorInstructionClass}
          className={classNames({
            "is-visible": this.state.isDrawing && !this.state.drawingComplete,
          })}
          style={{
            bottom:
              detectIt.primaryInput === "touch"
                ? 60 + "px"
                : (window.innerWidth * 0.036553525) / 2 < 35
                ? 35 * 4 + "px"
                : ((window.innerWidth * 0.036553525) / 2) * 4 + "px",
          }}
        >
          {this.props.lang.colorAll}
        </p>
        {this.state.isInstructionVisible && (
          <div className="no-drag" css={instructionClass}>
            <h3>{this.props.lang.instructionTitle}</h3>
            <p>{this.props.lang.instruction}</p>
            <img
              src={closeImg}
              alt=""
              css={closeInstructionClass}
              className="no-drag"
              onClick={() => {
                this.setState({ isInstructionVisible: false });
              }}
            />
          </div>
        )}
        {this.state.isColorInstructionVisible && this.state.isDrawing && (
          <div className="no-drag" css={instructionClass}>
            <h3>{this.props.lang.instructionTitle}</h3>
            <p>{this.props.lang.instructionColorize}</p>
            {/* <p className="small">
              Fill the entire area to validate the coloring and discover if you
              have won something.
            </p> */}
            <img
              src={closeImg}
              alt=""
              css={closeInstructionClass}
              className="no-drag"
              onClick={() => {
                this.setState({ isColorInstructionVisible: false });
              }}
            />
          </div>
        )}
        {this.state.isGammeInstructionVisible &&
          this.state.isOdd % 3 === 0 &&
          this.state.isOdd !== 0 && (
            <div className="no-drag" css={gammeClass}>
              <a href={this.props.lang.rangeURL} target="blank" alt="">
                <p>{this.props.lang.push}</p>
                <img src={pop3Img} alt="" css={gammeProductClass} />
              </a>
              <img
                src={closeImg}
                alt=""
                css={closeInstructionClass}
                onClick={() => {
                  this.setState({ isGammeInstructionVisible: false });
                }}
              />
            </div>
          )}
        <div css={percentClass} className="no-drag">
          <div
            onClick={() => {
              if (detectIt.primaryInput === "touch" && !this.state.isDrawing) {
                this.setState((state) => {
                  return {
                    showPercentPopin: true,
                  };
                });

                window.addEventListener("touchstart", this.removeInfoPercent);
              }
            }}
            onMouseEnter={() => {
              clearTimeout(this.timerPopinPercent);
              this.setState({ showPercentPopin: true });
            }}
            onMouseLeave={() => {
              this.timerPopinPercent = setTimeout(() => {
                this.setState({ showPercentPopin: false });
              }, 1500);
            }}
          >
            <ProgressRing
              radius={36}
              stroke={4}
              progress={FirebaseManager.data.percent}
              color={YELLOW}
            ></ProgressRing>
            <div>
              {(FirebaseManager.data && FirebaseManager.data.percent) || 0}
              <span>%</span>
            </div>
          </div>
          <div>
            <p>{FirebaseManager.data && FirebaseManager.data.name}</p>
            {/* <p>
              {this.props.lang.frescoCompleted &&
                this.props.lang.frescoCompleted.replace(
                  /X|\$/g,
                  Math.floor(
                    (Date.now() - FirebaseManager.data.timestamp) /
                      1000 /
                      60 /
                      60 /
                      24
                  )
                )}
            </p> */}
          </div>
        </div>
        <div
          css={popinPercentInfoClass}
          className={classNames({ "is-visible": this.state.showPercentPopin })}
          onMouseEnter={() => {
            clearTimeout(this.timerPopinPercent);
          }}
          onMouseLeave={() => {
            this.timerPopinPercent = setTimeout(() => {
              this.setState({ showPercentPopin: false });
            }, 1500);
          }}
        >
          {this.props.lang.frescoPourcent}
        </div>

        {this.state.isPopinConnected && (
          <div css={popinConnected} className="no-drag">
            <img
              src={closeCircle}
              alt=""
              className="close"
              onClick={() => {
                EmitterManager.emit("ALLOW_SCROLL", false);
                this.setState({ isPopinConnected: false });
                Viewport.enableInteractive();
              }}
            />
            <p>
              {`${connectedUsers.length + 1} ${this.props.lang.usersColorize}`}
            </p>
            <ul className="center">
              <li>
                <div
                  css={avatarClassFn({ background: store.avatar.svg })}
                ></div>
                <p>
                  <span className="bold">{store.avatar.name}</span>
                  <br />
                  {country[getUrlParam("lang", "us_en").split("_")[0]]}
                </p>
                <div
                  className={`flag-icon-background flag-icon-${
                    countryParam === "uk"
                      ? "gb-eng"
                      : countryParam === "me"
                      ? "sa"
                      : countryParam
                  }`}
                ></div>
              </li>
              {connectedUsers.map((el) => {
                return (
                  <li key={el.userId}>
                    <div
                      css={avatarClassFn({
                        background: store.avatarTab.find((item) => {
                          // console.log(item.name, el);
                          return item.name === el.userName;
                        }).svg,
                      })}
                    ></div>
                    <p>
                      <span className="bold">{el.userName}</span>
                      <br />
                      {country[el.userCountry]}
                    </p>
                    <div
                      className={`flag-icon-background flag-icon-${
                        el.userCountry === "uk"
                          ? "gb-eng"
                          : el.userCountry === "me"
                          ? "sa"
                          : el.userCountry
                      }`}
                    ></div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </section>
    );
  }
}

const termColorClass = css`
  bottom: 20px;
  color: black !important;
  display: none;
  font-weight: bold;
  opacity: 0.4;
  pointer-events: all;
  position: absolute;
  right: 20px;
  text-transform: uppercase;

  ${mq.medium(css`
    display: initial;
  `)};
`;

const popinPercentInfoClass = css`
  background-color: white;
  border-radius: 50px;
  bottom: 90px;
  font-size: 12px;
  left: 10px;
  opacity: 0;
  padding: 2em;
  pointer-events: all;
  position: absolute;
  transition: opacity 0.3s linear, visibility 0.3s linear;
  visibility: hidden;
  width: 80%;

  ${mq.medium(css`
    bottom: 110px;
    left: 30px;
    width: initial;
  `)};

  &.is-visible {
    opacity: 1;
    visibility: visible;
  }

  a {
    font-weight: bold;
  }
`;

const colorInstructionClass = css`
  /* bottom: 60px; */
  color: ${YELLOW};
  font-size: 13px;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  padding: 0 5em;
  position: absolute;
  right: 0;
  text-align: center;
  transition: opacity 0.3s 0.5s linear, visibility 0.3s 0.5s linear;
  visibility: hidden;
  width: 100%;

  ${mq.medium(css`
    /* bottom: 180px; */
    font-size: 20px;
  `)};

  &.is-visible {
    opacity: 1;
    transition: opacity 0.3s 1s linear, visibility 0.3s 1s linear;
    visibility: visible;
  }
`;

const instructionClass = css`
  background-color: ${YELLOW};
  bottom: 20px;
  font-size: 13px;
  left: 20px;
  padding: 0.75em;
  pointer-events: all;
  position: absolute;
  width: 65%;
  z-index: 12;

  ${mq.medium(css`
    font-size: 17px;
    padding: 1.5em;
    width: 500px;
  `)};

  .small {
    font-size: 10px;
    text-transform: uppercase;
  }

  p  {
    text-align: center;

    ${mq.medium(css`
      text-align: left;
    `)};
  }

  h3 {
    color: white;
    font-size: 14px;
    text-transform: uppercase;

    &::before {
      background-image: url(${infoImg});
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      display: inline-block;
      height: 23px;
      margin-right: 0.5em;
      vertical-align: middle;
      width: 23px;
    }
  }
`;

const gammeClass = css`
  align-items: center;
  background-color: white;
  bottom: 0;
  display: flex;
  height: 100px;
  left: 0;
  padding: 1.5em;
  pointer-events: all;
  position: absolute;
  width: 100%;
  z-index: 1;

  ${mq.medium(css`
    bottom: 20px;
    height: 190px;
    left: 20px;
    padding: 2em;
    width: 430px;
  `)};

  p {
    font-size: 14px;
    padding-left: 140px;
    white-space: pre-line;

    ${mq.medium(css`
      font-size: 18px;
      padding-left: 160px;
    `)};
  }
`;

const gammeProductClass = css`
  left: -120px;
  position: absolute;
  transform: translate(0px, 90px) rotate(6deg);
  top: -160px;
  width: 400px;

  ${mq.medium(css`
    left: -70px;
    top: -90px;
    transform: translate(10px, 50px) rotate(6deg);
    width: 290px;
  `)};
`;

const closeInstructionClass = css`
  cursor: pointer;
  height: 20px;
  pointer-events: all;
  position: absolute;
  right: 1em;
  top: 1em;
`;

const containerClass = css`
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  /* transition: transform 0.5s linear, opacity 0.5s linear, visibility 0.5s linear; */
  width: 100%;

  /* &.is-hidden { */
  /* opacity: 0; */
  /* transform: scale(1.5); */
  /* visibility: hidden; */
  /* } */

  * {
    user-select: none;
  }
`;

const headerClass = css`
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 5px;
  transition: transform 0.07s linear, opacity 0.07s linear 3s,
    visibility 0.07s linear;

  ${mq.medium(css`
    top: 25px;
  `)};

  ${mq.mHeight(css`
    top: -15px;
  `)};

  &.is-out {
    /* transform: scale(0); */
    opacity: 0;
    pointer-events: none;
    transition: transform 0.07s linear, opacity 0.07s linear,
      visibility 0.07s linear;
    visibility: hidden;
  }

  .is-hidden & {
    opacity: 0;
    visibility: hidden;
  }
`;

const logoClassFn = ({ isMobile } = {}) => css`
  display: block;
  margin: 0 auto;
  pointer-events: all;
  width: 70%;

  ${mq.medium(css`
    height: 170px;
    width: 310px;
  `)};

  .pe-none & {
    pointer-events: none;
  }
`;

const titleClassFn = ({ isMobile } = {}) => css`
  display: block;
  margin: 0 auto;
  width: 50%;

  ${isMobile &&
  css`
    display: none !important;
  `};

  ${mq.medium(css`
    margin-top: -20px;
    width: 180px;
  `)};
`;

// const homeClass = css`
//   cursor: pointer;
//   left: 50%;
//   margin-left: -44vw;
//   /* pointer-events: all; */
//   position: absolute;
//   top: 30px;
//   width: 40px;

//   ${mq.medium(css`
//     margin-left: -220px;
//     top: 40px;
//   `)};
// `;

const shareHandlerClass = css`
  visibility: hidden;

  &.is-visible {
    visibility: visible;
  }
`;

const aboutClass = css`
  left: 20px !important;
  right: initial;

  ${mq.medium(css`
    display: none !important;
  `)};
`;

const shareClass = css`
  cursor: pointer;
  pointer-events: all;
  position: absolute;
  right: 20px;
  top: 30px;
  width: 30px;

  ${mq.medium(css`
    right: 30px;
    width: 50px;
  `)};

  &.is-padded {
    padding-bottom: 30px;
  }

  &.fb {
    height: 72px;
    right: 60px;
    top: 27px;
    width: 58px;

    ${mq.medium(css`
      right: 80px;
      top: 37px;
    `)};
  }

  &.tw {
    height: 72px;
    right: 60px;
    top: 80px;
    width: 58px;
    z-index: 1;

    ${mq.medium(css`
      right: 70px;
    `)};
  }

  &.insta {
    height: 72px;
    right: 25px;
    top: 90px;
    width: 58px;
  }

  /* &.insta {
  right: 20px;
  top: 30px;
  }

  &.tw {
  right: 20px;
  top: 30px;
  } */

  .is-hidden & {
    opacity: 0;
    visibility: hidden;
  }
`;

const usersClass = css`
  cursor: pointer;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);

  ${mq.medium(css`
    left: 30px;
  `)};

  .is-hidden & {
    opacity: 0;
    visibility: hidden;
  }
`;

const userRoundClassFn = ({ img = "" } = {}) => css`
  background-color: white;
  border: 4px solid ${YELLOW};
  border-radius: 50%;
  height: 60px;
  margin-bottom: -35px;
  pointer-events: all;
  position: relative;
  width: 60px;

  &.first {
    background-color: ${YELLOW};
    background-image: url(${userImg});
    background-position: top 3px center;
    background-repeat: no-repeat;
    background-size: 45%;

    span {
      font-size: 15px;
      font-weight: bold;
      left: 50%;
      position: absolute;
      top: 80%;
      transform: translate(-50%, -50%);
    }

    &.item-enter {
      opacity: 0;
    }

    &.item-enter-active {
      opacity: 1;
      transition: opacity 500ms ease-in;
    }

    &.item-exit {
      opacity: 1;
    }

    &.item-exit-active {
      opacity: 0;
      transition: opacity 500ms ease-in;
    }
  }

  &.picto {
    background-image: url(${img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 93%;
    margin-top: 25px;

    > div {
      background-size: cover !important;
      border: 3px solid ${YELLOW};
      border-radius: 50%;
      height: 20px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
      width: 20px;
    }
  }
`;

const zoomClass = css`
  bottom: 50px;
  pointer-events: all;
  position: absolute;
  right: 18px;

  .is-hidden & {
    opacity: 0;
    visibility: hidden;
  }
`;

const zoomButton = (label, currentZoom) => css`
  cursor: pointer;
  display: block;
  height: 90px;
  user-select: none;
  width: 75px;
  ${label === "plus" &&
  currentZoom >= (isMobile() ? 0.69 : 0.89) &&
  `opacity:.5;pointer-events:none;`}
  ${label === "minus" &&
  currentZoom <= (isMobile() ? 0.21 : 0.28) &&
  `opacity:.5;pointer-events:none;`}

  ${label === "minus" &&
  css`
    margin-top: -10px;
    z-index: 1;

    ${mq.medium(css`
      margin-top: 0;
    `)};
  `};

  *,
  *:active,
  *:focus {
    background-color: transparent;
    user-select: none;
    -webkit-tap-highlight-color: transparent !important;
  }

  &:active,
  &:focus {
    background-color: transparent;
    -webkit-tap-highlight-color: transparent !important;
  }

  > div {
    box-shadow: 10px 5px 5px red;
    height: 30px;
    margin-left: 10px;
    margin-top: 10px;
    width: 30px;
  }
`;
const popinConnected = css`
  background-color: rgba(0, 0, 0, 0.8);
  direction: ltr;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: all;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  width: 100%;
  z-index: 1;

  .bold {
    font-weight: bold;
  }

  .close {
    cursor: pointer;
    height: 55px;
    left: 0;
    margin: 0 auto;
    position: fixed;
    right: 0;
    top: 20px;
    width: 55px;
    z-index: 2;
    -webkit-tap-highlight-color: transparent !important;

    ${mq.medium(css`
      height: 73px;
      left: initial;
      right: 50px;
      top: 50px;
      width: 73px;
    `)};
  }

  > p {
    color: ${YELLOW};
    font-size: 15px;
    margin-top: 100px;
    padding: 0 1em;
    position: relative;
    text-align: center;

    ${mq.medium(css`
      font-size: 30px;
      margin-top: 15vh;
    `)};

    &::before {
      background-image: url(${userImg});
      background-size: cover;
      display: block;
      height: 40px;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: -50px;
      width: 40px;

      ${mq.medium(css`
        content: "";
      `)};
    }
  }

  .center {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    margin-top: 3%;
    padding: 0;
    position: absolute;
    transform: translateX(-50%);
    width: 90%;

    ${mq.medium(css`
      width: 60%;
    `)};
  }

  li {
    align-items: center;
    background-color: white;
    border-radius: 45px;
    display: flex;
    height: 90px;
    justify-content: space-between;
    margin: 15px;
    width: 100%;

    ${mq.medium(css`
      width: 400px;
    `)};

    p {
      flex-grow: 1;
      font-size: 15px;
      margin-left: 20px;

      ${mq.medium(css`
        font-size: 20px;
      `)};
    }

    div:last-child {
      background-size: cover;
      border: 4px solid ${YELLOW};
      border-radius: 50%;
      height: 30px;
      margin-right: 20px;
      width: 30px;
      pointer-events: auto;
    }
  }
`;

const avatarClassFn = ({ background }) => css`
  background-image: url(${background});
  background-size: cover;
  border: 4px solid ${YELLOW};
  border-radius: 50%;
  flex-shrink: 0;
  height: 90px;
  width: 90px;
`;

const percentClass = css`
  align-items: center;
  bottom: 10px;
  display: flex;
  pointer-events: all;
  position: absolute;
  left: 10px;

  ${mq.medium(css`
    bottom: 30px;
    left: 30px;
  `)};

  .is-hidden & {
    opacity: 0;
    visibility: hidden;
  }

  > div:first-of-type {
    border: 4px solid ${YELLOW};
    border-radius: 50%;
    height: 70px;
    margin-right: 20px;
    position: relative;
    width: 70px;

    svg {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
    }

    > div:last-child {
      align-items: center;
      border: 4px solid ${YELLOW};
      border-radius: 50%;
      display: flex;
      font-size: 17px;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
    }

    span {
      font-size: 13px;
      font-weight: 100;
    }
  }

  > div:last-child {
    font-size: 16px;
    font-weight: bold;

    ${mq.medium(css`
      font-size: 27px;
    `)};

    p {
      margin: 0;
    }

    p:last-child {
      font-size: 13px;
      font-weight: normal;

      ${mq.medium(css`
        font-size: 16px;
      `)};
    }
  }
`;

export default Draw;
