export default {
  color:
    'Verleihen Sie dem Winter Farbe und malen Sie das Fresko von OMY aus! Mit etwas Glück finden Sie dabei einen Promo Code!',
  btnStart: 'Jetzt malen',
  avatar: 'Ihr OMY Avatar ist:',
  instructionTitle: 'Anleitung',
  instruction:
    'Sehen Sie sich im Fresko um, wählen Sie eine weisse Fläche und klicken Sie darauf, um sie auszumalen.',
  usersColorize: 'Personen malen gerade an diesem Fresko.',
  instructionColorize: `Wählen Sie eine Farbe und malen Sie damit die gewählte Fläche aus, um zu erfahren, ob Sie etwas gewonnen haben`,
  sorry: `Schade!
Hier war kein Promo Code versteckt.`,
  eshop: 'Lösen Sie Ihren Promo Code auf ch.loccitane.com ein!',
  discoverMore: 'Mehr entdecken',
  win: `Glückwunsch! 
Sie haben einen Promo Code gefunden!`,
  tryAgain: 'Versuchen Sie es einfach nochmal!',
  close: 'Schliessen',
  discover: 'ENTDECKEN',
  discoverUrl: '',
  howTo: 'Wie lösen Sie Ihren Promo Code ein?',
  push: "Entdecken Sie die limitierte Shea Edition von OMY for L'OCCITANE!",
  rangeURL: 'https://ch.loccitane.com/l-occitane-x-omy,78,2,97650,1462405.htm',
  colorAll:
    'Wenn Sie eine Farbe gewählt haben, malen Sie damit das gesamte Element aus.',
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText:
    "Verleihen Sie dem Winter Farbe und malen Sie das L'OCCITANE Online-Fresko von OMY aus! Mit etwas Glück finden Sie dabei einen Promo Code für unsere Shea Kollektion! Los geht's! #ProvenceEverywhere",
  globalTWText: `LET’S COLOR TOGETHER
Verleihen Sie dem Winter Farbe und malen Sie das L'OCCITANE Online-Fresko von OMY aus! Mit etwas Glück finden Sie dabei einen Promo Code für unsere Shea Kollektion! Los geht's! #ProvenceEverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText:
    "Nun sind Sie an der Reihe: Verleihen auch Sie dem Winter Farbe und malen Sie das L'OCCITANE Online-Fresko von OMY aus! Mit etwas Glück finden Sie dabei einen Promo Code für unsere Shea Kollektion! Los geht's! #ProvenceEverywhere",
  localTWText: `LET’S COLOR TOGETHER
Nun sind Sie an der Reihe: Verleihen auch Sie dem Winter Farbe und malen Sie das L'OCCITANE Online-Fresko von OMY aus! Mit etwas Glück finden Sie dabei einen Promo Code für unsere Shea Kollektion! Los geht's! #ProvenceEverywhere`,
  frescoPourcent:
    'So weit ist das Fresko bereits fertiggestellt. Neugierig, wie das fertige Fresko aussieht? Besuchen Sie unseren Instagram-Account: @loccitane_ch',
  tc:
    'https://ch.loccitane.com/teilnahmebedingungen-color,78,2,97720,1472397.htm',
  tcTitle: 'Teilnahmebedingungen',
  frescoCompleted: 'Fresko wurde vor $ Tagen begonnen',
  ie:
    'Dieser Browser ist mit unserer Animation nicht kompatibel. Bitte verwenden Sie einen neueren Browser.',
  continue: 'Malen Sie einfach weiter, um einen Code zu finden!',
  eye: '',
  howToURL:
    'https://ch.loccitane.com/angebot-handcreme-mit-stift,78,2,97650,1458652.htm'
}
