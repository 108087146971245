export default {
  color:
    'Finding and settling on a way to use colour within your illustrations is a fun challenge. ',
  btnStart: 'Start colouring',
  avatar: 'Your avatar is...',
  instructionTitle: 'Instructions',
  instruction: 'Browse the fresco, choose a blank area and tap to colour it',
  usersColorize: 'users are now colouring this illustration.',
  instructionColorize: `Select the colour of your pencil and colour the whole selected area. 
  Colour in the entire area to discover whether you have unlocked the secret offer!`,
  sorry: `Oh no.
No promo code was hidden in this area.`,
  eshop: 'Use it on the eshop.',
  discoverMore: 'Discover more',
  win: `Congratulations, 
  you have found the secret offer! To redeem your offer, spend $70 and apply the below promo code at checkout* `,
  tryAgain: 'How about you try again!',
  close: 'Close',
  discover: 'Discover all the completed illustrations.',
  discoverUrl: '',
  howTo: 'CLICK HERE TO DISCOVER YOUR OFFER',
  push: "Discover L'OCCITANE's limited edition Shea x OMY Collection!",
  rangeURL: 'https://au.loccitane.com/omy-shea-collection,23,1,1226,1536818.htm',
  colorAll:
    'Once you choose a colour, keep the same to colour the entire element.',
  globalFBTitle: 'LET’S COLOUR TOGETHER',
  globalFBText: `Come colour the online L'Occitane fresco by OMY, 
and get a chance to win promo code on the 
shea collection. Get your pencils ready! 
#provenceeverywhere`,
  globalTWText: `LET'S COLOUR TOGETHER
Come colour the online L'Occitane fresco by OMY, 
and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#provenceeverywhere`,
  localFBTitle: 'LET’S COLOUR TOGETHER',
  localFBText: `It's your turn to colour the L'Occitane online fresco
by OMY and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#ProvenceEverywhere`,
  localTWText: `LET'S COLOUR TOGETHER
It's your turn to colour the L'Occitane online fresco
by OMY and get a chance to win promo code on the
shea collection. Get your pencils ready! 
#ProvenceEverywhere`,
  frescoPourcent: `This percentage is how much of the fresco is completed.`,
  tc:
    'https://au.loccitane.com/omy-game-terms-and-conditions,23,1,97935,1536544.htm',
  tcTitle: 'Terms and Conditions ',
  frescoCompleted: 'Fresco started X hours ago',
  ie:
    'This browser is not compatible with our animation, please use a newer one.',
  continue: 'Please continue to colour to find the code!',
  eye: '',
  howToURL: 'https://au.loccitane.com/omy-game-offer,23,1,98841,1480355.htm'
}
