export default {
  color:
    'Colour your winter with OMY illustrated fresco! Travel around the animated map for a chance to discover an exclusive offer in the drawing.',
  btnStart: 'Start coloring',
  avatar: 'Your OMY avatar is...',
  instructionTitle: 'Instructions',
  instruction: 'Browse the fresco, choose a blank area and tap to color it',
  usersColorize: 'users are now coloring this illustration.',
  instructionColorize: `Choose a colour and fill in your selected area.
 Complete the entire area to discover what you've won.`,
  sorry: `Oh no.
  No promo code was hidden in this area.`,
  eshop: "Shop on the L'OCCITANE site to redeem your FREE gift.*",
  discoverMore: 'Discover more',
  win: `Congratulations -
you've won an exclusive offer!
Shop online or in-stores to redeem your FREE gift. T&Cs apply.`,
  tryAgain: 'How about you try again!',
  close: 'Close',
  discover: 'DISCOVER',
  discoverUrl: '',
  howTo: 'HOW TO USE THIS PROMO CODE ?',
  push: "Discover L'OCCITANE's limited edition Shea x OMY Collection!",
  rangeURL:
    'https://ae.loccitane.com/collections/new-collections/shea-x-omy?lang=en_AE',
  colorAll: 'choose your desired color to fill up the entire element. ',
  globalFBTitle: 'LET’S COLOR TOGETHER',
  globalFBText: `Come color the online L'Occitane illustrated fresco by OMY, 
  and get a chance to win an exclusive gift . Get your pencils ready! 
  #provenceeverywhere`,
  globalTWText: `LET'S COLOR TOGETHER
  Come color the online L'Occitane illustrated fresco by OMY, 
  and get a chance to win an exclusive gift . Get your pencils ready! 
  #provenceeverywhere`,
  localFBTitle: 'LET’S COLOR TOGETHER',
  localFBText: `It's your turn to color the L'Occitane illustrated online fresco
  by OMY and get a chance to win an exclusive gift. Get your pencils ready! 
  #ProvenceEverywhere`,
  localTWText: `LET'S COLOR TOGETHER
  It's your turn to color the L'Occitane illustrated online fresco
  by OMY and get a chance to win an exclusive gift. Get your pencils ready! 
  #ProvenceEverywhere`,
  frescoPourcent: `This shows the percentage of this illustration's completion. 
Curious to know what this finished illustrated Fresco looks like? Check out our Instagram account: @loccitaneme`,
  tc: 'https://ae.loccitane.com/omyshea-landing-page.html?lang=en_AE',
  tcTitle: 'Terms and Conditions ',
  frescoCompleted: 'Fresco started X hours ago',
  ie:
    'This browser is not compatible with our animation, please use a newer one. ',
  continue: 'Go on, continue coloring to find you gift!',
  eye: '',
  howToURL: 'https://ae.loccitane.com/omyshea-landing-page.html?lang=en_AE'
}
