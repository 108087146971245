import colors from "../datas/colors";

export const randomProperty = obj => {
  var keys = Object.keys(obj);
  let value = obj[keys[(keys.length * Math.random()) << 0]];
  const key = Object.keys(obj).find(key => obj[key] === value);

  return {
    name: key,
    color: value
  };
};

let randomColor = randomProperty(colors);

export default {
  color: {
    name: randomColor.name,
    colorSelected: randomColor.color
  },
  tiles: [],
  tilesBck: [],
  containerProducts: null,
  currentZoom: 0
};
