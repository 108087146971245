export default {
  color:
    'عيشوا الحياة بالألوان مع لوحة بروفانس الفنية! تصفحوا اللوحة ولونوا مناطقها المختلفة، لفرصة الكشف عن عروض ومفاجآت حصرية مخبأة داخل اللوحة.',
  btnStart: 'ابدأ التلوين',
  avatar: 'اختر شخصيتك',
  instructionTitle: 'تعليمات اللعبة:',
  instruction: 'تصفح اللوحة، اختر المنطقة، ثم اضغط عليها لتلوينها.',
  usersColorize: 'شخص يلون هذه اللوحة',
  instructionColorize: `"اختر اللون، ثم ابدأ بتلوين المنطقة التي اخترتها.
  لون المنطقة بالكامل لتكتشف ماذا ربحت.`,
  sorry: `لا يوجد عروض مخبأة هنا.`,
  eshop: 'تسوق في موقع لوكسيتان الإلكتروني لتحصل على هديتك.*',
  discoverMore: 'اكتشف المزيد',
  win: `مبروك، لقد ربحت! 
  تسوق أونلاين أو بمتاجرنا لاستحقاق هديتك. تطبق الشروط والأحكام.`,
  tryAgain: ' جرب منطقة أخرى!',
  close: 'اغلق',
  discover: 'اكتشف',
  discoverUrl: '',
  howTo: 'كيف استخدم البرومو كود؟',
  push: 'اكتشف مجموعة الشيا أومي الجديدة محدودة الإصدار!',
  rangeURL: 'https://sa.loccitane.com/المجموعات/المجموعات-الجديدة/شيا-x-أومي?lang=ar_SA',
  colorAll: 'بمجرد اختيارك للّون، يمكنك بتلوين المنطقة المختارة بالكامل بنفس اللون.',
  globalFBTitle: 'فلنلوّن سوياً',
  globalFBText:
    'نضم إلينا في تلوين لوحة لوكسيتان الفنية بالتعوان مع أومي و احصل على الفرصة لربح جوائز قيّمة. فلتجهز أقلامك الخشبية! provenceeverywhere#',
  globalTWText:
    'فلنلوّن سوياً انضم إلينا في تلوين لوحة لوكسيتان الفنية بالتعوان مع أومي و احصل على الفرصة لربح جوائز قيّمة. فلتجهز أقلامك الخشبية! provenceeverywhere#',
  localFBTitle: 'فلنلوّن سوياً',
  localFBText:
    'جاء دورك لتلون على لوحة لوكسيتان الفنية بالتعاون مع أومي و احصل على الفرصة لربح جوائز قيّمة. فلتجهز أقلامك الخشبية! provenceeverywhere#',
  localTWText:
    'فلنلوّن سوياً جاء دورك لتلون على لوحة لوكسيتان الفنية بالتعاون مع أومي و احصل على الفرصة لربح جوائز قيّمة. فلتجهز أقلامك الخشبية! provenceeverywhere#',
  frescoPourcent:
    'هذه النسبة توضح مدى انتهاء اللوحة. هل تريد رؤية كيف تبدو هذه اللوحة الفنية؟ تصفح صفحتنا على الانستجرام loccitaneme@',
  tc: 'https://sa.loccitane.com/omyshea-landing-page.html?lang=ar_SA',
  tcTitle: 'الشروط والأحكام',
  frescoCompleted: 'بدأت اللوحة منذ $ يوم',
  ie: 'هذا المتصفح غير متوافق مع الرسوم المتحركة الخاصة باللوحة، يرجى استخدام متصفح جديد.',
  continue: 'هيا، فلتكمل التلوين لتجد هديتك!',
  eye: '',
  howToURL: 'https://sa.loccitane.com/omyshea-landing-page.html?lang=ar_SA'
}
