import Pencil from "./Pencil";
import MainContainer from "./MainContainer";
import { TimelineMax, TweenMax, Linear } from "gsap";
import PixiApp from "./PixiApp";
import store from "../datas/store";
import { utils } from "pixi.js";

export default class AiDraw {
  constructor({ target, color, name = "toto" }) {
    // target.alpha = 1;

    for (let i = 0; i < store.tilesBck.length; i++) {
      MainContainer.setChildIndex(store.tilesBck[i], 0);
    }

    this.pencil = new Pencil({ name });
    this.pencil.pencilLead.tint = utils.string2hex(color);
    this.pencil.alpha = 0;
    MainContainer.addChild(this.pencil);

    MainContainer.setChildIndex(target, 0);

    this.pencil.x = this.pencil.finalTargetX = target.x;
    this.pencil.y = this.pencil.finalTargetY = target.y;

    const stepY = target.height / 4;

    PixiApp.ticker.add(this.scaleContent);

    MainContainer.drawSlot({
      slotName: target.name,
      color
    });

    TweenMax.defaultEase = Linear.easeNone;
    const tl = new TimelineMax();
    tl.to(
      this.pencil,
      0,
      {
        alpha: MainContainer.isDrawing ? 0 : 1,
        finalTargetX: target.x + target.width,
        finalTargetY: target.y + stepY
      },
      1
    )
      .to(
        this.pencil,
        0,
        {
          finalTargetX: target.x,
          finalTargetY: target.y + stepY * 2
        },
        1.4
      )
      .to(
        this.pencil,
        0,
        {
          finalTargetX: target.x + target.width,
          finalTargetY: target.y + stepY * 3
        },
        1.6
      )
      .to(
        this.pencil,
        0,
        {
          finalTargetX: target.x,
          finalTargetY: target.y + stepY * 4
        },
        1.8
      )
      .to(
        target,
        2,
        {
          alpha: 1
        },
        2
      )
      .to(
        this.pencil,
        1,
        {
          alpha: 0,
          onComplete: () => {
            this.pencil.stop();
            this.pencil.destroy();
            PixiApp.ticker.remove(this.scaleContent);
          }
        },
        2.2
      );
  }

  scaleContent = () => {
    this.pencil.scale.x = this.pencil.scale.y =
      1 + (1 - MainContainer.scale.x) * 4;
  };
}
