import {
  Container,
  Sprite,
  Point,
  utils,
  SCALE_MODES,
  TilingSprite,
  BLEND_MODES,
} from "pixi.js";

import InvertedDrawingMask from "./InvertedDrawingMask";
import PixiApp from "./PixiApp";
import FirebaseManager from "./FirebaseManager";
import EmitterManager from "./EmitterManager";
import { TweenMax, Power3 } from "gsap";
import Viewport from "./Viewport";
import store from "../datas/store";
import UIContainer from "./UIContainer";
import { getUrlParam } from "../utils";
import detectIt from "detect-it";

class MainContainer extends Container {
  constructor() {
    super();

    this.firstDraw = true;
    this.isDrawing = false;
    this.productClicked = false;
    this.shareUrl = "";
    this.isLooseAlreadyShown = false;
    this.lastCall = performance.now();
    this.finished = 0;

    this.heightMaps = [];

    /*
     ** Get pixel color
     */

    Sprite.prototype.getColorByPoint = function (point) {
      const tempPoint = new Point();

      this.worldTransform.applyInverse(point, tempPoint);

      const width = this._texture.orig.width;
      const height = this._texture.orig.height;
      const x1 = -width * this.anchor.x;
      let y1 = 0;

      let flag = false;

      if (tempPoint.x >= x1 && tempPoint.x < x1 + width) {
        y1 = -height * this.anchor.y;

        if (tempPoint.y >= y1 && tempPoint.y < y1 + height) {
          flag = true;
        }
      }

      if (!flag) {
        return [0, 0, 0, 0];
      }

      const tex = this.texture;
      const baseTex = this.texture.baseTexture;
      if (!baseTex.colormap) {
        if (!genColorMap(baseTex)) {
          return [0, 0, 0];
        }
      }

      const colormap = baseTex.colormap;
      const data = colormap.data;
      const res = baseTex.resolution;
      let dx = Math.round((tempPoint.x - x1 + tex.frame.x) * res);
      let dy = Math.round((tempPoint.y - y1 + tex.frame.y) * res);
      let num = dx + dy * colormap.width;
      return [
        data[num * 4],
        data[num * 4 + 1],
        data[num * 4 + 2],
        data[num * 4 + 3],
      ];
    };
    const genColorMap = (baseTex) => {
      if (!baseTex.resource) {
        return false;
      }
      const imgSource = baseTex.resource.source;
      let canvas = null;
      if (!imgSource) {
        return false;
      }
      let context = null;
      if (imgSource.getContext) {
        canvas = imgSource;
        context = canvas.getContext("2d");
      } else if (imgSource instanceof Image) {
        canvas = document.createElement("canvas");
        canvas.width = imgSource.width;
        canvas.height = imgSource.height;
        context = canvas.getContext("2d");
        context.drawImage(imgSource, 0, 0);
      } else {
        return false;
      }

      const w = canvas.width,
        h = canvas.height;
      baseTex.colormap = context.getImageData(0, 0, w, h);

      return true;
    };

    /*
     ** end
     */

    if (detectIt.primaryInput === "mouse") {
      this.on("click", this.clickOnSprite);
    } else {
      this.on("touchend", this.clickOnSprite);
    }
  }

  clickOnSprite = (event) => {
    // alert(this.isDrawing, Viewport.dragMoved, this.productClicked);
    // console.log(event.data.global);
    if (
      this.isDrawing ||
      Viewport.dragMoved ||
      Viewport.isZoomingOnSprite ||
      this.productClicked
    )
      return;

    for (let i = 0; i < this.children.length; i++) {
      const sprite = this.children[i];
      // console.log("searching");
      if (
        sprite.alpha > 0
        /* ||
                 FirebaseManager.toAutoDraw.some(el => el.id === sprite.name)*/
      ) {
        continue;
      }

      const isHit = sprite.containsPoint(event.data.global);
      // console.log("test hit", sprite.name);
      if (isHit && sprite.name) {
        // console.log("test hit ok");
        if (
          sprite.getColorByPoint(event.data.global)[0] &&
          Viewport.interactive &&
          !Viewport.isZooming
        ) {
          this.posGlobl = event.data.global;
          // alert("is hit");
          // console.log("isHit");
          this.sprite = sprite;
          Viewport.disableInteractive();
          EmitterManager.emit("SPRITE_SELECTED", sprite);
          EmitterManager.emit("DRAWING_ON");
          this.isDrawing = true;

          console.log("hit lock", sprite.name);
          // Lock slot
          FirebaseManager.currentFresco.doc(sprite.name).set({
            timestamp: Date.now(),
            user: FirebaseManager.userId,
            userCountry: getUrlParam("lang", "us_en").split("_")[0],
            userName: store.avatar.name,
            isLocked: true,
          });

          sprite.alpha = 1;

          this.setChildIndex(this.background, this.children.length - 2);
          TweenMax.to(store.containerProducts, 1, {
            alpha: 0,
          });

          let pencil = PixiApp.stage.getChildByName("pencil");

          this.maskDrawing = new InvertedDrawingMask({
            sprite,
            isPointer: true,
            cb: () => {
              this.finished++;
              EmitterManager.emit("DRAWING_COMPLETE");

              // chec if win or lose
              // fetch(
              //   window.location.hostname !== "localhost"
              //     ? "https://us-central1-loccitane-omy-3a690.cloudfunctions.net/isWinner"
              //     : "http://localhost:5001/loccitane-omy-3a690/europe-west1/isWinner",
              //   {
              //     method: "POST",
              //     body: JSON.stringify({
              //       country: getUrlParam("lang", "us_en"),
              //     }),
              //   }
              // )
              //   .then((res) => res.json())
              //   .then((res) => {
              //     pencil.hide();

              //     const language = getUrlParam("lang", "us_en");

              //     if (
              //       res.win ||
              //       ((language === "jp_ja" ||
              //         language === "ca_fr" ||
              //         language === "ca_en" ||
              //         language === "us_en" ||
              //         language === "si_si" ||
              //         language === "hr_hr" ||
              //         language === "me_ar" ||
              //         language === "me_en" ||
              //         language === "ae_ar" ||
              //         language === "ae_en" ||
              //         language === "au_en" ||
              //         language === "uk_en") &&
              //         this.finished === 3)
              //     ) {
              //       EmitterManager.emit(
              //         "POPIN:WIN",
              //         language === "jp_ja"
              //           ? "FS2001"
              //           : language === "ca_fr" ||
              //             language === "ca_en" ||
              //             language === "us_en"
              //           ? "SHEA"
              //           : language === "si_si"
              //           ? "OMY"
              //           : language === "au_en"
              //           ? "CREATIVE"
              //           : language === "hr_hr"
              //           ? "OMYKARITE"
              //           : language === "me_ar" || language === "me_en"
              //           ? "OMYCOLOR"
              //           : language === "ae_ar" || language === "ae_en"
              //           ? "OMYSHEA"
              //           : language === "uk_en"
              //           ? "COLOUR"
              //           : res.code
              //       );
              //     } else if (!this.isLooseAlreadyShown) {
              //       this.isLooseAlreadyShown = true;
              //       EmitterManager.emit("POPIN:LOOSE");
              //     } else {
              this.handleEndDraw(true);

              EmitterManager.emit("DRAWING_OFF");

              pencil.show();

              // Viewport.enableInteractive();
              // }
              // });

              if (window.location.hostname === "localhost") {
                // Math.random() < 0.5
                /*?*/ /*EmitterManager.emit("POPIN:WIN", "1245D85");*/
                /*:*/ EmitterManager.emit("POPIN:WIN", "CREATIVE");
              }

              this.justDraw = true;

              this.closeDrawingMod({
                bck: false,
              });
              this.setChildIndex(sprite, 0);

              for (let i = 0; i < store.tilesBck.length; i++) {
                this.setChildIndex(store.tilesBck[i], 0);
              }

              // const oldScale = this.scale.x;

              // this.scale.x = this.scale.y = 1;

              if (this.firstDraw) {
                window.gtag("event", "drawing");
                this.firstDraw = false;
              }
              // console.log('locked false')
              FirebaseManager.currentFresco.doc(sprite.name).update({
                timestamp: Date.now(),
                userCountry: getUrlParam("lang", "us_en").split("_")[0],
                userName: store.avatar.name,
                color: store.color.colorSelected,
                user: FirebaseManager.userId,
                isLocked: false,
              });
            },
            timerFn: () => {
              this.handleEndDraw();
            },
          });

          this.addChildAt(this.maskDrawing, this.children.length - 1);
          this.setChildIndex(this.spritePaper, this.children.length - 1);

          for (let i = 0; i < store.tiles.length; i++) {
            this.setChildIndex(store.tiles[i], this.children.length - 1);
          }

          PixiApp.stage.setChildIndex(
            UIContainer,
            PixiApp.stage.children.length - 1
          );
          PixiApp.stage.setChildIndex(
            pencil,
            PixiApp.stage.children.length - 1
          );

          break;
        }
      }
    }
  };

  addPaperTexture = (woldWidth, worldHeight) => {
    let paperTexture = PixiApp.loader.resources.paperTexture.texture;
    this.spritePaper = new TilingSprite(
      paperTexture,
      woldWidth * 2,
      worldHeight * 2
    );
    this.spritePaper.name = "spritePaper";
    this.spritePaper.blendMode = BLEND_MODES.MULTIPLY;
    this.addChildAt(this.spritePaper, this.children.length - 1);

    let tileTexture = PixiApp.loader.resources.backgroundTile.texture;
    this.background = new TilingSprite(
      tileTexture,
      woldWidth * 2,
      worldHeight * 2
    );
    this.background.alpha = 0;
    this.background.visible = false;
    this.addChild(this.background);
  };

  sortLayers = () => {
    for (let i = 0; i < store.tilesBck.length; i++) {
      this.setChildIndex(store.tilesBck[i], 0);
    }
  };

  drawSlot = ({ slotName, color } = {}) => {
    const sprite = this.spriteAccess[slotName];
    if (!sprite) return;

    sprite.tint = utils.string2hex(color);
    // console.log(slotName)
    if (!this.maskDrawing || (this.maskDrawing && !this.maskDrawing.mask)) {
      this.setChildIndex(sprite, 0);
      // console.log('sort')
      this.sortLayers();
    }
    // if (this.sprite && this.isDrawing)
    //   this.setChildIndex(this.sprite, this.children.length - 2);

    if (sprite.alpha === 0) {
      sprite.alpha = color === "#ffffff" ? 0 : 1;
    }
  };

  generateShare = (sprite) => {
    const finalImgW = 1200;
    const finalImgH = 630;

    const bounds = sprite.getBounds();
    bounds.x -= (finalImgW - bounds.width) / 2;
    bounds.y -= (finalImgH - bounds.height) / 2;
    bounds.width = finalImgW;
    bounds.height = finalImgH;

    const tex = PixiApp.renderer.generateTexture(
      this,
      SCALE_MODES.LINEAR,
      1,
      bounds
      // new Rectangle(
      //   sprite.x - (finalImgW - sprite.width) / 2,
      //   sprite.y - (finalImgH - sprite.height) / 2,
      //   sprite.width + (finalImgW - sprite.width) / 2,
      //   sprite.height + (finalImgH - sprite.height) / 2
      // )
    );

    const spr = new Sprite(tex);

    const img = PixiApp.renderer.extract.image(spr);

    const storageRef = window.firebase.storage().ref();
    const shareRef = storageRef.child(
      `${FirebaseManager.data.name.toLowerCase().replace(/\s+/g, "-")}_${
        FirebaseManager.data.timestamp
      }_${Date.now()}.png`
    );

    const ref = this;

    shareRef.putString(img.src, "data_url").then(function (snapshot) {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        sprite.shareUrl = ref.shareUrl = downloadURL;

        ref.setChildIndex(store.containerProducts, ref.children.length - 1);

        const shareGraph =
          ref.children[ref.children.length - 1].getChildByName("share");
        shareGraph.visible = true;
        shareGraph.x = sprite.x;
        shareGraph.y = sprite.y;
      });
    });
  };

  handleEndDraw = (skip = false) => {
    if (!skip) {
      this.closeDrawingMod({
        sprite: this.sprite,
      });
    }

    EmitterManager.emit("COLORIZE:END");
    EmitterManager.emit("HIDE:COLORSELECTION");

    EmitterManager.emit("DRAWING_OFF");
    let pencil = PixiApp.stage.getChildByName("pencil");
    pencil.show();

    TweenMax.delayedCall(1.5, () => {
      Viewport.enableInteractive();
    });

    this.hideBackground();
  };

  hideBackground = () => {
    const shareGraph =
      this.children[this.children.length - 1].getChildByName("share");

    if (shareGraph) shareGraph.visible = false;

    TweenMax.to(this.background, 0.4, {
      alpha: 0,
      ease: Power3.easeInOut,
      onComplete: () => {
        this.background.visible = false;
        this.isDrawing = false;

        if (this.justDraw && this.sprite) {
          this.justDraw = false;

          requestAnimationFrame(() => {
            // alert("GENERATE SHARE");
            // this.generateShare(this.sprite);
            this.sprite = null;
          });
        } else {
          this.sprite = null;
        }
      },
    });

    TweenMax.to(store.containerProducts, 0.4, {
      alpha: 1,
    });
  };

  closeDrawingMod = ({ sprite, bck = true } = {}) => {
    // exec when finished
    this.maskDrawing.mask = null;

    if (bck) this.maskDrawing.stop();

    this.maskDrawing.destroy();

    this.setChildIndex(store.containerProducts, this.children.length - 1);

    if (sprite) {
      // console.log("DELETE ", sprite.name);
      sprite.alpha = 0;
      // console.log('close')
      FirebaseManager.currentFresco.doc(sprite.name).delete();
      sprite.tint = 0xffffff;
      this.setChildIndex(sprite, this.children.length - 1);
      this.setChildIndex(sprite, 0);
      console.log(sprite);
    }
  };
}

export default new MainContainer();
