import { Filter } from "pixi.js";
import VignetteFilterFrag from "./VignetteFilterFrag";

export default class VignetteFilter extends Filter {
  constructor({ radius = 0.6, softness = 0.3, opacity = 0.5 } = {}) {
    super(null, VignetteFilterFrag);

    this.uniforms.uRadius = radius;
    this.uniforms.uSoftness = softness;
    this.uniforms.uOpacity = opacity;
    this.uniforms.uResolution = new Float32Array([
      window.innerWidth,
      window.innerHeight
    ]);
  }

  get uResolution() {
    return this.uniforms.uResolution;
  }
}
